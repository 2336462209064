export default function MessageModal({ isOpen, onClose, message }: any) {
    if (!isOpen) {
        return null;
    }

    return (
        <div className="fixed z-50 top-50 inset-0 flex justify-center p-4 items-start bg-black bg-opacity-50">
            <div className="max-w-[40vw] bg-white rounded shadow dark:bg-gray-800 dark:border-gray-700">
                <div className="w-full ">
                    <div className="bg-primary rounded-none flex flex-row justify-between">
                        <h5 className="mb-2 p-2 text-xl font-bold tracking-tight text-white">
                            {message.senderId || 'Not Provided'}
                        </h5>
                        <button className="p-2 text-white" onClick={onClose}>
                            <i className="fa-solid fa-xmark text-2xl"></i>
                        </button>
                    </div>
                    <p className="mb-3 text-lg p-2 text-gray-700 dark:text-gray-400  whitespace-pre-wrap w-full">
                        {message.message || 'Not Provided'}
                    </p>
                </div>
            </div>
        </div>
    );
}
