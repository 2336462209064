import React, { useState, useEffect, useRef, Fragment } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import dark from '@/assets/images/matt-gross-9aCkSl6YcXg-unsplash.jpg';
import TextArea from '../../../components/Textarea';
import Input from '../../../components/Input';
import { Textarea } from '@mantine/core';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import sendfilesms from '../../../Validations/sendfile';
import toast from 'react-hot-toast';
import senderId from '../../../Validations/senderId';
import IconMail from '../../../components/Icon/IconMail';
import IconLockDots from '../../../components/Icon/IconLockDots';
import { CircularProgress } from '@mui/material';
import IconEdit from '../../../components/Icon/IconEdit';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, IRootState, RootState } from '../../../store';
import LoginThunk from '../../../Redux/actions/login';
import { setPageTitle, toggleTheme } from '../../../store/themeConfigSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import IconUser from '../../../components/Icon/IconUser';
import signupSchema from '../../../Validations/register';
import IconSun from '../../../components/Icon/IconSun';
import IconMoon from '../../../components/Icon/IconMoon';
import IconLaptop from '../../../components/Icon/IconLaptop';
import campany1 from '@/assets/images/IGAJU(1) (1).png';
import campany2 from '@/assets/images/carousel2.jpeg';
import campany3 from '@/assets/images/drag-2.jpeg';
import { z } from 'zod';
import { Dialog, Transition } from '@headlessui/react';
import RegisterThunk from '../../../Redux/actions/register';
import loginSchema from '../../../Validations/login';
import UserThunk from '../../../Redux/actions/user';
import Dropdown from '../../../components/dropdown';
import IconLogout from '../../../components/Icon/IconLogout';
import ptofile from '@/assets/images/avatar-icon-images-4 (1).jpg';
import { storage } from '../../../utils/storage';
import UsersThunk from '../../../Redux/actions/users';
import Smschema from '../../../Validations/sendSms';
import senderIDThunk from '../../../Redux/actions/GetSenderIds';
import SendThunk from '../../../Redux/actions/sendSms';
import sms from '@/assets/images/Screenshot_2023-12-13_143249-removebg-preview.png';
import excel from '@/assets/images/Screenshot_2023-12-13_143427-removebg-preview.png';
import DataThunk from '../../../Redux/actions/getApidata';
import PostSenderIdThunk from '../../../Redux/actions/PostSenderId';
import PurchaseSchema from '../../../Validations/buy';
import IconShoppingBag from '../../../components/Icon/IconShoppingBag';
import PurchaseThunk from '../../../Redux/actions/purchase';
import AllocateThunk from '../../../Redux/actions/assignRole';
import AllocateSms from '../../../Validations/alllocate';
import AssignRoleThunk from '../../../Redux/actions/assignRole';
import AssignRoles from '../../../Validations/AssignRole';

export const AssignRole = ({ user }: any) => {
    const [modal1, setModal1] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
const [role,setRole] = useState('')
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        resolver: yupResolver(AssignRoles),
    });

    const Signup: any = async (data: any, e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        console.log(data);
        try {
            const userId = user.id;

            const datas = { ...data, userId };

            const response: any = await dispatch(AssignRoleThunk(datas)).then((action: any) => unwrapResult(action));

            toast.success('User Role Updated ');

            setModal1(false);
        } catch (e: any) {
            toast.error(e);
        }
    };
    return (
        <div className="">
            <div className="flex items-center justify-center">
                <button type="button" onClick={() => setModal1(true)} className="btn btn-primary btn-sm m-1">
                    Assign Role
                </button>
            </div>
            <Transition appear show={modal1} as={Fragment}>
                <Dialog as="div" open={modal1} onClose={() => setModal1(false)}>
                    <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                        <div className="fixed inset-0" />
                    </Transition.Child>
                    <div className="fixed inset-0 bg-[black]/60 z-[999] overflow-y-auto">
                        <div className="flex items-start justify-center min-h-screen px-4">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel as="div" className="panel border-0 p-0 rounded-lg overflow-hidden my-8 w-full max-w-lg text-black dark:text-white-dark">
                                    <div className="flex bg-[#fbfbfb] dark:bg-[#121c2c] items-center justify-between px-5 py-3">
                                        <div className="text-lg flex flex-row justify-center w-full ">Assign  Role</div>
                                    </div>
                                    <div className="modal-content m-5 ">
                                        <form className="space-y-5 dark:text-white" onSubmit={handleSubmit(Signup)}>
                                            <div>
                                                <label htmlFor="Email " className="font-medium">
                                                    Role
                                                </label>
                                                <div className="relative text-white-dark">
                                                    <select  id="" className='form-select'
                                                    {...register('role')}>
                                                     
                                                        <option value="admin">Admin</option>
                                                        <option value="developer">User</option>
                                                        <option value="trusted_developer">Trusted Developer</option>
                                                    </select>
                                                </div>
                                                {errors.role && <p className="text-red-500 text-xs">Role is required</p>}
                                            </div>

                                            <button type="submit" className="btn btn-lg capitalize w-full btn-primary">
                                                <span>Assign Role</span>
                                            </button>
                                        </form>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </div>
    );
};
