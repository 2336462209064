import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import 'react-toastify/dist/ReactToastify.css';
import { AppDispatch, RootState } from '../../store';
import DeleteSenderIdThunk from '../../Redux/actions/DeleteSenderId';
import IconTrash from '../../components/Icon/IconTrash';
import { showSuccessMessage } from '../../utils/toast';


export default function DeleteSenderId({ Sender, handleFilter }: any) {
    const dialog = useRef<HTMLDialogElement>(null);

    const closeModel = () => {
        if (dialog.current?.close) dialog.current.close();
    };

    const openModel = () => {
        if (dialog.current?.showModal) dialog.current.showModal();
    };

    const close = (e: React.MouseEvent<HTMLElement>) => {
        const dialogDimensions = dialog.current?.getBoundingClientRect();
        if (e.clientX < dialogDimensions!.left || e.clientX > dialogDimensions!.right || e.clientY < dialogDimensions!.top || e.clientY > dialogDimensions!.bottom) {
            closeModel();
        }
    };
    const dispatch = useDispatch<AppDispatch>();
    const { isLoading } = useSelector((state: RootState) => state.deleteSenderId);
    const Id = Sender.id;

    const handleDelete = async (rID: any) => {
        await dispatch(DeleteSenderIdThunk(rID));
        handleFilter(rID);
        closeModel();

        showSuccessMessage('SenderId Deleted');
    };

    return (
        <>
            <dialog ref={dialog} className=" rounded-lg shadow-lg  bg-gray-50 dark:bg-[#121c2c]  " data-testid="dialog" onClick={(e) => close(e)}>
                <div className="px-3  rounded">
                    <div className="flex flex-row justify-center items-center">
                        <h3 className="p-2 text-gray-600 dark:text-gray-400 text-lg">
                            <i className="fas fa-exclamation-triangle text-red-500 text-xl mx-2"></i> Delete SenderID
                        </h3>
                    </div>
                    <div>
                        <div className="form-group">
                            <div className="flex flex-row justify-center">
                                <p className="text-md text-gray-600 dark:text-gray-400">
                                    are you sure you want to Delete this sender Id
                                    <span className=" font-semibold"> {Sender.name}</span>
                                </p>
                            </div>
                        </div>

                        <div className="mt-2 flex flex-row justify-center">
                            <button type="submit" onClick={() => handleDelete(Id)} className="btn btn-primary ">
                                {isLoading ? <i className="fa-solid fa-spinner text-red-400 text-lg "></i> : 'Delete'}
                            </button>
                          
                            <button type="button" className="btn btn-outline-danger mx-2 " onClick={closeModel}>
                                                                    Discard
                                                                </button>
                        </div>
                    </div>
                </div>
            </dialog>
            <button onClick={() => openModel()} title="Delete senderId" className="btn btn-sm btn-danger">
                {}
                <IconTrash className="w-4 " />
            </button>
        </>
    );
}
