import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { unwrapResult } from '@reduxjs/toolkit';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';
import toast from 'react-hot-toast';
import DataThunk from '../../../../Redux/actions/getApidata';
import { RootState, AppDispatch } from '../../../../store';
import Smschema from '../../../../Validations/sendSms';
import senderIDThunk from '../../../../Redux/actions/GetSenderIds';
import SendSmsThunk from '../../../../Redux/actions/sendSms';

function SendMany() {
    const dispatch = useDispatch<AppDispatch>();
    const { user } = useSelector((state: RootState) => state.user);
    const { data: Api_data }: any = useSelector((state: RootState) => state.data);
    const { data } = useSelector((state: RootState) => state.senderId);
    const { isLoading } = useSelector((state: RootState) => state.send);

    const [selectedSenderId, setSelectedSenderId] = useState('');
    const [Key, setKey] = useState('');
    const [Keys, setKeys] = useState<any[]>([]);
    const [message, setMessage] = useState('');
    const [smsCount, setSmsCount] = useState(1);

    useEffect(() => {
        dispatch(DataThunk());
        dispatch(senderIDThunk());
    }, [dispatch]);

    useEffect(() => {
        if (data) {
            setKeys(data);
        }
    }, [data]);

    useEffect(() => {
        if (Api_data && Api_data.length > 0) {
            setKey(Api_data[0].secret);
        }
    }, [Api_data]);

    useEffect(() => {
        const messageLength = message.length;
        const count = Math.ceil(messageLength / 160);
        setSmsCount(count > 0 ? count : 1);
    }, [message]);

    const handleMessageChange = (event: any) => {
        const newMessage = event.target.value.replace(/\n/g, '');
        setMessage(newMessage);
    };

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    
    } = useForm({
        resolver: yupResolver(Smschema),
    });

    const submit = async (data: any) => {
        const recipients = data.recipients
            .split(',')
            .map((phoneNumber: string) => phoneNumber.trim())
            .map((phoneNumber: string) => {
                if (phoneNumber.startsWith('250')) {
                    return phoneNumber;
                } else if (phoneNumber.startsWith('25')) {
                    return phoneNumber;
                } else {
                    return `25${phoneNumber}`;
                }
            });

        const formData = { senderId: data?.senderId, message, recipients };

        try {
            await dispatch(SendSmsThunk({ formData, Key })).then(unwrapResult);
            reset();
            setMessage('');
            toast.success('Message sent');
        } catch (error) {
            return error
        }
    };

    return (
        <div className="m-2">
            <div className="w-full p-2">
                <p className="mb-4 sm:text-lg font-bold md:text-1xl lg:text-xl">Send SMS</p>
                <form className="flex flex-col mt-2" onSubmit={handleSubmit(submit)}>
                    <div className="flex flex-col mt-4 w-full">
                        {user.role === 'trusted_developer' ? (
                            <div className="ml-2 md:mt-0 w-full mt-4 flex flex-col">
                                <label htmlFor="customSenderId" className="font-bold">
                                    Enter Sender ID
                                </label>
                                <input type="text" className='form-input' {...register('senderId')} placeholder="Enter Sender ID" />
                                {errors.senderId && <div className="text-red-500 text-xs italic"  > SenderId is required</div>}
                            </div>
                        ) : (
                            <div className="my-2 w-full flex flex-col">
                                <label htmlFor="apiKeySelect" className="font-bold">
                                    Select Sender ID
                                </label>
                                {Keys.filter((item: any) => item.approved).length === 0 ? (
                                    <p className="form-input">
                                        No SenderId yet{' '}
                                        <Link to="/dashboard/settings" className="text-primary underline">
                                            Create New
                                        </Link>
                                    </p>
                                ) : (
                                    <select id="apiKeySelect" className="form-select text-gray-500" {...register('senderId')} >
                                        <option value="">Select sender Id</option>
                                        {Keys.filter((item: any) => item.approved).map((item: any) => (
                                            <option key={item.id} value={item.secret}>
                                                {item.name}
                                            </option>
                                        ))}
                                    </select>
                                )}
                                {errors.senderId && <p className="text-red-500 text-xs italic">Sender Id is required</p>}
                            </div>
                        )}
                    </div>
                    <div className="my-5">
                        <label htmlFor="recipients" className="font-bold">
                            Numbers separate with (,)
                        </label>
                        <textarea placeholder="Ex. 0781120101," {...register('recipients')} className='form-textarea h-24' />
                        {errors.recipients && <p className="text-red-500 text-xs italic">{typeof errors?.recipients?.message === 'string' && errors.recipients.message}</p>}
                    </div>
                    <div className="mt-3">
                        <div className="flex flex-row justify-between">
                            <label htmlFor="message" className="font-bold">
                                Message
                            </label>
                            <div>
                                <p className="text-gray-600 dark:text-gray-400">{message.length}/160</p>
                            </div>
                        </div>
                        <textarea {...register('message')} className="form-textarea h-32" placeholder="Message" onChange={handleMessageChange} value={message} />
                    </div>
                    {errors.message && <p className="text-red-500 text-xs italic">{typeof errors?.message?.message === 'string' && errors.message?.message}</p>}
                    <button type="submit" className="w-1/3 btn my-2 btn-primary" data-testid="submit">
                        {isLoading ? <CircularProgress size={24} sx={{ color: 'white' }} /> : <span>Send ({smsCount}) sms</span>}
                    </button>
                </form>
            </div>
        </div>
    );
}

export default SendMany;
