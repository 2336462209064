import { ChangeEvent, useState, useEffect } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../../../../store';
import sendfilesms from '../../../../Validations/sendfile';
import TextArea from '../../../../components/Textarea';
import { EXCEL_FILE_BASE64 } from '../../../../utils/constant';
import DataThunk from '../../../../Redux/actions/getApidata';
import FileSaver from 'file-saver';
import senderIDThunk from '../../../../Redux/actions/GetSenderIds';
import { Link } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import toast from 'react-hot-toast';
import IconDownload from '../../../../components/Icon/IconDownload';
import SendSmsThunk from '../../../../Redux/actions/sendSms';

function SendFile() {
    const { user, loading: userLoad } = useSelector((state: RootState) => state.user);
    const dispatch = useDispatch<AppDispatch>();
    const [FaultyRecipients, setFaultyRecipients] = useState<string[]>([]);
    const [Recipients, setRecipients] = useState<string[]>([]);
    const { isLoading } = useSelector((state: RootState) => state.send);
    const [selectedSenderId, setSelectedSenderId] = useState('');
    const [apiKeyError, setApiKeyError] = useState('');
    const [senderError, setSenderError] = useState('');
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        resolver: yupResolver(sendfilesms),
    });

    const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            try {
                const XLSX = await import('xlsx');
                readExcelFile(file, XLSX);
            } catch (error) {
                return error;
            }
        }
    };

    const readExcelFile = (file: File, XLSX: any) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = e.target?.result;
            processExcelData(data, XLSX);
        };
        reader.readAsBinaryString(file);
    };

    const processExcelData = (data: any, XLSX: any) => {
        const workbook = XLSX.read(data, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        const recipientsColumnData = jsonData
            .map((row: any) => row[recipientsColumnIndex])
            .filter((recipient: any) => recipient !== undefined && recipient !== null)
            .map((recipient: any) => {
                let recipientStr = recipient.toString().replace(/\D/g, ''); // Remove non-numeric characters
                if (recipientStr.charAt(0) === '0') {
                    recipientStr = `25${recipientStr}`;
                } else if (recipientStr.charAt(0) === '7' || recipientStr.startsWith('7')) {
                    recipientStr = `250${recipientStr}`;
                } else if (recipientStr.startsWith('250')) {
                    // Already formatted correctly
                }
                return recipientStr;
            });

        const recipientsSet = new Set<string>();
        const faultyRecipients: string[] = [];

        recipientsColumnData.forEach((recipient: string) => {
            if (recipient.length === 12) {
                if (!recipient.startsWith('25078') && !recipient.startsWith('25079') && !recipient.startsWith('25072') && !recipient.startsWith('25073')) {
                    faultyRecipients.push(recipient);
                } else {
                    recipientsSet.add(recipient);
                }
            } else {
                faultyRecipients.push(recipient);
            }
        });

        const validRecipients = Array.from(recipientsSet);
        setRecipients(validRecipients);
        setFaultyRecipients(faultyRecipients);
    };

    const recipientsColumnIndex = 1;

    const { data: senderIdData, loading: loader } = useSelector((state: RootState) => state.senderId);

    useEffect(() => {
        dispatch(senderIDThunk());
    }, [dispatch]);

    const [Keys, setKeys] = useState<any[]>([]);

    useEffect(() => {
        setKeys(senderIdData);
    }, [senderIdData]);

    const [Key, setKey] = useState('');

    const submit = async (data: any) => {
        if (!Key || Key === '') {
            setApiKeyError('No API Key selected');
            return;
        } else {
            setSenderError('');
            const senderId = data?.senderId;

            const recipients = Recipients;
            const formData = { senderId, message, recipients };

            try {
                await dispatch(SendSmsThunk({ formData, Key })).then(unwrapResult);
                reset();
                setMessage('');
                toast.success('Message sent');
            } catch (error) {
                return error;
            }
        }
    };

    const { data: Api_data, loading }: any = useSelector((state: RootState) => state.data);

    useEffect(() => {
        dispatch(DataThunk());
    }, [dispatch]);

    useEffect(() => {
        setKey(Api_data[0]?.secret);
    }, [Api_data]);

    const handleDownload = () => {
        let sliceSize = 1024;
        let byteCharacters = atob(EXCEL_FILE_BASE64);
        let bytesLength = byteCharacters.length;
        let slicesCount = Math.ceil(bytesLength / sliceSize);
        let byteArrays = new Array(slicesCount);
        for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
            let begin = sliceIndex * sliceSize;
            let end = Math.min(begin + sliceSize, bytesLength);
            let bytes = new Array(end - begin);
            for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
                bytes[i] = byteCharacters[offset].charCodeAt(0);
            }
            byteArrays[sliceIndex] = new Uint8Array(bytes);
        }
        FileSaver.saveAs(new Blob(byteArrays, { type: 'application/vnd.ms-excel' }), 'my-excel.xlsx');
    };

    const [message, setMessage] = useState('');
    const [smsCount, setSmsCount] = useState(1);
    const maxMessageLength = 160;

    useEffect(() => {
        const messageLength = message.length;
        const smsCount = Math.ceil(messageLength / maxMessageLength);
        setSmsCount(smsCount > 0 ? smsCount : 1);
    }, [message]);

    const handleMessageChange = (event: any) => {
        const newMessage = event.target.value.replace(/[\r\n]/g, '');
        setMessage(newMessage);
    };

    return (
        <>
            <div className="m-2 t">
                <div className="w-full p-2">
                    <p className="mb-4 sm:text-lg md:text-1xl font-bold lg:text-xl">Send From Excel File</p>

                    <form className="flex flex-col mt-3" onSubmit={handleSubmit(submit)}>
                        <div className="flex flex-col mt-4 w-full">
                            {user.role === 'trusted_developer' ? (
                                <div className="ml-2 md:mt-0 w-full mt-4 flex flex-col">
                                    <label htmlFor="customSenderId" className="font-bold">
                                        Enter Sender ID
                                    </label>
                                    <input type="text" className="form-input" {...register('senderId')} placeholder="Enter Sender ID" />
                                    {errors.senderId && <div className="text-red-500 text-xs italic"> SenderId is required</div>}
                                </div>
                            ) : (
                                <div className="mt-5 w-full flex flex-col">
                                    <label htmlFor="" className="font-bold">
                                        Select Sender ID
                                    </label>

                                    {Keys.filter((item: any) => item.approved).length === 0 ? (
                                        <p className="form-input">
                                            No SenderId yet{' '}
                                            <Link to="/dashboard/settings" className="text-primary underline">
                                                Create New
                                            </Link>
                                        </p>
                                    ) : (
                                        <select id="apiKeySelect" className="form-select text-gray-500" {...register('senderId')}>
                                            <option value="">Select sender Id</option>
                                            {Keys.filter((item: any) => item.approved).map((item: any) => (
                                                <option key={item.id} value={item.secret}>
                                                    {item.name}
                                                </option>
                                            ))}
                                        </select>
                                    )}
                                    {errors.senderId && <p className="text-red-500 text-xs italic">{typeof errors.senderId.message === 'string' && errors.senderId.message}</p>}
                                </div>
                            )}
                        </div>

                        <div className="senderndAPI flex flex-col md:flex-row mt-7">
                            <div className="senderndAPI1 w-full md:w-[50%]">
                                <label htmlFor="" className="font-bold">
                                    Download template
                                </label>
                                <button type="button" className="btn btn-info w-full" onClick={handleDownload}>
                                    <IconDownload />
                                </button>
                            </div>

                            <div className="senderndAPI2 w-full md:mt-0 mt-4 md:w-[50%] ml-2">
                                <label htmlFor="" className="font-bold">
                                    Excel file
                                </label>
                                <input type="file" className="form-input p-2" onChange={handleFileChange} />
                            </div>
                        </div>

                        <div className="mt-7">
                            <label htmlFor="" className="font-bold">
                                Numbers Extracted
                            </label>
                            <TextArea className="" placeholder="No number Found" disabled={true} value="numbers" defaultValue={Recipients.join(', ')} />
                        </div>

                        {FaultyRecipients && FaultyRecipients.length > 0 && (
                            <div className="my-7">
                                <label htmlFor="" className="font-bold text-danger">
                                    Invalid Numbers (Incorrect Format)
                                </label>
                                <textarea className="h-10 form-textarea bg-red-200" placeholder="No numbers found" disabled defaultValue={FaultyRecipients.join(', ')} />
                                <p>You can still proceed with sending messages to the valid numbers.</p>
                            </div>
                        )}

                        <div className="mt-7">
                            <div className="flex flex-row justify-between">
                                <label htmlFor="" className="font-bold">
                                    Message
                                </label>
                                <div className="">
                                    <p className="font-bold">{message.length}/160</p>
                                </div>
                            </div>

                            <textarea className="form-textarea h-24" {...register('message')} placeholder="Message" onChange={handleMessageChange} value={message} name="message" />
                            {errors.message && <p className="text-red-500 text-xs italic">{typeof errors?.message?.message === 'string' && errors?.message?.message}</p>}
                        </div>

                        {loader}
                        <button type="submit" className="w-1/3 btn btn-primary" data-testid="submit">
                            {isLoading ? <CircularProgress size={24} sx={{ color: 'white' }} /> : <span>Send ({smsCount}) SMS</span>}
                        </button>
                    </form>
                </div>
            </div>
        </>
    );
}

export default SendFile;
