import { useEffect, useState } from 'react'
import { capitalize, CircularProgress } from '@mui/material';
import { Link } from 'react-router-dom';
import ExportDataTable, { TableColumn } from '../../../components/datatable';
import { useGetCrons } from '../../../api/groups';
import formatDateToLongForm from '../../../utils/Date';
import MessageModal from '../MessageModal';


const SchedulresReports = () => {
    const {crons, loading,getCrons} :any= useGetCrons();
    const [expandedMessage, setExpandedMessage] = useState<number | null>(null);

    const handleExpandMessage = (index: number) => {
        if (expandedMessage === index) {
            setExpandedMessage(null);
        } else {
            setExpandedMessage(index);
        }
    };
    useEffect(() => {
        getCrons();
    }, []);

    const columns: TableColumn<any>[] = [
        {
            title: 'Sender ID',
            accessor: 'senderId',
            render: (row) => <p>{row.senderId}</p>,
        },
        {
            title: 'Message',
            accessor: 'message',
            render: (row, index) => (
                <p onClick={() => handleExpandMessage(index)} className=" hover:underline">
                    {row.message && row.message.length > 30 ? row.message.substring(0, 30) + '...' : row.message || 'Not Provided'}
                    <MessageModal isOpen={expandedMessage === index} onClose={() => handleExpandMessage(index)} message={row} />,
                </p>
            ),
        },
        {
            title: 'Scheduled Time',
            accessor: 'schedule_time',
            render: (row) => <p>{formatDateToLongForm(row.schedule_time)}</p>,
        },
        {
            title: 'Created by',
            accessor: 'user.firstName', 
            render: (row) => (
                <p>
                    {capitalize(row.user?.firstName)} {capitalize(row.user?.lastName)}
                </p>
            ),
        },
        {
            title: 'Group Name',
            accessor: 'group.name', 
            render: (row) => <p>{row.group?.name}</p>,
        },
        {
            title: 'Sent SMS',
            accessor: 'sentSms',
            render: (row) => <Link  className='hover:underline' to={`/dashboard/contacts/${row?.id}/completed`}>{row.sentSms}</Link>,
        },
     
        {
            title: 'Failed SMS',
            accessor: 'failedSms', 
            render: (row) => <Link  className='hover:underline' to={`/dashboard/contacts/${row?.id}/failed`}>{row.failedSms}</Link>,
        
        },

        {
            title: 'Waiting SMS',
            accessor: 'waitingCount', // Access nested property
            render: (row) => <Link  className='hover:underline' to={`/dashboard/contacts/${row?.id}/initiated`}>{row.waitingCount}</Link>,
      
        },

       

    ];

    return (
        <><div>
            <ul className="flex space-x-2 rtl:space-x-reverse">
                <li>
                    <Link to="/dashboard" className="text-primary hover:underline">
                        Dashboard
                    </Link>
                </li>

                <li className="before:content-['/'] ltr:before:mr-2 rtl:before:ml-2">
                    <span>  Scheduled message reports</span>
                </li>
            </ul>
        </div><div className="mt-4 ">

            
                {crons ? (
                    <ExportDataTable
                        columns={columns}
                        data={crons?.list ?? []}
                        total={crons?.total}
                        currentPage={1}
                        nextPage={0}
                        previousPage={0}
                        lastPage={1}
                        isLoading={loading}
                        filterComponent={undefined} />
                ) : (
                    <div className="flex flex-col items-center justify-center">
                        <CircularProgress />
                    </div>
                )}
            </div></>
    );
};

export default SchedulresReports;
