import React, { useState } from 'react';
import svg from '@/assets/bulksms 1.svg';
import { Link as ScrollLink } from 'react-scroll';
import { NavLink } from 'react-router-dom';
import IconArrowLeft from '../../components/Icon/IconArrowLeft';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { storage } from '../../utils/storage';

export default function Header1() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const { user } = useSelector((state: RootState) => state.user);

    const Token = storage.getToken();
    return (
        <header>
            <nav className="bg-white border-gray-200 px-4 lg:px-6 py-2.5 dark:bg-gray-800">
                <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl">
                    <a href="https://BULK SMS.com" className="flex items-center">
                        <img src={svg} className="mr-3 " alt="BULK SMS Logo" />
                    </a>
                    <div className="flex items-center lg:order-2">
                        <div className="hidden md:block lg:pr-4 lg:w-auto text-lg w-full lg:pt-0">
                            <ul className="tracking-wide font-medium lg:text-sm flex flex-row justify-center items-center gap-6 lg:gap-0">
                                <NavLink to="/" className="block md:px-4 text-lg transition cursor-pointer hover:text-primary">
                                    <span>Home</span>
                                </NavLink>
                                <ScrollLink
                                    activeClass="active"
                                    to="pricing"
                                    spy={true}
                                    smooth={true}
                                    offset={-70}
                                    duration={800}
                                    className="block md:px-4 text-lg transition cursor-pointer hover:text-primary"
                                >
                                    <span>Pricing</span>
                                </ScrollLink>
                                <ScrollLink
                                    activeClass="active"
                                    to="features"
                                    spy={true}
                                    smooth={true}
                                    offset={-70}
                                    duration={800}
                                    className="block md:px-4 transition text-lg cursor-pointer hover:text-primary"
                                >
                                    <span>Features</span>
                                </ScrollLink>
                                <NavLink
                                    to="/login"
                                    className="flex items-center justify-center bg-primary mx-2 transition-colors duration-300 ease-in-out rounded-full h-9 px-6 py-6"
                                    style={{ color: 'white' }}
                                >
                             <span className="font-normal text-base ">Login</span>
                                  
                                </NavLink>
                                <NavLink
                                    to="/register"
                                    className="flex items-center justify-center bg-primary transition-colors duration-300 ease-in-out rounded-full h-9 px-2 py-6"
                                    style={{ color: 'white' }}
                                >
                                    <span className="font-normal text-base ml-2">Get started</span>
                                    <div className="bg-white rounded-full h-7 w-7 flex items-center justify-center ml-4">
                                        <IconArrowLeft className="w-5 h-5 text-primary" />
                                    </div>
                                </NavLink>
                            </ul>
                        </div>
                        <button
                            onClick={toggleMenu}
                            className="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                            aria-controls="mobile-menu-2"
                            aria-expanded={isMenuOpen}
                        >
                            <span className="sr-only">Open main menu</span>
                            <svg className={`w-6 h-6 ${isMenuOpen ? 'hidden' : ''}`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    fillRule="evenodd"
                                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                                    clipRule="evenodd"
                                ></path>
                            </svg>
                            <svg className={`w-6 h-6 ${isMenuOpen ? '' : 'hidden'}`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    fillRule="evenodd"
                                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                    clipRule="evenodd"
                                ></path>
                            </svg>
                        </button>
                    </div>
                    <div className={`lg:hidden justify-between items-center w-full ${isMenuOpen ? 'block' : 'hidden'}`} id="mobile-menu-2">
                        <ul className="tracking-wide font-medium lg:text-sm flex justify-center items-center mt-4 flex-col gap-6 lg:gap-0">
                            <NavLink to="/" className="block md:px-4 text-lg transition cursor-pointer hover:text-primary">
                                <span>Home</span>
                            </NavLink>
                            <ScrollLink
                                activeClass="active"
                                to="pricing"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={800}
                                className="block md:px-4 text-lg transition cursor-pointer hover:text-primary"
                            >
                                <span>Pricing</span>
                            </ScrollLink>
                            <ScrollLink
                                activeClass="active"
                                to="features"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={800}
                                className="block md:px-4 transition text-lg cursor-pointer hover:text-primary"
                            >
                                <span>Features</span>
                            </ScrollLink>
                            <NavLink
                                    to="/login"
                                    className="flex items-center justify-center bg-primary mx-2 transition-colors duration-300 ease-in-out rounded-full h-9 px-6 py-6"
                                    style={{ color: 'white' }}
                                >
                             <span className="font-normal text-base ">Login</span>
                                  
                                </NavLink>
                            <NavLink
                                to="/register"
                                className="flex items-center justify-center bg-primary transition-colors duration-300 ease-in-out rounded-full h-9 px-2 py-6"
                                style={{ color: 'white' }}
                            >
                                <span className="font-normal text-base ml-2">Get started</span>
                                <div className="bg-white rounded-full h-7 w-7 flex items-center justify-center ml-4">
                                    <IconArrowLeft className="w-5 h-5 text-primary" />
                                </div>
                            </NavLink>
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    );
}
