import React, { useEffect } from 'react';

import ReactApexChart from 'react-apexcharts';
import { CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';
import { useGetWalletStatistics } from '../../api/groups';
import { IRootState } from '../../store';
import ExportDataTable, { TableColumn } from '../../components/datatable';
import { Link } from 'react-router-dom';

const WalletStatisticsReports = () => {
    const { walletStatistics, loading, error, fetchWalletStatistics } = useGetWalletStatistics();

    useEffect(() => {
        fetchWalletStatistics();
    }, []);

    console.log(walletStatistics);
    const columns: TableColumn<any>[] = [
        {
            title: 'Date',
            accessor: 'date',
            render: (row) => <p>{row.date}</p>,
        },
        {
            title: 'Amount',
            accessor: 'amount',
            render: (row) => <p>{row.amount}</p>,
        },
       
    ];

    return (
        <><div>
            <ul className="flex space-x-2 rtl:space-x-reverse">
                <li>
                    <Link to="/dashboard" className="text-primary hover:underline">
                        Dashboard
                    </Link>
                </li>

                <li className="before:content-['/'] ltr:before:mr-2 rtl:before:ml-2">
                    <span>Wallet reports</span>
                </li>
            </ul>
        </div><div className="mt-4 ">

            
                {walletStatistics ? (
                    <ExportDataTable
                        columns={columns}
                        data={walletStatistics ?? []}
                        total={walletStatistics?.length}
                        currentPage={1}
                        nextPage={0}
                        previousPage={0}
                        lastPage={1}
                        isLoading={loading}
                        filterComponent={undefined} />
                ) : (
                    <div className="flex flex-col items-center justify-center">
                        <CircularProgress />
                    </div>
                )}
            </div></>
    );
};

export default WalletStatisticsReports;
