import React, { useState, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { Dialog, Transition } from '@headlessui/react';

import AllocateSms from '../../../Validations/alllocate';
import AllocateThunk from '../../../Redux/actions/assignSms';

export const Allocate = ({ user }: any) => {
    const [modal1, setModal1] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        resolver: yupResolver(AllocateSms),
    });

    const Signup: any = async (data: any, e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        console.log(data);
        try {
            const userId = user.id;

            const datas = { ...data, userId };

            const response: any = await dispatch(AllocateThunk(datas)).then((action: any) => unwrapResult(action));

            toast.success('Sms Allocated ');

            setModal1(false);
        } catch (e: any) {
            toast.error(e);
        }
    };
    return (
        <div className="">
            <div className="flex items-center justify-center">
                <button type="button" onClick={() => setModal1(true)} className="btn btn-primary btn-sm m-1">
                   
                    Allocate SMS
                </button>
            </div>
            <Transition appear show={modal1} as={Fragment}>
                <Dialog as="div" open={modal1} onClose={() => setModal1(false)}>
                    <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                        <div className="fixed inset-0" />
                    </Transition.Child>
                    <div className="fixed inset-0 bg-[black]/60 z-[999] overflow-y-auto">
                        <div className="flex items-start justify-center min-h-screen px-4">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel as="div" className="panel border-0 p-0 rounded-lg overflow-hidden my-8 w-full max-w-lg text-black dark:text-white-dark">
                                    <div className="flex bg-[#fbfbfb] dark:bg-[#121c2c] items-center justify-between px-5 py-3">
                                        <div className="text-lg flex flex-row justify-center w-full ">Allocate sms</div>
                                    </div>
                                    <div className="modal-content m-5 ">
                                        <form className="space-y-5 dark:text-white" onSubmit={handleSubmit(Signup)}>
                                            <div>
                                                <label htmlFor="Email " className="font-medium">
                                                    sms
                                                </label>
                                                <div className="relative text-white-dark">
                                                    <input id="sms" type="number " placeholder="Enter number of sms " className="form-input  placeholder:text-white-dark" {...register('smsCount')} />
                                                </div>
                                                {errors.smsCount && <p className="text-red-500 text-xs">sms number is required</p>}
                                            </div>

                                            <button type="submit" className="btn btn-lg capitalize w-full btn-primary">
                                                <span>Allocate</span>
                                            </button>
                                        </form>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </div>
    );
};
