import { ChangeEvent, useEffect, useState } from 'react';
import img1 from '@/assets/images/Screenshot_2023-12-19_223119-removebg-preview.png';
import Header from './Header';
import IconSend from '../../components/Icon/IconSend';
import IconUser from '../../components/Icon/IconUser';
import IconCashBanknotes from '../../components/Icon/IconCashBanknotes';
import { yupResolver } from '@hookform/resolvers/yup';
import { Input, CircularProgress } from '@mui/material';
import { unwrapResult } from '@reduxjs/toolkit';
import { useForm } from 'react-hook-form';
import FileSaver from 'file-saver';
import toast from 'react-hot-toast';
import { EXCEL_FILE_BASE64 } from '../..//utils/constant';
import { useSelector, useDispatch } from 'react-redux';
import senderIDThunk from '../../Redux/actions/GetSenderIds';
import PostSenderIdThunk from '../../Redux/actions/PostSenderId';
import DataThunk from '../../Redux/actions/getApidata';
import Smschema from '../../Validations/sendSms';
import TextArea from '../../components/Textarea';
import { RootState, AppDispatch } from '../../store';
import SendThunk from '../../Redux/actions/sendSms';
import { storage } from '../../utils/storage';
import signupSchema from '../../Validations/register';
import RegisterThunk from '../../Redux/actions/register';
import UserThunk from '../../Redux/actions/user';
import IconMail from '../../components/Icon/IconMail';
import IconLockDots from '../../components/Icon/IconLockDots';

import PurchaseSchema from '../../Validations/buy';
import PurchaseThunk from '../../Redux/actions/purchase';
import IconDownload from '../../components/Icon/IconDownload';
import sendfilesms from '../../Validations/sendfile';
import sendfiless from '../../Validations/sendFiles';
import { Navigate, useNavigate } from 'react-router-dom';
import Header2 from './Header2';
import Header1 from './Header2';

export default function GetStarted() {
    const [activeTab4, setActiveTab4] = useState<any>(1);
    const dispatch = useDispatch();
    const Token = storage.getToken();
const [amount,setAmount] = useState()
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        resolver: yupResolver(sendfilesms),
    });
    return (
        <div className='font-normal '>
            <div aria-hidden="true" className="absolute  grid grid-cols-2 -space-x-52 opacity-40 dark:opacity-20">
                <div className="blur-[106px] h-56 bg-gradient-to-br from-primary to-purple-400 dark:from-blue-700"></div>
                <div className="blur-[106px] h-32 bg-gradient-to-r from-cyan-400 to-sky-300 dark:to-indigo-600"></div>
            </div>
            <Header1/>
            <div className="flex flex-row pt-36  font-normal ">
            <div className="w-1/3 hidden md:block">
    <img src={img1} alt="" />
</div>

                <div className=" md:w-3/4 w-full  bg-white dark:bg-gray-900 p-5 ">
                    <div className="inline-block w-full  ">
                        <div className="relative z-[1]">
                            <div
                                className={`${activeTab4 === 1 ? 'w-[15%]' : activeTab4 === 2 ? 'w-[48%]' : activeTab4 === 3 ? 'w-[81%]' : ''}
           bg-primary w-[15%] h-1 absolute ltr:left-0 rtl:right-0 top-[30px] m-auto -z-[1] transition-[width]`}
                            ></div>
                         
                            <ul className="mb-5 grid grid-cols-3 mx-5 ">
                                <li className="mx-auto dark:text-white">
                                    <button
                                        type="button"
                                        className={`${activeTab4 === 1 ? '!border-primary !bg-primary text-white dark:text-white ' : ''}
                   border-[3px] border-[#f3f2ee] bg-white dark:bg-[#253b5c] dark:border-[#1b2e4b] flex justify-center items-center w-16 h-16 rounded-full`}
                                        onClick={() => setActiveTab4(1)}
                                    >
                                        <IconSend />
                                    </button>
                                    <span className={`${activeTab4 === 1 ? 'text-primary ' : ''}text-center   block mt-2`}>Send message</span>
                                </li>
                                <li className="mx-auto dark:text-white">
                                    <button
                                        type="button"
                                        className={`${activeTab4 === 2 ? '!border-primary !bg-primary  text-white' : ''}
                   border-[3px] border-[#f3f2ee] bg-white dark:bg-[#253b5c] dark:border-[#1b2e4b] flex justify-center items-center w-16 h-16 rounded-full`}
                                        onClick={() => setActiveTab4(2)}
                                    >
                                        <IconUser />
                                    </button>
                                    <span className={`${activeTab4 === 2 ? 'text-primary ' : ''}text-center block mt-2`}>Account information</span>
                                </li>
                                <li className="mx-auto dark:text-white ">
                                    <button
                                        type="button"
                                        className={`${activeTab4 === 3 ? '!border-primary !bg-primary text-white' : ''}
                   border-[3px] border-[#f3f2ee] bg-white dark:bg-[#253b5c] dark:border-[#1b2e4b] flex justify-center items-center w-16 h-16 rounded-full`}
                                        onClick={() => setActiveTab4(3)}
                                    >
                                        <IconCashBanknotes />
                                    </button>
                                    <span className={`${activeTab4 === 3 ? 'text-primary ' : ''}text-center block mt-2`}> Purchase SMS</span>
                                </li>
                            </ul>
                            
                        </div>


                        <div className="">
                            <p className="mb-5">{activeTab4 === 1 && <SendMany activeTab4={activeTab4}  setActiveTab4 = {setActiveTab4}/>}</p>

                            <p className="mb-5">{activeTab4 === 2 && <Signup setActiveTab4 = {setActiveTab4} activeTab4={activeTab4} />}</p>

                            <p className="mb-5">{activeTab4 === 3 && <Payment />}</p>
                        </div>
                      

                      
                       
                    </div>
                </div>
            </div>
        </div>
    );
}

function SendMany({ activeTab4,setActiveTab4 }: any) {
    const { user, loading: userLoad } = useSelector((state: RootState) => state.user);
    const { data: Api_data, loading }: any = useSelector((state: RootState) => state.data);

    const { data: senderIdData, loading: loader, errorMessage }: any = useSelector((state: RootState) => state.senderId);
    const dispatch = useDispatch<AppDispatch>();
    const [selectedSenderId, setSelectedSenderId] = useState('');
    const [senderError, setSenderError] = useState('');
    const [message, setMessage] = useState('');
    const [smsCount, setSmsCount] = useState(1);
    const maxMessageLength = 160;

    useEffect(() => {
        dispatch(DataThunk());
    }, [dispatch]);

    useEffect(() => {
        dispatch(senderIDThunk());
    }, []);

    useEffect(() => {
        const messageLength = message.length;
        const smsCount = Math.ceil(messageLength / maxMessageLength);
        setSmsCount(smsCount > 0 ? smsCount : 1);
    }, [message]);

    const handleMessageChange = (event: any) => {
        const newMessage = event.target.value.replace(/[\r\n]/g, '');
        setMessage(newMessage);

        if (newMessage.length > 0) {
            // Do something with the message
        }
    };

    const Token = storage.getToken();
    const { isLoading } = useSelector((state: RootState) => state.send);

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        resolver: yupResolver(sendfiless),
    });

    const [Recipients, setRecipients] = useState<any>();
    const [numbers, setNumbers] = useState<string>('');

    const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            try {
                const XLSX = await import('xlsx');
                readExcelFile(file, XLSX);
            } catch (error) {
                console.error('Error reading Excel file', error);
                toast.error('Error reading Excel file');
            }
        }
    };

    const readExcelFile = (file: File, XLSX: any) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = e.target?.result;
            processExcelData(data, XLSX);
        };
        reader.readAsBinaryString(file);
    };

    const processExcelData = (data: any, XLSX: any) => {
        const workbook = XLSX.read(data, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        const recipientsColumnData = jsonData
            .map((row: any) => row[recipientsColumnIndex])
            .filter((recipient: any) => recipient !== undefined && recipient !== null)
            .map((recipient: any) => `250${recipient}`);

        setRecipients(recipientsColumnData);
    };

    const recipientsColumnIndex = 1;

    const handleDownload = () => {
        let sliceSize = 1024;
        let byteCharacters = atob(EXCEL_FILE_BASE64);
        let bytesLength = byteCharacters.length;
        let slicesCount = Math.ceil(bytesLength / sliceSize);
        let byteArrays = new Array(slicesCount);
        for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
            let begin = sliceIndex * sliceSize;
            let end = Math.min(begin + sliceSize, bytesLength);
            let bytes = new Array(end - begin);
            for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
                bytes[i] = byteCharacters[offset].charCodeAt(0);
            }
            byteArrays[sliceIndex] = new Uint8Array(bytes);
        }
        FileSaver.saveAs(new Blob(byteArrays, { type: 'application/vnd.ms-excel' }), 'my-excel.xlsx');
    };

    const onSubmit = async (formData: any,e:any) => {
        e.preventDefault()
        if (user?.role !== 'trusted_developer') {
            const response = await dispatch(PostSenderIdThunk({ name: selectedSenderId }));
        }
        try {
            
                const Key: any = Api_data[0].secret;
                const recipients = [...Recipients, ...numbers.split(',').map((n) => `25${n.trim()}`)];
                const finalFormData = {  ...formData ,recipients };

                const response = await dispatch(SendThunk({ formData: finalFormData, Key }));
                unwrapResult(response);

                reset();
                setActiveTab4((prevTab:any) => (prevTab === 1 ? 2 : 3));

                toast.success('Message sent');
            
        } catch (e: any) {
            console.error('Error sending message', e);
            setActiveTab4((prevTab:any) => (prevTab === 1 ? 2 : 3));

            toast.error('Please wait while we approve your sender Id');
        }
    };

let money =0


const allCont = [...(Recipients || []), ...(numbers ? numbers.split(',').map((n) => `25${n.trim()}`) : [])];

if (allCont.length < 1000) {
    money = allCont.length * 15;
}
        
    return (
        <>
          <div className="m-2">
    <div className="w-full p-2">
        <form className="flex flex-col mt-2 dark:text-white " onSubmit={handleSubmit(onSubmit)}>
            <div className="my-2">
                <label htmlFor="Email" className="">
                    sender Name
                </label>
                <div className="relative text-white-dark">
                    <input
                        id="text"
                        type="name"
                        placeholder="Enter sender name"
                        className="form-input placeholder:text-white-dark"
                        {...register('senderId')}
                    />
                </div>
                {errors.senderId && <p className="text-red-500 text-sm">Sender Name is required</p>}
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 my-4">
                <div>
                    <label htmlFor="Email" className="">
                        Phone Numbers
                    </label>
                    <div className="relative text-white-dark">
                        <textarea
                            id="text"
                            placeholder="phone numbers ex. 078xxxxxx"
                            className="form-textarea h-36 placeholder:text-white-dark"
                            {...register('phoneNumbers')}
                            onChange={(event) => setNumbers(event.target.value)}
                        />
                    </div>
                    {errors.phoneNumbers && <p className="text-red-500 text-sm">Phone Numbers are required</p>}
                </div>

                <div>
                    <div className="flex flex-col">
                        <label htmlFor="" className="">
                            {' '}
                            Or Import contacts from Excel
                        </label>
                        <label htmlFor="" className="">
                            {' '}
                            Download template
                        </label>

                        <button type="button" className="btn btn-sm btn-info" onClick={handleDownload}>
                            <IconDownload />
                        </button>
                    </div>

                    <div>
                        <label htmlFor="" className="">
                            {' '}
                            Excel file
                        </label>

                        <input type="file" className="form-input" onChange={handleFileChange} accept=".xls, .xlsx" />
                    </div>
                </div>
            </div>

            <div className="mt-7">
                <label htmlFor="" className="">
                    {' '}
                    Numbers Extracted
                </label>
                <TextArea className="" placeholder="No number Found" disabled={true} value="numbers" defaultValue={Recipients} />
            </div>

            <div className="my-2">
                <div className="flex flex-col sm:flex-row justify-between">
                    <label htmlFor="" className="">
                        Message
                    </label>
                    <div className="">
                        <p className="text-gray-600 dark:text-gray-400">{message.length}/160</p>
                    </div>
                </div>
                <div className="relative text-white-dark">
                    <textarea
                        id="text"
                        placeholder="Message"
                        className="form-textarea h-24 placeholder:text-white-dark"
                        {...register('message')}
                        onChange={handleMessageChange}
                    />
                </div>
                {errors.message && <p className="text-red-500 text-sm">Message is required</p>}
            </div>

            <p>The total amount to be paid will be <span className="text-lg font-bold">{money}</span> RWF</p>
            <div className="flex justify-between">
                <button
                    type="submit"
                    className={`btn btn-primary ${activeTab4 === 1 ? 'hidden' : ''}`}
                    onClick={() => setActiveTab4(activeTab4 === 3 ? 2 : 1)}
                >
                    Back
                </button>
                <button type="submit" className="btn btn-primary sm:ml-auto">
                    {activeTab4 === 3 ? 'Finish' : 'Next'}
                </button>
            </div>
        </form>
    </div>
</div>

        </>
    );
}

function Signup( {activeTab4,setActiveTab4}:any) {
    const { error, errorMessage, isLoading, access_token }: any = useSelector((state: RootState) => state.register);
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        resolver: yupResolver(signupSchema),
    });
    const dispatch = useDispatch();
    const Signup: any = async (data: any, e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        console.log(data);
        try {
            const email = data.email;
            const firstName = data.firstName;
            const lastName = data.lastName;
            const password = data.password;
            const datas = { email, lastName, firstName, password };

            const response: any = await dispatch(RegisterThunk(datas)).then((action: any) => unwrapResult(action));

            console.log(response);

            localStorage.setItem('Ishema-token', response.access_token);
            toast.success('Register successful');
          
            setActiveTab4(3);

        } catch (e: any) {
            console.log(e)
            toast.error(e);
        }
    };

   

    return (
        <div className="modal-content m-5 ">
            {' '}
            <form className="space-y-5 dark:text-white" onSubmit={handleSubmit(Signup)}>
                <div>
                    <label htmlFor="Email " className="">
                        First Name
                    </label>
                    <div className="relative text-white-dark">
                        <input id="text" type="name" placeholder="Enter first name" className="form-input ps-10 placeholder:text-white-dark" {...register('firstName')} />
                        <span className="absolute start-4 top-1/2 -translate-y-1/2">
                            <IconUser fill={true} />
                        </span>
                    </div>
                    {errors.firstName && <p className="text-red-500 text-xs">First name is required</p>}
                </div>
                <div>
                    <label htmlFor="Email " className="">
                        Last Name
                    </label>
                    <div className="relative text-white-dark">
                        <input id="text" type="text" placeholder="Enter last name" className="form-input ps-10 placeholder:text-white-dark" {...register('lastName')} />
                        <span className="absolute start-4 top-1/2 -translate-y-1/2">
                            <IconUser fill={true} />
                        </span>
                    </div>
                    {errors.lastName && <p className="text-red-500 text-xs">Last Name is required</p>}
                </div>
                <div>
                    <label htmlFor="Email " className="font-medium">
                        Email
                    </label>
                    <div className="relative text-white-dark">
                        <input id="email" type="email" placeholder="Enter Email" className="form-input ps-10 placeholder:text-white-dark" {...register('email')} />
                        <span className="absolute start-4 top-1/2 -translate-y-1/2">
                            <IconMail fill={true} />
                        </span>
                    </div>
                    {errors.email && <p className="text-red-500 text-xs">Email is required</p>}
                </div>

                <div>
                    <label htmlFor="Password" className="">
                        Password
                    </label>
                    <div className="relative text-white-dark">
                        <input id="Password" type="password" placeholder="Enter Password" className="form-input ps-10 placeholder:text-white-dark" {...register('password')} />
                        <span className="absolute start-4 top-1/2 -translate-y-1/2">
                            <IconLockDots fill={true} />
                        </span>
                    </div>
                    {errors.password && <p className="text-red-500 text-xs">Password is required</p>}
                </div>
                <div>
                    <label htmlFor="Password" className="font-medium">
                        confirm password
                    </label>
                    <div className="relative text-white-dark">
                        <input id="Password" type="password" placeholder="Confirm Password" className="form-input ps-10 placeholder:text-white-dark" {...register('confirmPassword')} />
                        <span className="absolute start-4 top-1/2 -translate-y-1/2">
                            <IconLockDots fill={true} />
                        </span>
                    </div>
                    {errors.confirmPassword && <p className="text-red-500 text-xs"> Password does not match</p>}
                </div>
                <div className="flex justify-between">
                            <button type="submit" className={`btn btn-primary ${activeTab4 === 1 ? 'hidden' : ''}`} onClick={() => setActiveTab4(activeTab4 === 3 ? 2 : 1)}>
                                Back
                            </button>
                            <button type="submit" className="btn btn-primary ltr:ml-auto rtl:mr-auto" >
                                {
                                    isLoading ? (
                                    <span> Wait ...</span>
                                    ):(
                                        <span>{activeTab4 === 3 ? 'Finish' : 'Next'}</span>
                                    )
                                }
                                
                            </button>
                        </div>
            </form>
        </div>
    );
}

function Payment() {
    const Token = storage.getToken();
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        resolver: yupResolver(PurchaseSchema),
    });
const navigate = useNavigate()
    const { user, loading: userLoad } = useSelector((state: RootState) => state.user);
    const dispatch = useDispatch();
    const Signup: any = async (data: any, e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        console.log(data);

        if (!user || !Token) {
            toast.error('Please login');
        } else {
            try {
                const response: any = await dispatch(PurchaseThunk(data)).then((action: any) => unwrapResult(action));

                toast.success('Check Phone to complete Payment');
                navigate('/dashboard')
            } catch (e: any) {
                toast.error(e);
            }
        }
    };
    return (
        <div>
            <div className=" m-5 ">
                <form className="space-y-5 dark:text-white" onSubmit={handleSubmit(Signup)}>
                    <div>
                        <label htmlFor="Email " className="">
                            Phone Number
                        </label>
                        <div className="relative text-white-dark">
                            <input id="text" type="text" placeholder="Enter number to pay with " className="form-input  placeholder:text-white-dark" {...register('phoneNumber')} />
                        </div>
                        {errors.phoneNumber && <p className="text-red-500 text-xs">Phone is required</p>}
                    </div>
                    <div>
                        <label htmlFor="Email " className="font-medium">
                            sms
                        </label>
                        <div className="relative text-white-dark">
                            <input id="sms" type="number " placeholder="Enter number of sms " className="form-input  placeholder:text-white-dark" {...register('sms')} />
                        </div>
                        {errors.sms && <p className="text-red-500 text-xs">sms number is required</p>}
                    </div>

                    <button type="submit" className="btn btn-lg capitalize w-full btn-primary">
                        <span>Pay</span>
                    </button>
                </form>
            </div>
        </div>
    );
}
