import React, { useEffect, useRef, useState } from 'react';
import ExportDataTable, { TableColumn } from '../../components/datatable';
import formatDateToLongForm from '../../utils/Date';
import { useGetContacts, useGetSmsRequest } from '../../api/groups';
import { Link, useLocation, useParams } from 'react-router-dom';
import AdminCreateUser from '../users/AdminCreateUser';
import MessageModal from '../schedules/MessageModal';
import { use } from 'i18next';

function SmsRequests() {
   const {scheduleId} :any = useParams()
   const {status}:any = useParams()
   const {loading,requests, fetchRequests}:any= useGetSmsRequest()

  const location = useLocation();

  const query = location.search;
  const updatePageSizeInQuery = (query: string) => {
    const urlParams = new URLSearchParams(query);
    urlParams.set('status', status);
    urlParams.set('scheduledId', scheduleId);
    return '?' + urlParams.toString();
};
  const [expandedMessage, setExpandedMessage] = useState<number | null>(null);

  const handleExpandMessage = (index: number) => {
      if (expandedMessage === index) {
          setExpandedMessage(null);
      } else {
          setExpandedMessage(index);
      }
  };
  useEffect(() => {
    const updatedQuery = updatePageSizeInQuery(query);
  fetchRequests(updatedQuery)
  }, [query
    
  ])
  

    const columns: TableColumn<any>[] = [
        {
            title: 'Group Name',
            accessor: 'name',
            render: (row) => <p>{row.group?.name}</p>,
        },
        {
            title: 'Sender name',
            accessor: 'senderId',
            render: (row) => <p>{row?.senderId}</p>,
        },   
        {
            title: 'Phone Number',
            accessor: 'phoneNumber',
            render: (row) => <p>{row?.phoneNumber}</p>,
        }, 
        {
            title: 'Message',
            accessor: 'message',
            render: (row, index) => (
                <p onClick={() => handleExpandMessage(index)} className=" hover:underline">
                    {row.message && row.message.length > 30 ? row.message.substring(0, 30) + '...' : row.message || 'Not Provided'}
                    <MessageModal isOpen={expandedMessage === index} onClose={() => handleExpandMessage(index)} message={row} />,
                </p>
            ),
        },
        {
            title: 'Status',
            accessor: 'status',
            render: row => {
                let badgeColor;
                let badgeText;

                switch (row?.status) {
                    case 'completed':
                        badgeColor = 'bg-primary';
                        badgeText = 'completed';
                        break;
                    case 'pending':
                        badgeColor = 'bg-warning';
                        badgeText = 'pending';
                        break;
                    case 'failed':
                        badgeColor = ' bg-danger ';
                        badgeText = 'failed';
                        break;
                    
                    default:
                        badgeColor = 'bg-gray-500';
                        badgeText = 'Unknown';
                }
                return (
                    <div className={`badge ${badgeColor} text-center p-1  `}>
                        {row?.status}
                    </div>
                );
            },
        },   
        {
            title: 'Created At',
            accessor: 'created_at',
            render: (row) => <p>{formatDateToLongForm(row.created_at)}</p>,
        },
       
    ];
    return (
        <div className=" flex  flex-col        ">
            <div>
                <div className="w-full flex flex-row justify-between items-center">
                    <div>
                        <ul className="flex space-x-2 rtl:space-x-reverse">
                            <li>
                                <Link to="/dashboard" className="text-primary hover:underline">
                                    Dashboard
                                </Link>
                            </li>

                            <li>
                                <Link to="/dashboard/schedule-reports" className="text-primary hover:underline">
                                  / Schedule reports
                                </Link>
                            </li>
                            <li className="before:content-['/'] ltr:before:mr-2 rtl:before:ml-2">
                                <span>Scheduled message receivers</span>
                            </li>
                        </ul>
                    </div>

                  
                </div>
            </div>
            <ExportDataTable
                columns={columns}
                data={requests?.list ?? []}
                total={requests?.total ?? 0}
                currentPage={requests?.currentPage?? 1}
                nextPage={requests?.nextPage ?? 0}
                previousPage={requests?.previousPage ?? 0}
                lastPage={requests?.lastPage ?? 0}
                isLoading={loading}
                filterComponent={undefined}
            />
        </div>
    );
}

export default SmsRequests;
