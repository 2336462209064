import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import AdminCreateUser from './AdminCreateUser';
import { AdminEdituser } from './AdminEdituser';
import { Allocate } from './Allocatesms';
import ExportDataTable, { TableColumn } from '../../components/datatable';
import DeleteUser from './deletUser';
import UsersThunk from '../../Redux/actions/users';
import { AppDispatch, RootState } from '../../store';
import formatDateToLongForm from '../../utils/Date';
import { AssignRole } from './AssignRole';


function Users() {
    const [expandedMessage, setExpandedMessage] = useState<number | null>(null);
    const [expandedNNumbers, SetexpandedNNumbers] = useState<number | null>(null);
    const dispatch = useDispatch<AppDispatch>();
    const { data, loading }:any = useSelector((state: RootState) => state.GetUsers);
   
    const [selectedApiSecret, setSelectedApiSecret] = useState('');
    const [statusFilter, SetStatusFilter] = useState('');
    const [from, setStartDateFilter] = useState('');
    const [to, setEndDateFilter] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setpageSize] = useState(15);
    const handleSelectChange = (selectedSecret: string) => {
        setSelectedApiSecret(selectedSecret);
    };

    useEffect(() => {
        const queryParams = new URLSearchParams({
            pageNumber: currentPage.toString(),
            pageSize: pageSize.toString(),
            api_key: selectedApiSecret,
            status: statusFilter,
        });

        if (from) {
            queryParams.append('from', from);
        }

        if (to) {
            queryParams.append('to', to);
        }

        dispatch(UsersThunk(queryParams.toString()));
    }, [currentPage, selectedApiSecret, statusFilter, from, to, pageSize]);

    const handleFilter = (idToRemove: any) => {
        const filteredReports = data.filter((report: any) => report.id !== idToRemove);
    };

    const columns: TableColumn<any>[] = [
        {
            title: 'First Name',
            accessor: 'firstName',
            render: (row) => <p>{row.firstName}</p>,
        },
        {
            title: 'LastName',
            accessor: 'lastName',
            render: (row) => <p>{row.lastName}</p>,
        },
        {
            title: 'Email',
            accessor: 'email',
            render: (row) => <p>{row.email}</p>,
        },
        {
            title: 'Role ',
            accessor: 'role',
            render: (row) => <p>{row.role}</p>,
        },

        {
            title: 'created At',
            accessor: 'created_at',
            render: (row) => <p>{formatDateToLongForm(row.created_at)}</p>,
        },
        {
            title: 'Actions',
            accessor: 'created_at',
            render: (row) => (
                <p className="flex flex-row gap-2 ">
                    {' '}
                    <AdminEdituser user={row} />
                    <DeleteUser user={row} handleFilter={handleFilter} />
                    <AssignRole user={row} />
                    <Allocate user={row} />
                </p>
            ),
        },
    ];
    return (
        <div className=" flex  flex-col        ">
            <div>
                <div className="w-full flex flex-row justify-between items-center">
                    <div>
                        <ul className="flex space-x-2 rtl:space-x-reverse">
                            <li>
                                <Link to="/dashboard" className="text-primary hover:underline">
                                    Dashboard
                                </Link>
                            </li>

                            <li className="before:content-['/'] ltr:before:mr-2 rtl:before:ml-2">
                                <span>Users</span>
                            </li>
                        </ul>
                    </div>

                    <AdminCreateUser />
                </div>
            </div>
            <ExportDataTable
                columns={columns}
                data={data ?? []}
                total={data?.length}
                currentPage={1}
                nextPage={0}
                previousPage={0}
                lastPage={1}
                isLoading={loading}
                filterComponent={undefined}
            />
        </div>
    );
}

export default Users;
