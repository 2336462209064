import React, { useEffect, useState, Fragment } from 'react';
import Input from '../../components/Input';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import { unwrapResult } from '@reduxjs/toolkit';
import ApiThunk from '../../Redux/actions/createApi';
import { yupResolver } from '@hookform/resolvers/yup';
import ApiSchema from '../../Validations/api';
import { useForm } from 'react-hook-form';
import { showErrorMessage, showSuccessMessage } from '../../utils/toast';
import 'react-toastify/dist/ReactToastify.css';
import { NavLink, Route, Routes } from 'react-router-dom';
import senderIDThunk from '../../Redux/actions/GetSenderIds';
import { Dialog, Transition, Tab } from '@headlessui/react';

import ExportDataTable, { TableColumn } from '../../components/datatable';
import formatDateToLongForm from '../../utils/Date';
import PostSenderIdThunk from '../../Redux/actions/PostSenderId';
import senderId from '../../Validations/senderId';
import toast from 'react-hot-toast';
import { Approve } from './ApproveSenderId';
import DeleteSenderId from './DeletESenderId';

export default function SenderId() {
    const dispatch = useDispatch<AppDispatch>();
    const { data: data, loading } = useSelector((state: RootState) => state.senderId);
    const { user } = useSelector((state: RootState) => state.user);

    useEffect(() => {
        dispatch(senderIDThunk());
    }, []);

    const {} = useForm({
        resolver: yupResolver(ApiSchema),
    });

    const [Keys, setKeys] = useState<any[]>([]);
    const [sortedData, setSortedData] = useState<any[]>([]);
    const [modal2, setModal2] = useState(false);
    useEffect(() => {
        setKeys(data);
        setSortedData([...data]);
    }, [data]);

    const handleFilter = (idToRemove: any) => {
        const filteredReports = Keys.filter((report: any) => report.id !== idToRemove);

        setKeys(filteredReports);
        setSortedData(filteredReports);
    };
    const columns: TableColumn<any>[] = [
        {
            title: 'Name',
            accessor: 'name',
            render: (row) => <p>{row.name}</p>,
        },

        {
            title: 'created At',
            accessor: 'created_at',
            render: (row) => <p>{formatDateToLongForm(row.created_at)}</p>,
        },
        {
            title: 'Environment',
            accessor: 'environment',
            textAlign: 'center',
            render: (row) => (
                <p className="">
                    {row.approved ? (
                        <span className="badge bg-success">Approved</span>
                    ) : (
                        <span className="badge bg-danger">Not Approved</span>
                    )}
                </p>
            ),
        },
        {
            title: 'Actions',
            accessor: 'actions',
            textAlign: 'center',
            render: (row) => (
                <div className="flex gap-2 justify-start">
               
                    <DeleteSenderId Sender={row} handleFilter={handleFilter} />
                    {
                        user && user?.role === 'admin' &&(
                            <Approve user = {row} handleRefetch = {handleRefetch}/>
                        )

                    }
                </div>
            ),
        },
    ];
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(senderId),
    });

    const { isLoading } = useSelector((state: RootState) => state.api);

    const submit = async (data: any) => {
        try {
            console.log(data);
            const response = await dispatch(PostSenderIdThunk(data)).then((action: any) => unwrapResult(action));

            toast.success('SenderID created');
            setModal2(false)
        } catch (e: any) {
            showErrorMessage(e);
        }
    };

    const handleRefetch = async () => {
       await  dispatch(senderIDThunk());
      };
    return (
        <>
            <div className="fle flex-col  w-full h-[20%] mt-2  ">
                <div className=" flex  flex-row  justify-between h-[50%]">
                    <div className=" w-[100%] flex flex-row justify-start items-center">
                    <h6 className="text-lg font-bold mb-5">Sender Id</h6>
                    </div>
                    <div className=" w-[100%] flex flex-row justify-end items-center">
                        <div className="">
                            <div className="flex items-center justify-center">
                                <button type="button" onClick={() => setModal2(true)} className="btn btn-primary">
                                    Add sender Id
                                </button>
                            </div>
                            <Transition appear show={modal2} as={Fragment}>
                                <Dialog as="div" open={modal2} onClose={() => setModal2(false)}>
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-out duration-300"
                                        enterFrom="opacity-0"
                                        enterTo="opacity-100"
                                        leave="ease-in duration-200"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    >
                                        <div className="fixed inset-0" />
                                    </Transition.Child>
                                    <div className="fixed inset-0 bg-[black]/60 z-[999] overflow-y-auto">
                                        <div className="flex items-center justify-center min-h-screen px-4">
                                            <Transition.Child
                                                as={Fragment}
                                                enter="ease-out duration-300"
                                                enterFrom="opacity-0 scale-95"
                                                enterTo="opacity-100 scale-100"
                                                leave="ease-in duration-200"
                                                leaveFrom="opacity-100 scale-100"
                                                leaveTo="opacity-0 scale-95"
                                            >
                                                <Dialog.Panel as="div" className="panel border-0 p-0 rounded-lg overflow-hidden w-full max-w-lg my-8 text-black dark:text-white-dark">
                                                    <div className="flex bg-[#fbfbfb] dark:bg-[#121c2c] items-center justify-between px-5 py-3">
                                                        <h5 className="font-bold text-lg">Create SenderId</h5>
                                                    </div>
                                                    <div className="p-5">
                                                        <form onSubmit={handleSubmit(submit)}>
                                                            <div className="form-group">
                                                                <Input className=" " type="text" name="name" placeholder=" Name " register={{ ...register('name') }} errors={errors?.name?.message} />
                                                            </div>

                                                            <div className="flex justify-end items-center mt-8">
                                                                <button type="button" className="btn btn-outline-danger" onClick={() => setModal2(false)}>
                                                                    Discard
                                                                </button>
                                                                <button type="submit" className="btn btn-primary ltr:ml-4 rtl:mr-4" >
                                                                    Save
                                                                </button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </Dialog.Panel>
                                            </Transition.Child>
                                        </div>
                                    </div>
                                </Dialog>
                            </Transition>
                        </div>
                    </div>
                </div>
            </div>

            <ExportDataTable columns={columns} data={data ?? []} total={data?.length} currentPage={1} nextPage={0} previousPage={0} lastPage={0} isLoading={loading} filterComponent={undefined} />
        </>
    );
}
