import * as yup from "yup";

const sendfilesms = yup.object().shape({
  message: yup
    .string()
    .required("Message is required"),
  senderId: yup
    .string()
    .required("Sender ID is required")

});

export default sendfilesms
  ;
