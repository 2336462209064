import React, { useEffect } from 'react';
import jwt_decode from 'jwt-decode';
import { Link, useNavigate } from 'react-router-dom';
import DashbordThunk from '../Redux/actions/dashboard';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store';
import { DashCard } from '../components/DashCard';
import { FaComments, FaUsers, FaWallet, FaKey } from 'react-icons/fa';
import { CircularProgress } from '@mui/material';
import WalletStatisticsChart from '../components/wallet-chart';

function Dashbord() {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const { data, loading } = useSelector((state: RootState) => state.dashboard);
    useEffect(() => {
        dispatch(DashbordThunk());
    }, [dispatch]);
    const { access_token, isLoading } = useSelector((state: RootState) => state.login);

    useEffect(() => {
        const storedToken = localStorage.getItem('Ishema-token');

        if (!isLoading && !storedToken && !access_token) {
            navigate('/login');
        } else if (storedToken) {
            try {
                const decodedToken: any = jwt_decode(storedToken);

                const currentTime = Date.now() / 1000;

                if (decodedToken.exp < currentTime) {
                    navigate('/login');
                }
            } catch (error) {
                console.log('Error decoding token:', error);
                navigate('/login');
            }
        }
    }, [isLoading, access_token, navigate]);

    const { user } = useSelector((state: RootState) => state.user);

    return (
        <>
            <div className="flex flex-col w-full">
                <ul className="flex space-x-2 rtl:space-x-reverse">
                    <li>
                        <Link to="/" className="text-primary hover:underline">
                            Dashboard
                        </Link>
                    </li>
                </ul>

                <div className="w-full ">
                    {loading || !data ? (
                        <div className='panel w-full'>
   <div className='  flex flex-col items-center justify-center'>
                         <CircularProgress />
                            </div>
                        </div>
                      
                    ) : (
                        <div className="w-full grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-2">
                            <DashCard title="Balance" value={data.balance ?? 0} icon={<FaWallet />} backgroundColor="#FF9800" iconColor="#FFFFFF" />
                            <Link to="messages">
                                <DashCard title="SMS sent " value={data.sent ?? 0} icon={<FaComments />} backgroundColor="#00BFA5" iconColor="#FFFFFF" />
                            </Link>

                            <Link to="settings">
                                <DashCard title="Keys" value={data.api_key ?? 0} icon={<FaKey />} backgroundColor="#2196F3" iconColor="#FFFFFF" />
                            </Link>

                            {user?.role === 'admin' && (
                                <Link to="users">
                                    <DashCard title="Users" value={8} icon={<FaUsers />} backgroundColor="#9C27B0" iconColor="#FFFFFF" />
                                </Link>
                            )}
                        </div>
                    )}
                </div>
                <div className="mt-6">
                    
                    <WalletStatisticsChart />
                </div>
            </div>
        </>
    );
}

export default Dashbord;
