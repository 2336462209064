import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link, useLocation } from 'react-router-dom';
import AnimateHeight from 'react-animate-height';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, IRootState, RootState } from '../../store';
import { useEffect, useState } from 'react';
import { toggleSidebar } from '../../store/themeConfigSlice';
import IconCaretsDown from '../Icon/IconCaretsDown';
import IconCaretDown from '../Icon/IconCaretDown';
import IconHome from '../Icon/IconHome';
import IconSettings from '../Icon/IconSettings';
import IconUsers from '../Icon/IconUsers';
import IconShoppingBag from '../Icon/IconShoppingBag';
import img from '@/assets/Screenshot_2024-06-05_233441-removebg-preview.png'
import IconAirplay from '../Icon/IconAirplay';
import IconUsersGroup from '../Icon/IconUsersGroup';
import IconFile from '../Icon/IconFile';

const Sidebar = () => {
    const [currentMenu, setCurrentMenu] = useState<string>('');
    const themeConfig = useSelector((state: IRootState) => state.themeConfig);
    const semidark = useSelector((state: IRootState) => state.themeConfig.semidark);
    const location = useLocation();
    const dispatch = useDispatch<AppDispatch>();

    const toggleMenu = (value: string) => {
        setCurrentMenu((oldValue) => {
            return oldValue === value ? '' : value;
        });
    };

    const { user } = useSelector((state: RootState) => state.user);

    const navigation = [
        {
            name: 'Dashboard',
            to: '/dashboard',
            icon: IconHome,
            current: location.pathname === '/dashboard',
        },
        
        {
            name: 'Send SMS',
            to: '/dashboard/send-message',
            icon: IconAirplay,
            current: location.pathname === '/dashboard/send-message',
        }
    ,
        {
            name: 'Purchase SMS',
            to: '/dashboard/pay',
            icon: IconShoppingBag,
            current: location.pathname === '/dashboard/pay',
        },
        {
            name: 'Scheduled SMS',
            to: '/dashboard/schedules',
            icon: IconShoppingBag,
            current: location.pathname === '/dashboard/schedules',
        },

        
        {
            name: 'Recipients',
            to: '/dashboard/contacts',
            icon: IconUsersGroup, 
            current: location.pathname === '/dashboard/contacts',
            items: [
                { name: 'Contacts', to: '/dashboard/contacts' },
                { name: 'Groups', to: '/dashboard/groups' },
            ],
        },
        {
            name: 'Reports',
            to: '/dashboard/messages',
            icon:IconFile , 
            current: location.pathname === '/dashboard/messages',
            items: [
                { name: 'Message Reports', to: '/dashboard/messages' },
                { name: 'Wallet reports', to: '/dashboard/wallet-reports' },
                { name: 'Schedule reports', to: '/dashboard/schedule-reports' },
            ],
        },
    ];


    if (user.role === 'admin' || user.role === 'super-admin') {
        navigation.push({
            name: 'users',
            to: '/dashboard/users',
            icon: IconUsers,
            current: location.pathname === '/dashboard/users',
        });
    }
    navigation.push({
        name: 'Settings',
        to: '/dashboard/settings',
        icon: IconSettings,
        current: location.pathname === '/dashboard/settings',
    });

    useEffect(() => {
        if (window.innerWidth < 1024 && themeConfig.sidebar) {
            dispatch(toggleSidebar());
        }
    }, [location]);

    return (
        <div className={'dark'}>
            <nav className={`sidebar capitalize fixed min-h-screen h-full top-0 bottom-0 w-[260px] shadow-[5px_0_25px_0_rgba(94,92,154,0.1)] z-50 transition-all duration-300`}>
                <div className="bg-white dark:bg-teal-900 h-full">
                    <div className="flex justify-between items-center px-4 py-3">
                        <Link to="/" className="main-logo flex w-[10vw] flex-col items-center shrink-0 ">
                            <img src={img} alt="" className='w-full h-full' />
                        </Link>
                        <button
                            type="button"
                            className="collapse-icon w-8 h-8 rounded-full flex items-center hover:bg-gray-500/10 dark:hover:bg-dark-light/10 dark:text-white-light transition duration-300 rtl:rotate-180"
                            onClick={() => dispatch(toggleSidebar())}
                        >
                            <IconCaretsDown className="m-auto rotate-90" />
                        </button>
                    </div>
                    
                    <PerfectScrollbar className="h-[calc(100vh)] relative">
                        <ul className="relative  space-y-0.5 p-4 py-0">
                            <li className="nav-item">
                                <ul>
                                    {navigation.map((item, index) => (
                                        <li key={index} className="nav-item">
                                            {item.items ? ( // Check if item has sub-items
                                                <button type="button" className={`nav-link group w-full`} onClick={() => toggleMenu(item.name)}>
                                                    <div className="flex items-center">
                                                        <item.icon className="group-hover:!text-primary shrink-0" />
                                                        <span className="ltr:pl-3 rtl:pr-3 text-black dark:text-white dark:group-hover:text-white">{item.name}</span>
                                                    </div>
                                                    <div className={currentMenu !== item.name ? 'rtl:rotate-90 -rotate-90' : ''}>
                                                        <IconCaretDown />
                                                    </div>
                                                </button>
                                            ) : (
                                                <Link to={item.to} className={`group ${item.current ? 'active text-white' : ''}`}>
                                                    <div className="flex items-center">
                                                        <item.icon className="group-hover:!text-primary shrink-0" />
                                                        <span className="ltr:pl-3 rtl:pr-3 text-black dark:text-white  dark:group-hover:text-white-dark">{item.name}</span>
                                                    </div>
                                                </Link>
                                            )}
                                            {item.items && (
                                                <AnimateHeight duration={300} height={currentMenu === item.name ? 'auto' : 0}>
                                                    <ul className="sub-menu text-gray-500">
                                                        {item.items.map((subItem, subIndex) => (
                                                            <li key={subIndex}>
                                                                <Link to={subItem.to}>{subItem.name}</Link>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </AnimateHeight>
                                            )}
                                        </li>
                                    ))}
                                    {/* {ItemDropDown(currentMenu, toggleMenu, {
                                        name: 'Recipient groups',
                                        Icon: UsersIcon,
                                        items: [
                                            {
                                                name: '',
                                                to: '/account/admin/group',
                                            },

                                            {
                                                name: 'Country Reports',
                                                to: '/account/admin/reports/country',
                                            }
                                        ],
                                    })} */}
                                </ul>
                            </li>
                        </ul>
                    </PerfectScrollbar>
                </div>
            </nav>
        </div>
    );
};

export default Sidebar;

type DropDownProps = {
    items: {
        name: string;
        to: string;
    }[];
    name: string;
    Icon: React.ForwardRefExoticComponent<
        Omit<React.SVGProps<SVGSVGElement>, 'ref'> & {
            title?: string | undefined;
            titleId?: string | undefined;
        } & React.RefAttributes<SVGSVGElement>
    >;
};

function ItemDropDown(currentMenu: string, toggleMenu: (value: string) => void, item: DropDownProps) {
    return (
        <li className="menu nav-item">
            <button type="button" className={`${currentMenu === item.name ? '' : ''} nav-link group w-full`} onClick={() => toggleMenu(item.name)}>
                <div className="flex items-center">
                    <item.Icon className="group-hover:!text-white shrink-0" />
                    <span className="ltr:pl-3 rtl:pr-3 text-black dark:text-white/80 dark:group-hover:text-white">{item?.name}</span>
                </div>

                <div className={currentMenu !== item.name ? 'rtl:rotate-90 -rotate-90' : ''}>
                    <IconCaretDown className="text-white" />
                </div>
            </button>

            <AnimateHeight duration={300} height={currentMenu === item.name ? 'auto' : 0}>
                <ul className="sub-menu text-white/80">
                    {item.items.map((item, index) => (
                        <li key={index}>
                            <Link to={item.to}>{item.name}</Link>
                        </li>
                    ))}
                </ul>
            </AnimateHeight>
        </li>
    );
}
