import * as yup from "yup";

const Smschema:any = yup.object().shape({
  recipients: yup
    .string()
    .required("Recipients are required")
    .test("isValidRecipients", "Invalid recipients format", (value) => {
      if (!value) return false;
      const phoneNumbers = value.split(",");

      return phoneNumbers.every((phoneNumber) => {
        const trimmedNumber = phoneNumber.trim();
        if (trimmedNumber.startsWith("250")) {
          // Phone number with country code (must be 12 digits)
          return trimmedNumber.length === 12;
        } else {
          // Phone number without country code (must be 10 digits and follow the specified format)
          return (
            trimmedNumber.length === 10 &&
            /^(078|072|079|073)\d{7}$/.test(trimmedNumber)
          );
        }
      });
    }),
  message: yup
    .string()
    .required("Message is required"),
  senderId: yup
    .string()
    .required("Sender ID is required")
});

export default Smschema;
