

import axios from 'axios';
import { storage } from '../../utils/storage';
import config from '../../config';


const PRIVATE_API = axios.create({
    baseURL: `${config.API_URL}api/v1/`,
    timeout: 120000,
    headers: {
        Authorization: `Bearer ${storage.getToken()}`
    }
});


export default PRIVATE_API;
