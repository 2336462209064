import { Tab } from '@headlessui/react';
import SendFile from './send-file';
import { Link } from 'react-router-dom';
import IconFile from '../../../components/Icon/IconFile';
import { Fragment } from 'react';
import IconAirplay from '../../../components/Icon/IconAirplay';
import SendGroup from './sendGroup';
import IconFolder from '../../../components/Icon/IconFolder';
import SendMany from './send-many';

function Sms() {
    const tabs = [
        {
            icon: IconAirplay,
            title: 'Send SMS',
            selected: true,
        },
        {
            icon: IconFile,
            title: 'Send from File',
            selected: true,
        },
        {
            icon: IconFolder,
            title: 'Send to Group',
            selected: true,
        },
    ];
    
    const tabItems = [<SendMany />, <SendFile />, <SendGroup />];
    return (
        <div className="text-gray-700 dark:grey-200">
            <ul className="flex space-x-2 rtl:space-x-reverse">
                <li>
                    <Link to="/" className="text-primary hover:underline">
                        Dashboard
                    </Link>
                </li>

                <li className="before:content-['/'] ltr:before:mr-2 rtl:before:ml-2 text-gray-400">
                    <span>Send message</span>
                </li>
            </ul>

            <div className="panel mt-2 " id="icon">
                <div className="mb-5">
                    <Tab.Group>
                        <Tab.List className="mt-3 mr-3 flex flex-wrap border-b border-white-light dark:border-[#39437b]">
                            {tabs.map((item, index) => (
                                <Tab key={index} as={Fragment}>
                                    {({ selected }) => (
                                        <button
                                            className={`${
                                                selected
                                                    ? '!border-white-light !border-b-white font-bold text-md text-primary capitalize  !outline-none dark:!border-[#39437b]  dark:!border-b-black'
                                                    : 'dark:text-gray-400 font-bold text-md capitalize'
                                            }
                              dark:hover:border-b-black' -mb-[1px] flex items-center border border-transparent p-3.5 py-2 hover:text-primary`}
                                        >
                                            <item.icon className="ltr:mr-2 w-5 rtl:ml-2  " />
                                            {item.title}
                                        </button>
                                    )}
                                </Tab>
                            ))}
                        </Tab.List>
                        <Tab.Panels>
                            {tabItems.map((item, index) => (
                                <Tab.Panel key={index}>
                                    <div className="">{item}</div>
                                </Tab.Panel>
                            ))}
                        </Tab.Panels>
                    </Tab.Group>
                </div>
            </div>
        </div>
    );
}

export default Sms;
