import { Dialog, Transition } from '@headlessui/react';
import { useState, Fragment } from 'react';
import { z } from 'zod';
import { useCreateContact, useCreateGroup } from '../../../api/groups';
import IconPlus from '../../../components/Icon/IconPlus';
import { Form } from '../../../components/form';
import InputField from '../../../components/inputField';
import { group } from 'console';

const schema = z.object({
    phoneNumber: z.string()
        .length(12, { message: 'Phone number must be 12 characters long' })
        .refine((value) => value.startsWith('250'), {
            message: 'Phone number must start with 250',
        }),
});

export default function CreateContact({ data, handeRefetch }: any) {
    const { creating, createContact } = useCreateContact();
    const [modal, setModal] = useState(false);

    return (
        <div className="">
            <div className="flex items-center justify-center">
                <button type="button" onClick={() => setModal(true)} className="btn btn-primary">
                    Add Contact
                </button>
            </div>
            <Transition appear show={modal} as={Fragment}>
                <Dialog as="div" open={modal} onClose={() => setModal(false)}>
                    <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                        <div className="fixed inset-0" />
                    </Transition.Child>
                    <div className="fixed inset-0 bg-[black]/60 z-[999] overflow-y-auto">
                        <div className="flex items-start justify-center min-h-screen px-4">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel as="div" className="panel border-0 p-0 rounded-lg overflow-hidden my-8 w-full max-w-lg text-black dark:text-white-dark">
                                    <div className="flex bg-[#fbfbfb] dark:bg-[#121c2c] items-center justify-between px-5 py-3">
                                        <div className="text-lg flex flex-row justify-center w-full font-bold ">Add Contact to {data?.name}</div>
                                    </div>
                                    <div className="p-5">
                                        <Form<{ phoneNumber: string }, typeof schema>
                                            onSubmit={async(inputs: any) => {
                                                const formData = { phoneNumber: inputs.phoneNumber, groupId: data.id };
                                               await createContact(formData);
                                                handeRefetch();
                                                setModal(false)
                                            }}
                                            schema={schema}
                                        >
                                            {({ register, formState }) => (
                                                <div className="w-full">
                                                    <div className="px-4 flex flex-col gap-4 text-sm mb-3">
                                                        <div className="flex flex-col md:flex-row gap-2">
                                                            <InputField
                                                                type="text"
                                                                isLoading={creating}
                                                                placeholder="phone start with 250"
                                                                label="Phone Number"
                                                                error={formState.errors.phoneNumber}
                                                                registration={register('phoneNumber')}
                                                            />
                                                        </div>
                                                        <div className="my-3 flex justify-end">
                                                            <button disabled={creating} className='btn btn-primary'>Submit</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </Form>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </div>
    );
}
