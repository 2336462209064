import React, { useState } from "react";

interface TextAreaProps {
  placeholder: string;
  className?: string;
  defaultValue?: string;
  register?: any;
  name?: string;
  errors?: any;
  max?: number;
  cols?: number;
  rows?: number;
  resize?: "vertical" | "horizontal";
  required?: boolean;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  value: string; 
}

function TextArea({
  placeholder,
  className = "",
  defaultValue = "",
  register = null,
  name = "",
  errors,
  disabled,
  required,
  max = 160,
}: TextAreaProps) {
  const [message, setMessage] = useState(defaultValue);

  const handleTextareaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const text = event.target.value;
    setMessage(text);
  };

  return (
    <div className="">
      <div
        className={`  ${className}`}
      >
        <textarea
        className="form-textarea h-32"
          defaultValue={defaultValue}
          required={required}
          name={name}
          disabled={disabled}
          {...register}
          data-testid="textarea-element"
          placeholder={placeholder}
          onChange={handleTextareaChange}
        />
      </div>
      {errors && (
        <p className="error text-red-500 text-xs italic" data-testid="errors">
          {errors}
        </p>
      )}
    
    </div>
  );
}

export default TextArea;
