import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store';
import ReportThunk from '../../../Redux/actions/messageReports';
import { Link, NavLink } from 'react-router-dom';
import DataThunk from '../../../Redux/actions/getApidata';
import ExportDataTable, { TableColumn } from '../../../components/datatable';
import formatDateToLongForm from '../../../utils/Date';
import { FunnelIcon } from '@heroicons/react/24/outline';

function MessageModal({ isOpen, onClose, message }: any) {
    if (!isOpen) {
        return null;
    }

    return (
        <div className="fixed z-50 top-50 inset-0 flex justify-center p-4 items-start bg-black bg-opacity-50">
            <div className="w-[30vw] bg-white  rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                <div className="">
                    <div className="bg-primary rounded-none flex flex-row justify-between">
                        <h5 className="mb-2 p-2  text-xl font-bold tracking-tight whitespace-pre-wrap text-white">{message.sender_id || 'Not Provided'}</h5>
                        <button className="p-2 text-white" onClick={onClose}>
                            <i className="fa-solid fa-xmark text-2xl"></i>
                        </button>
                    </div>
                    <p className="mb-3 font-normal p-2  text-gray-700 whitespace-pre-wrap dark:text-gray-400 w-fit">{message.message || 'Not Provided'}</p>
                </div>
            </div>
        </div>
    );
}

function NumbersModalbg({ isOpen, onClose, message }: any) {
    if (!isOpen) {
        return null;
    }

    return (
        <div className="fixed z-50 top-50 inset-0 flex justify-center p-4 items-start bg-black bg-opacity-50">
            <div className="w-[30vw] bg-white    rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                <div className="">
                    <div className="bg-primary rounded-none flex flex-row justify-between">
                        <h5 className="mb-2 p-2  text-xl font-bold tracking-tight whitespace-pre-wrap text-white">{message.sender_id || 'Not Provided'}</h5>
                        <button className="p-2 text-white" onClick={onClose}>
                            <i className="fa-solid fa-xmark text-2xl"></i>
                        </button>
                    </div>
                    <div className="max-h-[40vh] overflow-scroll scrollbar-hidden">
                        <p className="mb-3 font-normal p-2 text-gray-700 whitespace-pre-wrap dark:text-gray-400 w-fit" style={{ wordWrap: 'break-word', maxWidth: '100%' }}>
                            {message.phone_number ? message.phone_number.map((str: any) => str.replace(/"/g, '')).join(', ') : 'Not Provided'}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

function Filter({ Sender, handleFilter }: any) {
    const dialog = useRef<HTMLDialogElement>(null);

    const closeModel = () => {
        if (dialog.current?.close) dialog.current.close();
    };

    const openModel = () => {
        if (dialog.current?.showModal) dialog.current.showModal();
    };

    const close = (e: React.MouseEvent<HTMLElement>) => {
        const dialogDimensions = dialog.current?.getBoundingClientRect();
        if (e.clientX < dialogDimensions!.left || e.clientX > dialogDimensions!.right || e.clientY < dialogDimensions!.top || e.clientY > dialogDimensions!.bottom) {
            closeModel();
        }
    };
    const dispatch = useDispatch<AppDispatch>();
    const { isLoading } = useSelector((state: RootState) => state.deleteSenderId);
    const { data, loading } = useSelector((state: RootState) => state.report);

    const { data: Api_data } = useSelector((state: RootState) => state.data);
    useEffect(() => {
        dispatch(DataThunk());
    }, []);

    const queryParams = new URLSearchParams(location.search);
    const search = queryParams.get('search') || '';
    const pageNumber = Number(queryParams.get('pageNumber')) || 1;
    const pageSize = Number(queryParams.get('pageSize')) || 10;

    const [selectedApiSecret, setSelectedApiSecret] = useState('');
    const [statusFilter, SetStatusFilter] = useState('');
    const [from, setStartDateFilter] = useState('');
    const [to, setEndDateFilter] = useState('');

    const handleSelectChange = (selectedSecret: string) => {
        setSelectedApiSecret(selectedSecret);
    };
    const resetFilters = () => {
        setSelectedApiSecret('');
        SetStatusFilter('');
        setStartDateFilter('');
        setEndDateFilter('');
    };

    useEffect(() => {
        const queryParams = new URLSearchParams({
            pageNumber: pageNumber.toString(),
            pageSize: pageSize.toString(),
            api_key: selectedApiSecret,
            status: statusFilter,
            search: search,
        });

        if (from) {
            queryParams.append('from', from);
        }

        if (to) {
            queryParams.append('to', to);
        }

        dispatch(ReportThunk(queryParams.toString()));
    }, [pageNumber, selectedApiSecret, statusFilter, from, to, pageSize, pageNumber, search]);

    return (
        <>
            <dialog ref={dialog} className=" fixed rounded shadow-lg w-fit  left-[35vw] top-[1vh] dark:bg-[#121c2c]  " data-testid="dialog" onClick={(e) => close(e)}>
                <div className="p-4 rounded  w-[30vw] ">
                    <div className=" flex flex-col ">
                        <div className="flex flex-row justify-center items-center">
                            <p className="text-gray-600 text-lg dark:text-gray-400">Filter Messages</p>
                        </div>

                        <div className="report1 flex flex-col  my-2 ">
                            <label htmlFor="phone" className="   text-gray-600 dark:text-gray-400 ">
                                Filter by Api Key
                            </label>
                            <select className="form-select  " onChange={(event) => handleSelectChange(event.target.value)}>
                                {Api_data.map((item: any) => (
                                    <option key={item.id} value={item.secret}>
                                        {item.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="flex flex-col my-2 ">
                            <label htmlFor="message" className=" text-gray-600 dark:text-gray-400">
                                Filter by Status
                            </label>
                            <select name="filter" className="form-select " onChange={(e) => SetStatusFilter(e.target.value)}>
                                <option value=""> Status</option>
                                <option value="success">Success</option>
                                <option value="failed">Failed</option>
                                <option value="pending">Pending</option>
                            </select>
                        </div>

                        <div className=" flex flex-col  my-2">
                            <label htmlFor="date" className="  text-gray-600 dark:text-gray-400">
                                {' '}
                                Filter by Start date
                            </label>
                            <input type="date" className="form-input  " onChange={(e) => setStartDateFilter(e.target.value)} placeholder="Start date mm/dd/yy" />
                        </div>

                        <div className=" flex flex-col  my-2 ">
                            <label htmlFor="date" className=" text-gray-600 dark:text-gray-400 ">
                                {' '}
                                Filter by End date
                            </label>
                            <input type="date" onChange={(e) => setEndDateFilter(e.target.value)} className="form-input  text-gray-600 dark:text-gray-400" placeholder="End date mm/dd/yy" />
                        </div>

                        <div className="flex flex-row justify-start mt-4 ">
                            <button type="button" className="btn btn-outline-danger" onClick={closeModel}>
                                Discard
                            </button>
                            <button className="btn btn-primary mx-2 " onClick={resetFilters}>
                                Reset{' '}
                            </button>
                        </div>
                    </div>
                </div>
            </dialog>

            <button type="button" onClick={() => openModel()} className="btn btn-primary btn-sm m-1">
                <FunnelIcon className="w-5 h-5 ltr:mr-2 rtl:ml-2" />
                FILTER
            </button>
        </>
    );
}

function MessagaReports() {
    const [expandedMessage, setExpandedMessage] = useState<number | null>(null);
    const [expandedNNumbers, SetexpandedNNumbers] = useState<number | null>(null);
    const dispatch = useDispatch<AppDispatch>();
    const { data, loading } = useSelector((state: RootState) => state.report);
    const [sortedData, setSortedData] = useState<any>([]);

    const handleExpandMessage = (index: number) => {
        if (expandedMessage === index) {
            setExpandedMessage(null);
        } else {
            setExpandedMessage(index);
        }
    };
    const handelExpandedNumbers = (index: number) => {
        if (expandedNNumbers === index) {
            SetexpandedNNumbers(null);
        } else {
            SetexpandedNNumbers(index);
        }
    };
    useEffect(() => {
        if (data) {
            setSortedData(data?.list);
        }
    }, [data]);

    const getFormattedDate = (dateString: any) => {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear().toString().substr(-2);
        return `${day}/${month}/${year}`;
    };

    const [selectedApiSecret, setSelectedApiSecret] = useState('');
    const [statusFilter, SetStatusFilter] = useState('');
    const [from, setStartDateFilter] = useState('');
    const [to, setEndDateFilter] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setpageSize] = useState(15);
    const handleSelectChange = (selectedSecret: string) => {
        setSelectedApiSecret(selectedSecret);
    };
    const resetFilters = () => {
        setSelectedApiSecret('');
        SetStatusFilter('');
        setStartDateFilter('');
        setEndDateFilter('');
        setCurrentPage(1);
    };

    useEffect(() => {
        const queryParams = new URLSearchParams({
            pageNumber: currentPage.toString(),
            pageSize: pageSize.toString(),
            api_key: selectedApiSecret,
            status: statusFilter,
        });

        if (from) {
            queryParams.append('from', from);
        }

        if (to) {
            queryParams.append('to', to);
        }

        dispatch(ReportThunk(queryParams.toString()));
    }, [currentPage, selectedApiSecret, statusFilter, from, to, pageSize]);
    const columns: TableColumn<any>[] = [
        {
            title: 'Description',
            accessor: 'description',
            render: (row) => <p>{row.description}</p>,
        },
        {
            title: 'Type',
            accessor: 'type',
            render: (row) => <p>{row.type}</p>,
        },
        {
            title: 'Sender ',
            accessor: 'senderId',
            render: (row) => <p>{row.sender_id}</p>,
        },
        {
            title: 'Phone',
            accessor: 'phone',
            render: (row, index) => (
                <p onClick={() => handelExpandedNumbers(index)} className="hover:underline">
                    {' '}
                    {row.phone_number.length > 0 ? (row.phone_number.length === 1 ? row.phone_number[0].replace(/"/g, '') : row.phone_number[0].replace(/"/g, '') + ' ...') : 'Not Provided'}
                    <NumbersModalbg isOpen={expandedNNumbers === index} onClose={() => handelExpandedNumbers(index)} message={row} />
                </p>
            ),
        },
        {
            title: 'Amount',
            accessor: 'amount',
            render: (row) => <p>{row.amount}</p>,
        },
        {
            title: 'Message',
            accessor: 'message',
            render: (row, index) => (
                <p onClick={() => handleExpandMessage(index)} className=" hover:underline">
                    {row.message && row.message.length > 30 ? row.message.substring(0, 30) + '...' : row.message || 'Not Provided'}
                    <MessageModal isOpen={expandedMessage === index} onClose={() => handleExpandMessage(index)} message={row} />,
                </p>
            ),
        },
        
        {
            title: 'created At',
            accessor: 'created_at',
            render: (row) => <p>{formatDateToLongForm(row.created_at)}</p>,
        },
        {
            title: 'status',
            accessor: 'status',
            textAlign: 'center',
            render: (row) => <p className={`px-4  ${row.status === 'success' ? 'text-green-500' : 'text-red-500'}`}>{row.status}</p>,
        },
    ];
    return (
        <div className=" flex  flex-col       overflow-scroll scrollbar-hidden ">
            <div>
                <div className="w-full flex flex-row justify-between items-center">
                    <div>
                        <ul className="flex space-x-2 rtl:space-x-reverse">
                            <li>
                                <Link to="/dashboard" className="text-primary hover:underline">
                                    Dashboard
                                </Link>
                            </li>

                            <li className="before:content-['/'] ltr:before:mr-2 rtl:before:ml-2">
                                <span>Reports</span>
                            </li>
                        </ul>
                    </div>
                    <NavLink to="/dashboard/send-message" className="text-[1em]">
                        <button type="submit" className="btn btn-primary " data-testid="submit">
                            Send SMS
                        </button>
                    </NavLink>
                </div>
            </div>
            <ExportDataTable
                columns={columns}
                data={sortedData ?? []}
                total={data?.total ?? 0}
                currentPage={data?.currentPage ?? 0}
                nextPage={data?.nextPage ?? 0}
                previousPage={data?.previousPage ?? 0}
                lastPage={data?.lastPage ?? 0}
                isLoading={loading}
                filterComponent={<Filter />}
            />
        </div>
    );
}

export default MessagaReports;
