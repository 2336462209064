import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../configs/config'

const AssignRoleThunk:any = createAsyncThunk(
  'users/role ',
  async (data:any, { rejectWithValue }) => {
   
    try {
      const res = await axios.patch(`/api/v1/users/${data.userId}/assign-role`, data,
      
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('Ishema-token')}`,
        },
      });
      return res.data;
    } catch (error:any) {
      return rejectWithValue(error?.response?.data?.message);
    
    }
  }
);
export default AssignRoleThunk;
