import { Navigate, useRoutes } from 'react-router-dom';
import Login from '../views/auth/Login';
import Register from '../views/auth/Register';
import AdminLayout from '../components/Layouts/DefaultLayout';
import Dashbord from '../views/Dashboard';
import MessagaReports from '../views/messages/report';
import Settings from '../views/settings';
import Sms from '../views/messages/send';
import Error404 from '../views/errors/4004Error';
import Home from '../views/Home/Home';
import GetStarted from '../views/Home/GetStarted';
import Groups from '../views/groups';
import Contacts from '../views/Contact';
import ContactsINGroup from '../views/Contact/more';
import WalletStatisticsReports from '../views/walletReports';
import Schedules from '../views/schedules';
import SchedulresReports from '../views/schedules/reports';
import PaymentPage from '../views/payment';
import Users from '../views/users/Users';
import SmsRequests from '../views/smsRequests';


export default function AppRoutes() {
    return useRoutes([
        { path: '/', element: <Navigate to="home" replace /> },
        { path: '*', element: <Error404 /> },
        { path: 'login', element: <Login /> },
        { path: 'register', element: <Register /> },
        { path: 'home', element: <Home /> },
        { path: 'get-started', element: <GetStarted /> },
        {
            element: <AdminLayout />,
            children: [
         
                { path: 'dashboard', element: <Dashbord /> },
                { path: '/dashboard/messages', element: <MessagaReports /> },

                { path: '/dashboard/wallet-reports', element: <WalletStatisticsReports /> },

                { path: '/dashboard/schedule-reports', element: <SchedulresReports /> },

                { path: '/dashboard/send-message/*', element: <Sms /> },
       
                {
                    path: '/dashboard/pay',
                    element: <PaymentPage />,
                },
                {
                    path: '/dashboard/schedules',
                    element: <Schedules />,
                },
                {
                    path: '/dashboard/contacts/:scheduleId/:status',
                    element: <SmsRequests />,
                },
                {
                    path: '/dashboard/groups',
                    element: <Groups />,
                },
                {
                    path: '/dashboard/contacts/:groupId',
                    element: <ContactsINGroup />,
                },
                {
                    path: '/dashboard/contacts',
                    element: <Contacts />,
                },
                {
                    path: '/dashboard/settings/*',
                    element: <Settings />,
                },
                {
                    path: '/dashboard/users/',
                    element: <Users />,
                },
            ],
        },
    ]);
}
