import { useState, Fragment } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { Dialog, Transition } from '@headlessui/react';;
import { AppDispatch, RootState } from '../../store';
import editUserThunk from '../../Redux/actions/updateUser';
import IconPencil from '../../components/Icon/IconPencil';


export const AdminEdituser = ({user}:any) => {
    const [modal1,setModal1] = useState(false)
    const [firstName, setFirstName] = useState(user?.firstName || '');
    const [lastName, setLastName] = useState(user?.lastName || '');

    useSelector((state: RootState) => state.edit);

    const id = user.id;

    const { handleSubmit } = useForm({});

    const dispatch = useDispatch<AppDispatch>();

    const submit = async () => {
        try {
            const datas = { firstName, lastName };

            const response = await dispatch(editUserThunk({ datas, id })).then((action: any) => unwrapResult(action));
            setModal1(false)
            toast.success('user Updated successfully');
        } catch (e: any) {
        return e
        }
    };

    return (
        <div className="">
            <div className="flex items-center justify-center">
         
               
                <button   onClick={() => setModal1(true)}title="Edit  user" className="btn
      
      btn-warning btn-sm">
                <IconPencil className=" h-7" />
            </button>
            </div>
            <Transition appear show={modal1} as={Fragment}>
                <Dialog as="div" open={modal1} onClose={() => setModal1(false)}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0" />
                    </Transition.Child>
                    <div className="fixed inset-0 bg-[black]/60 z-[999] overflow-y-auto">
                        <div className="flex items-start justify-center min-h-screen px-4">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                 <Dialog.Panel as="div" className="panel border-0 p-0 rounded-lg overflow-hidden my-8 w-full max-w-lg text-black dark:text-white-dark">
                                    <div className="flex bg-[#fbfbfb] dark:bg-[#121c2c] items-center justify-between px-5 py-3">
                                        <div className="text-lg flex flex-row justify-center w-full ">Edit User</div>
                                    </div>
                                    <div className="modal-content m-5 ">
                                    <form onSubmit={handleSubmit(submit)}>
                        <div className="form-group grid grid-cols-2 gap-2">
                            <input
                                className="form-input text-gray-600 dark:text-gray-400  "
                                type="text"
                                name="name"
                                onChange={(event) => setFirstName(event.target.value)}
                                defaultValue={user.firstName}
                            />
                            <input
                                className="form-input text-gray-600 dark:text-gray-400   "
                                type="text"
                                name="lastName"
                                onChange={(event) => setLastName(event.target.value)}
                                defaultValue={user.lastName}
                            />
                            <input className="form-input text-gray-600 dark:text-gray-400 " type="text" name="Email" placeholder={user.email} />
                            <input className="text-gray-600 dark:text-gray-400  form-input  " type="text" name="role" placeholder={user.role} />
                        </div>

                        <div className="mt-2 flex flex-row ">
                            <button type="submit" className=" btn btn-primary">
                                Save
                            </button>
                          
                        </div>
                    </form>
                                    
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </div>
    );
};
