import { Dialog, Transition } from '@headlessui/react';
import { useState, Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import { yupResolver } from '@hookform/resolvers/yup';
import { unwrapResult } from '@reduxjs/toolkit';

import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import ExportDataTable, { TableColumn } from '../../components/datatable';
import IconChecks from '../../components/Icon/IconChecks';
import IconClipboardText from '../../components/Icon/IconClipboardText';
import IconPlus from '../../components/Icon/IconPlus';
import ApiThunk from '../../Redux/actions/createApi';
import DataThunk from '../../Redux/actions/getApidata';
import formatDateToLongForm from '../../utils/Date';
import ApiSchema from '../../Validations/api';
import Select from '../../components/select';
import { CircularProgress } from '@mui/material';

export function ApiKeys() {
    const dispatch = useDispatch<AppDispatch>();
    const { data: Api_data, loading } = useSelector((state: RootState) => state.data);

    useEffect(() => {
        dispatch(DataThunk());
    }, [dispatch]);

    
    const handleRefetch = () => {
        dispatch(DataThunk());
    };

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(ApiSchema),
    });

    const [Keys, setKeys] = useState<any[]>([]);
    const [sortedData, setSortedData] = useState<any[]>([]);
    useEffect(() => {
        setKeys(Api_data);
        setSortedData([...Api_data]);
    }, [Api_data]);


    const [copiedKeys, setCopiedKeys] = useState<Record<string, boolean>>({});
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(12);

    const lastPostIndex = currentPage * postsPerPage;
    const firstPostIndex = lastPostIndex - postsPerPage;
    const currentPosts = sortedData.slice(firstPostIndex, lastPostIndex);

    const columns: TableColumn<any>[] = [
        {
            title: 'Name',
            accessor: 'name',
            render: (row) => <p>{row.name}</p>,
        },
        {
            title: 'Description',
            accessor: 'description',
            render: (row) => <p>{row.description}</p>,
        },
        {
            title: 'Secret',
            accessor: 'secret',
            render: (row) => (
                <p className="whitespace-no-wrap text-gray-600  flex  flex-row   dark:text-gray-400">
                    {row.secret}
                    <span
                        className={`ml-2 cursor-pointer text-primary ${copiedKeys[row.secret] ? 'text-green-500' : ''}`}
                        onClick={() => {
                            navigator.clipboard.writeText(row.secret);
                            setCopiedKeys((prevKeys: any) => ({
                                ...prevKeys,
                                [row.secret]: true,
                            }));
                            setTimeout(() => {
                                setCopiedKeys((prevKeys: any) => ({
                                    ...prevKeys,
                                    [row.secret]: false,
                                }));
                            }, 2000);
                        }}
                    >
                        {copiedKeys[row.secret] ? <IconChecks /> : <IconClipboardText />}
                    </span>
                </p>
            ),
        },
        {
            title: 'created At',
            accessor: 'created_at',
            render: (row) => <p>{formatDateToLongForm(row.created_at)}</p>,
        },
        {
            title: 'Environment',
            accessor: 'environment',
            textAlign: 'center',
            render: (row) => (
                <div className="flex gap-2 justify-start">
                    {row.environment === 'dev' ? <span className="badge bg-primary">Development</span> : <span className="badge bg-success">Production</span>}
                </div>
            ),
        },
    ];
    return (
        <>
            <div className="flex flex-row justify-end  items-center my-2 ">
               <div className='flex flex-row justify-end  items-center my-2 '>
                <CreateApiKey handleRefetch={handleRefetch}/>
               </div>
            </div>
            <ExportDataTable
                columns={columns}
                data={Api_data ?? []}
                total={Api_data?.length}
                currentPage={1}
                nextPage={0}
                previousPage={0}
                lastPage={0}
                isLoading={loading}
                filterComponent={undefined}
            />
        </>
    );
}

function CreateApiKey({ handleRefetch }: any) {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(ApiSchema),
    });
    const [modal, setModal] = useState(false);

    const dispatch = useDispatch<AppDispatch>();
    const { isLoading } = useSelector((state: RootState) => state.api);

    const submit = async (data: any) => {
        try {
            
            const response = await dispatch(ApiThunk(data)).then((action: any) => unwrapResult(action));

            if (response) {
                toast.success('Key created  successfully');
                handleRefetch();
                setModal(false);
            }
        } catch (e: any) {
            if (e) {
                toast.error('Error Occurred');
            } else {
                toast.error(e.response.data.message);
            }
        }
    };
    const [selectedEnvironment, setSelectedEnvironment] = useState('');

    const handleChangeEnvironment = (event: any) => {
        setSelectedEnvironment(event.target.value);
    };

    return (
        <div className="">
            <div className="flex items-center justify-center">
                <button type="button" onClick={() => setModal(true)} className="btn btn-primary">
                    <IconPlus className="w-4" />
                    Create Api Key
                </button>
            </div>
            <Transition appear show={modal} as={Fragment}>
                <Dialog as="div" open={modal} onClose={() => setModal(false)}>
                    <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                        <div className="fixed inset-0" />
                    </Transition.Child>
                    <div className="fixed inset-0 bg-[black]/60 z-[999] overflow-y-auto">
                        <div className="flex items-start justify-center min-h-screen px-4">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel as="div" className="panel border-0 p-0 rounded-lg overflow-hidden my-8 w-full max-w-lg text-black dark:text-white-dark">
                                    <div className="flex bg-[#fbfbfb] dark:bg-[#121c2c] items-center justify-between px-5 py-3">
                                        <div className="text-lg flex flex-row justify-center w-full font-bold ">Create Api Key</div>
                                    </div>
                                    <div className="p-5">
                                        <form className="w-full" onSubmit={handleSubmit(submit)}>
                                            <div className="flex flex-row w-full ">
                                                <div className="w-[50%] ">
                                                    <label htmlFor="" className=" dark:text-gray-400  text-gray-600">
                                                        {' '}
                                                        Name
                                                    </label>
                                                    <input className="form-input" type="text" placeholder="ex. wedding" {...register('name')} />
                                                    {errors.name && <p className="text-red-500 text-xs">Name is required</p>}
                                                </div>

                                                <div className="  w-[50%] ml-2 ">
                                                    <label htmlFor="" className="dark:text-gray-400  text-gray-600 ">
                                                        {' '}
                                                        Environment
                                                    </label>
                                                    <Select name="environment  " value={selectedEnvironment} onChange={handleChangeEnvironment} register={{ ...register('environment') }}>
                                                        <option value="">Select environment</option>
                                                        <option value="prod">Production</option>
                                                        <option value="dev">Development</option>
                                                    </Select>

                                                    {errors.environment && <p className="text-red-500 text-xs">Environment is required</p>}
                                                </div>
                                            </div>
                                            <div className="w-[100%] flex flex-col mt-4">
                                                <label htmlFor="" className="text-gray-600 dark:text-gray-400">
                                                    {' '}
                                                    Description
                                                </label>
                                                <textarea className="form-textarea" placeholder="description" {...register('description')} />
                                                {errors.description && <p className="text-red-500 text-xs">Description is required</p>}
                                            </div>

                                            <button type="submit" className="btn btn-primary mt-2 ">
                                                {isLoading ? <CircularProgress size={20} sx={{ color: 'white' }} /> : <span>Create Key</span>}
                                            </button>
                                        </form>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </div>
    );
}
