import React from 'react';
import toast from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { Link } from 'react-router-dom';
import PurchaseSchema from '../../Validations/buy';
import PurchaseThunk from '../../Redux/actions/purchase';

function PaymentPage() {
    const dispatch = useDispatch();

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        resolver: yupResolver(PurchaseSchema),
    });

    const Signup: any = async (data: any, e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        console.log(data);
        try {
            const response: any = await dispatch(PurchaseThunk(data)).then((action: any) => unwrapResult(action));
            toast.success('Check Phone to complete Payment');
        } catch (e: any) {
            toast.error(e);
        }
    };

    return (
        <>
            <div>
                <ul className="flex space-x-2 rtl:space-x-reverse">
                    <li>
                        <Link to="/dashboard" className="text-primary hover:underline">
                            Dashboard
                        </Link>
                    </li>

                    <li className="before:content-['/'] ltr:before:mr-2 rtl:before:ml-2">
                        <span>Purchase sms</span>
                    </li>
                </ul>
            </div>
            <div className="flex flex-col items-center ">
                <div className="panel border-0 p-0 rounded overflow-hidden my-8  w-full md:w-[70%]  text-black dark:text-white-dark">
                    <div className="flex bg-[#fbfbfb] dark:bg-[#121c2c] items-center justify-between px-5 py-3">
                        <div className="text-lg flex flex-row justify-center w-full ">Purchase sms</div>
                    </div>
                    <div className="modal-content m-5 ">
                        <form className="space-y-5 dark:text-white" onSubmit={handleSubmit(Signup)}>
                            <div>
                                <label htmlFor="Email " className="">
                                    Phone Number
                                </label>
                                <div className="relative text-white-dark">
                                    <input id="text" type="text" placeholder="Enter number to pay with " className="form-input  placeholder:text-white-dark" {...register('phoneNumber')} />
                                </div>
                                {errors.phoneNumber && <p className="text-red-500 text-xs">Phone is required</p>}
                            </div>
                            <div>
                                <label htmlFor="Email " className="font-medium">
                                    sms
                                </label>
                                <div className="relative text-white-dark">
                                    <input id="sms" type="number " placeholder="Enter number of sms " className="form-input  placeholder:text-white-dark" {...register('sms')} />
                                </div>
                                {errors.sms && <p className="text-red-500 text-xs">sms number is required</p>}
                            </div>

                            <button type="submit" className="btn btn-lg capitalize w-full btn-primary">
                                <span>Pay</span>
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PaymentPage;
