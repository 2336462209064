import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../configs/config'

const ApproveThunk:any = createAsyncThunk(
  'senderId/Approve ',
  async (data:any, { rejectWithValue }) => {
   
    try {
      const res = await axios.post(`/api/v1/sender-ids/approve/${data.id}`, data,
      
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('Ishema-token')}`,
        },
      });
      return res.data;
    } catch (error:any) {
      return rejectWithValue(error?.response?.data?.message);
    
    }
  }
);
export default ApproveThunk;
