import React, { useEffect, useRef, useState } from 'react';
import ExportDataTable, { TableColumn } from '../../components/datatable';
import formatDateToLongForm from '../../utils/Date';
import { useGetGroupList } from '../../api/groups';
import { Link } from 'react-router-dom';
import AdminCreateUser from '../users/AdminCreateUser';
import CreateGroup from './create';
import CreateContact from '../Contact/create';
import UploadContact from '../Contact/create/upload';
import { capitalize } from '@mui/material';

function Groups() {
    const { groups, fetchGroups, loading }: any = useGetGroupList();

    useEffect(() => {
        fetchGroups();
    }, []);

    const handeRefetch = () => {
        fetchGroups();
    };

    const columns: TableColumn<any>[] = [
        {
            title: 'Group Name',
            accessor: 'name',
            render: (row) => (
                <Link
                    to={`/dashboard/contacts/${row.id}`}
                    className="
            hover:underline cursor-pointer"
                >
                    <p>{ capitalize(row.name)}</p>
                </Link>
            ),
        },
        {
            title: 'Owner Name',
            accessor: 'user.firstName',
            render: (row) => (
                <p>
                    {row?.user?.firstName} {row?.user?.lastName}
                </p>
            ),
        },
        {
            title: 'Owner Email',
            accessor: 'user.email',
            render: (row) => (
                
                    <p>{row.name}</p>
            
            ),
        },
        {
            title: 'Total Contacts',
            accessor: 'contactNumber',
            render: (row) => (
                
                    <p>{row.contactNumber}</p>
            
            ),
        },
        {
            title: 'Created At',
            accessor: 'created_at',
            render: (row) => <p>{formatDateToLongForm(row.created_at)}</p>,
        },
        {
            title: 'Actions',
            accessor: 'created_at',
            render: (row) => (
                <p className="flex flex-row gap-2 ">
                    <CreateContact data={row} handeRefetch={handeRefetch} />

                    <UploadContact handeRefetch={handeRefetch} data={row} />
                </p>
            ),
        },
    ];
    return (
        <div className=" flex  flex-col        ">
            <div>
                <div className="w-full flex flex-row justify-between items-center">
                    <div>
                        <ul className="flex space-x-2 rtl:space-x-reverse">
                            <li>
                                <Link to="/dashboard" className="text-primary hover:underline">
                                    Dashboard
                                </Link>
                            </li>

                            <li className="before:content-['/'] ltr:before:mr-2 rtl:before:ml-2">
                                <span>Groups</span>
                            </li>
                        </ul>
                    </div>

                    <div className="flex flex-row justify-end my-2">
                        <CreateGroup handeRefetch={handeRefetch} />
                    </div>
                </div>
            </div>
            <ExportDataTable
                columns={columns}
                data={groups ?? []}
                total={groups?.lenght ?? 0}
                currentPage={1}
                nextPage={0}
                previousPage={0}
                lastPage={1}
                isLoading={loading}
                filterComponent={undefined}
            />
        </div>
    );
}

export default Groups;
