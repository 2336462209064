import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';
import { AppDispatch, RootState } from '../../../../store';
import DataThunk from '../../../../Redux/actions/getApidata';
import senderIDThunk from '../../../../Redux/actions/GetSenderIds';
import { useGetGroupList, useSendMessage } from '../../../../api/groups';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import sendfilesms from '../../../../Validations/sendfile';
import SendGroupSchema from '../../../../Validations/sendGroup';

function SendGroup() {
    const dispatch = useDispatch<AppDispatch>();
    const { groups, fetchGroups } = useGetGroupList();
    const [Key, setKey] = useState('');
    const { data: Api_data, loading }: any = useSelector((state: RootState) => state.data);
    const { data: senderIds, loading: senderIdsLoading } = useSelector((state: RootState) => state.senderId);
    const [message, setMessage] = useState('');
    const [scheduleOption, setScheduleOption] = useState('now');
    const [scheduleTime, setScheduleTime] = useState('');
    const [smsCount, setSmsCount] = useState(1);


    useEffect(() => {
        setKey(Api_data[0]?.secret);
    }, [Api_data]);

    useEffect(() => {
        fetchGroups();
        dispatch(DataThunk());
        dispatch(senderIDThunk());
    }, [dispatch, fetchGroups]);


    const { sendMessage, loading:loadingSendSMS } = useSendMessage();

    const [errorMessage, setErrorMessage] = useState('');

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        resolver: yupResolver(SendGroupSchema),
    });

    const submit = async (data: any) => {
        try {
            const data2 = {
                senderId: data?.senderId,
                message: message,
                groupId: data?.groupId,
                isScheduled: scheduleOption === 'later',
                schedule_time: scheduleOption === 'later' ? new Date(new Date(scheduleTime).getTime() + 2 * 60 * 60 * 1000).toISOString() : undefined,
            };
            await sendMessage(data2, Key);
            reset()
            setMessage('')
        } catch (error: any) {
            console.error('Error sending message:', error);
            setErrorMessage(error.message);
        }
    };

    useEffect(() => {
        const messageLength = message.length;
        const count = Math.ceil(messageLength / 160);
        setSmsCount(count > 0 ? count : 1);
    }, [message]);
    const handleMessageChange = (event: any) => {
        const newMessage = event.target.value.replace(/[\r\n]/g, '');
        setMessage(newMessage);

        if (newMessage.length > 0) {
        }
    };

    return (
        <>
            <div className="m-2">
                <div className="w-full p-2">
                    <p className="-4 sm:text-lg font-bold md:text-1xl lg:text-xl">Send to a group</p>
                    <form className="flex flex-col mt-2" onSubmit={handleSubmit(submit)}>
                        <div className="flex flex-col mt-4 w-full">
                            <div className="my-2 w-full flex flex-col">
                                <label htmlFor="groupId" className="font-bold">
                                    Select Group
                                </label>
                                <select id="groupId" className="form-select" {...register('groupId')}>
                                    <option value="">Select group</option>
                                    {groups.length > 0 ? (
                                        groups.map((group: any) => (
                                            <option key={group.id} value={group.id}>
                                                {group.name}
                                            </option>
                                        ))
                                    ) : (
                                        <option value="">No Group found</option>
                                    )}
                                </select>
                                {errors.groupId && <div className="text-red-500 text-xs italic"> Group is required</div>}
                            </div>
                            <div className="my-2 w-full flex flex-col">
                                <label htmlFor="senderId" className="font-bold">
                                    Select Sender ID
                                </label>
                                {senderIds.filter((item: any) => item.approved).length === 0 ? (
                                    <p className="form-input">
                                        No SenderId yet{' '}
                                        <Link to="/dashboard/settings" className="text-primary underline">
                                            Create New
                                        </Link>
                                    </p>
                                ) : (
                                    <select id="apiKeySelect" className="form-select" {...register('senderId')}>
                                        <option value="">Select sender Id</option>
                                        {senderIds
                                            .filter((item: any) => item.approved)
                                            .map((item: any) => (
                                                <option key={item.id} value={item.secret}>
                                                    {item.name}
                                                </option>
                                            ))}
                                    </select>
                                )}
                                {errors.senderId && <div className="text-red-500 text-xs italic"> SenderId is required</div>}
                            </div>
                        </div>
                        <div className="mt-3">
                            <div className="flex flex-row justify-between">
                                <label htmlFor="message" className="font-bold">
                                    Message
                                </label>
                                <div className="">
                                    <p className="text-gray-600 dark:text-gray-400">{message.length}/160</p>
                                </div>
                            </div>

                            <textarea className="form-textarea h-24" {...register('message')} placeholder="Message" onChange={handleMessageChange} value={message} name="message" />
                            {errors.message && <p className="text-red-500 text-xs italic">{typeof errors?.message?.message === 'string' && errors?.message?.message}</p>}
                        </div>
                        <div className="my-2 w-full flex flex-col">
                            <label htmlFor="scheduleOption" className="font-bold">
                                Schedule Option
                            </label>
                            <select id="scheduleOption" className="form-select" value={scheduleOption} onChange={(event) => setScheduleOption(event.target.value)}>
                                <option value="now">Send Now</option>
                                <option value="later">Schedule for Later</option>
                            </select>
                        </div>
                        {scheduleOption === 'later' && (
                            <div className="my-2 w-full flex flex-col">
                                <div>
                                    <label htmlFor="scheduleTime" className="font-bold">
                                        Schedule Time
                                    </label>
                                </div>
                                <input type="datetime-local" id="scheduleTime" className="form-input" value={scheduleTime} onChange={(event) => setScheduleTime(event.target.value)} />
                            </div>
                        )}

                        {errorMessage && <p className="text-red-500 font-bold">{errorMessage}</p>}

                        <button type="submit" className="w-1/3 btn my-2 btn-primary" data-testid="submit">
                        {loadingSendSMS ? <CircularProgress size={24} sx={{ color: 'white' }} /> : <span>Send ({smsCount}) sms</span>}
                    </button>
                    </form>
                </div>
            </div>
        </>
    );
}

export default SendGroup;
