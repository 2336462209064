
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { unwrapResult } from "@reduxjs/toolkit";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import "react-toastify/dist/ReactToastify.css";
import RegisterThunk from "../../Redux/actions/register";
import signupSchema from "../../Validations/register";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import IconMail from "../../components/Icon/IconMail";
import IconUser from "../../components/Icon/IconUser";
import IconLockDots from "../../components/Icon/IconLockDots";
import { CircularProgress } from "@mui/material";
import IconPlus from "../../components/Icon/IconPlus";

export default function AdminCreateUser({ user }: any) {
  const dialog = useRef<HTMLDialogElement>(null);
  const closeModel = () => {
    if (dialog.current?.close) dialog.current.close();
  };

  const openModel = () => {
    if (dialog.current?.showModal) dialog.current.showModal();
  };

  const close = (e: React.MouseEvent<HTMLElement>) => {
    const dialogDimensions = dialog.current?.getBoundingClientRect();
    if (
      e.clientX < dialogDimensions!.left ||
      e.clientX > dialogDimensions!.right ||
      e.clientY < dialogDimensions!.top ||
      e.clientY > dialogDimensions!.bottom
    ) {
      closeModel();
    }
  };

  const [firstName, setFirstName] = useState (user?.firstName || '');
  const [lastName, setLastName] = useState(user?.lastName || '');
 const {isLoading}= useSelector((state: RootState) => state.register);
   const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
} = useForm({
    resolver: yupResolver(signupSchema),
});
const dispatch = useDispatch<AppDispatch>();

const submit = async (data: any) => {
    try {
        const email = data.email;
        const firstName = data.firstName;
        const lastName = data.lastName;
        const password = data.password;
        const datas = { email, lastName, firstName, password };

        const response = await dispatch(RegisterThunk(datas)).then((action: any) => unwrapResult(action));
        reset();
        toast.success(' User Registered   ');
    
    } catch (e: any) {
        toast.error(e);
    }
};


  return (
    <>
      <dialog
        ref={dialog}
        className="modal rounded-lg shadow-lg   w-full md:w-[40%] dark:bg-[#121c2c] "
        onClick={(e) => close(e)}
      >
        <div className="p-3 rounded">
          <div className="flex flex-row justify-center items-center">
            <h3 className="p-2 text-gray-600 dark:text-gray-400 text-lg">Create User </h3>
          </div>
      
<form className="space-y-5 dark:text-white" onSubmit={handleSubmit(submit)}>
<div>
    <label htmlFor="Email " className="font-medium">
        Email
    </label>
    <div className="relative text-white-dark">
        <input id="email" type="email" placeholder="Enter Email" className="form-input ps-10 placeholder:text-white-dark" {...register('email')} />
        <span className="absolute start-4 top-1/2 -translate-y-1/2">
            <IconMail fill={true} />
        </span>
    </div>
    {errors.email && <p className="text-red-500 text-xs">Email is required</p>}
</div>
<div>
    <label htmlFor="Email " className="font-medium">
        First Name
    </label>
    <div className="relative text-white-dark">
        <input id="text" type="name" placeholder="Enter first name" className="form-input ps-10 placeholder:text-white-dark" {...register('firstName')} />
        <span className="absolute start-4 top-1/2 -translate-y-1/2">
            <IconUser fill={true} />
        </span>
    </div>
    {errors.firstName && <p className="text-red-500 text-xs">First name is required</p>}
</div>
<div>
    <label htmlFor="Email " className="font-medium">
        Last Name
    </label>
    <div className="relative text-white-dark">
        <input id="text" type="text" placeholder="Enter last name" className="form-input ps-10 placeholder:text-white-dark" {...register('lastName')} />
        <span className="absolute start-4 top-1/2 -translate-y-1/2">
            <IconUser fill={true} />
        </span>
    </div>
    {errors.lastName && <p className="text-red-500 text-xs">Last Name is required</p>}
</div>
<div>
    <label htmlFor="Password" className="font-medium">
        Password
    </label>
    <div className="relative text-white-dark">
        <input id="Password" type="password" placeholder="Enter Password" className="form-input ps-10 placeholder:text-white-dark" {...register('password')} />
        <span className="absolute start-4 top-1/2 -translate-y-1/2">
            <IconLockDots fill={true} />
        </span>
    </div>
    {errors.password && <p className="text-red-500 text-xs">Password is required</p>}
</div>
<div>
    <label htmlFor="Password" className="font-medium">
        confirm password
    </label>
    <div className="relative text-white-dark">
        <input id="Password" type="password" placeholder="Enter Password" className="form-input ps-10 placeholder:text-white-dark" {...register('confirmPassword')} />
        <span className="absolute start-4 top-1/2 -translate-y-1/2">
            <IconLockDots fill={true} />
        </span>
    </div>
    {errors.confirmPassword && <p className="text-red-500 text-xs"> Password does not match</p>}
</div>
<button type="submit" className="btn  btn-lg  capitalize w-full btn-primary ">
    {isLoading ? <CircularProgress size={20} sx={{ color: 'white' }} /> : <span> Register</span>}
</button>
</form>
        </div>
      </dialog>
      <button onClick={() => openModel()} title="Add User" className="btn  btn-primary">
                <IconPlus className=" w-4 " />  Add user
            </button>
    </>
  );
}
