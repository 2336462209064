import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../configs/config'

const PurchaseThunk:any = createAsyncThunk(
  'wallet/purchase ',
  async (data:any, { rejectWithValue }) => {
   
    try {
      const res = await axios.post('/api/v1/wallet/purchase-sms', data,
      
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('Ishema-token')}`,
        },
      });
      return res.data;
    } catch (error:any) {
      return rejectWithValue(error?.response?.data?.message);
    
    }
  }
);
export default PurchaseThunk;
