import React, { useState, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { Dialog, Transition } from '@headlessui/react';
import ApproveThunk from '../../../Redux/actions/Approve';
import ApproveSchema from '../../../Validations/Approve';

export const Approve = ({ user, handleRefetch }: any) => {
    const [modal1, setModal1] = useState(false);
    const dispatch = useDispatch();
  
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        resolver: yupResolver(ApproveSchema),
    });

    const Signup: any = async (data: any, e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        console.log(data);
        try {
            const id = user.id;
            const datas = { ...data, id };
            await dispatch(ApproveThunk(datas)).then((action: any) => unwrapResult(action));
            toast.success('Sender Id Approved');
            handleRefetch();
            setModal1(false);
        } catch (e: any) {
            toast.error(e);
        }
    };
    return (
        <div className="">
            <div className="flex items-center justify-center">
                <button type="button" onClick={() => setModal1(true)} className="btn btn-primary btn-sm m-1">
                    Approve
                </button>
            </div>
            <Transition appear show={modal1} as={Fragment}>
                <Dialog as="div" open={modal1} onClose={() => setModal1(false)}>
                    <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                        <div className="fixed inset-0" />
                    </Transition.Child>
                    <div className="fixed inset-0 bg-[black]/60 z-[999] overflow-y-auto">
                        <div className="flex items-start justify-center min-h-screen px-4">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel as="div" className="panel border-0 p-0 rounded-lg overflow-hidden my-8 w-full max-w-lg text-black dark:text-white-dark">
                                    <div className="flex bg-[#fbfbfb] dark:bg-[#121c2c] items-center justify-between px-5 py-3">
                                        <div className="text-lg flex flex-row justify-center w-full "> Approve senderId</div>
                                    </div>
                                    <div className="modal-content m-5 ">
                                        <form action="" onSubmit={handleSubmit(Signup)} className=" ">
                                            <p className="text-lg ">
                                                {' '}
                                                Are you sure you want to approve <span className="font-bold ">{user?.name} </span>
                                            </p>
                                            <button type="submit" className="btn btn-sm btn-primary w-full ">
                                                Approve
                                            </button>
                                        </form>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </div>
    );
};
