export default function formatDateToLongForm(dateString: string): string {
  const date = new Date(dateString); // Convert string to Date object
  date.setHours(date.getHours() - 2); // Subtract 2 hours
  const options:any = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false
  };
  const formattedDate = date.toLocaleString(undefined, options);
  return formattedDate;
}
