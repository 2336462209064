import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { setPageTitle } from '../../store/themeConfigSlice';
import { AppDispatch, IRootState, RootState } from '../../store';
import IconMail from '../../components/Icon/IconMail';
import jwt_decode from 'jwt-decode';
import IconLockDots from '../../components/Icon/IconLockDots';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import LoginThunk from '../../Redux/actions/login';
import loginSchema from '../../Validations/login';
import { unwrapResult } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
import { CircularProgress } from '@mui/material';
import Img1 from '@/assets/001@1-3000x3000 1 (1).png';
import Img2 from '@/assets/Frame.svg';
import Img3 from '@/assets/bulksms 1.svg';

const Login = () => {
    useEffect(() => {
        dispatch(setPageTitle('Login'));
    });
    const navigate = useNavigate();
    const themeConfig = useSelector((state: IRootState) => state.themeConfig);

    const { error, errorMessage, isLoading, access_token } = useSelector((state: RootState) => state.login);

    const Key = localStorage.getItem('Ishema-token');
    useEffect(() => {
        const storedToken = localStorage.getItem('Ishema-token');

        if (!isLoading && !storedToken) {
            navigate('/login');
        } else if (storedToken) {
            try {
                const decodedToken: { exp: number } = jwt_decode(storedToken);

                const currentTime = Date.now() / 1000;
                console.log(decodedToken.exp > currentTime);
                if (decodedToken.exp < currentTime) {
                    navigate('/login');
                } else if (access_token && Key) {
                    setTimeout(() => navigate('/dashboard'), 1000);
                }
            } catch (error) {
                // Handle decoding error

                navigate('/login');
            }
        }
    }, [isLoading, access_token, Key, navigate]);

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        resolver: yupResolver(loginSchema),
    });
    const dispatch = useDispatch<AppDispatch>();

    const submit = async (data: any) => {
        try {
            const response = await dispatch(LoginThunk(data)).then((action: any) => unwrapResult(action));
            reset();

            toast.success('login successfull');
        } catch (e: any) {
            toast.error(e);
        }
    };
    const isDark = useSelector((state: IRootState) => state.themeConfig.theme === 'dark' || state.themeConfig.isDarkMode);

    return (
       
            <div className="relative flex min-h-screen flex-row   py-6 justify-center font-outfit  dark:bg-[#060818] ">
                <div className="relative flex w-full max-w-[1502px] h-screen flex-col justify-between overflow-hidden rounded-md  bg-white/60 backdrop-blur-lg dark:bg-black/50 lg:min-h-[758px] lg:flex-row lg:gap-10 xl:gap-0">
                    <div className="hidden lg:block bg-[#008080] overflow-hidden shrink-0  text-white   items-start justify-end pt-[536px] px-[85px] pb-[129px] box-border relative gap-[16px]  max-w-full  ">
                        <div className="w-full h-full absolute !m-[0] top-[0px] right-[-2px] bottom-[0px]">
                            <img className="absolute top-[-1px] left-[0px] h-[858px] object-cover mix-blend-normal" alt="" src={Img1} />
                            <img className="absolute top-[127px] left-[208px] rounded-[40px] w-[294px] h-[386px] overflow-hidden object-contain z-[1]" loading="lazy" alt="" src={Img2} />
                        </div>
                        <div className="w-[540px] flex flex-row items-start justify-start py-0 pr-16 pl-[64.5px] box-border max-w-full mq750:pl-8 mq750:pr-8 mq750:box-border">
                            <h1 className="text-7xl  ">
                                Quick, Easy, and Effortless!
                            </h1>
                        </div>
                        <div className="w-[540px] relative text-lg capitalize mt-4 font-nunito     ">
                            Our bulk SMS solutions simplify communication strategies, offering user-friendly platforms for easy crafting and messaging, catering to startups and enterprises of all
                            sizes.
                        </div>
                    </div>
                    <div className="relative flex w-full flex-col items-center font-outfit  justify-center gap-6 px-4 pb-16 pt-6 sm:px-6 lg:max-w-[667px]">
                        <div className="flex w-full max-w-[440px] items-center gap-2 lg:absolute lg:end-6 lg:top-6 lg:max-w-full"></div>
                        <div className='flex flex-row justify-center px-16   w-full '>
                                <img src={Img3}  alt=""  />
                            </div>
                        <div className="w-full max-w-[440px] lg:mt-16">
                           
                            <div className="mb-10">
                                <h1 className="text-3xl font-bold uppercase !leading-snug text-primary md:text-4xl">  Login </h1>
                                
                                <p className="text-base  leading-normal text-white-dark">Enter your email and password to login</p>
                            </div>
                            <form className="space-y-5 dark:text-white" onSubmit={handleSubmit(submit)}>
                                <div>
                                    <label htmlFor="Email " className="font-medium">
                                        Email
                                    </label>
                                    <div className="relative text-white-dark">
                                        <input id="email" type="email" placeholder="Enter Email" className="form-input font-normal  ps-10 placeholder:text-white-dark" {...register('email')} />
                                        <span className="absolute start-4 top-1/2 -translate-y-1/2">
                                            <IconMail fill={true} />
                                        </span>
                                    </div>
                                    {errors.email && <p className="text-red-500 text-xs">Email is required</p>}
                                </div>
                                <div>
                                    <label htmlFor="Password" className="font-medium">
                                        Password
                                    </label>
                                    <div className="relative text-white-dark">
                                        <input id="Password" type="password" placeholder="Enter Password" className="form-input font-normal  ps-10 placeholder:text-white-dark" {...register('password')} />
                                        <span className="absolute start-4 top-1/2 -translate-y-1/2">
                                            <IconLockDots fill={true} />
                                        </span>
                                    </div>
                                    {errors.password && <p className="text-red-500 text-xs">Password is required</p>}
                                </div>

                                <button type="submit" className="btn  btn-lg  capitalize w-full btn-primary ">
                                    {isLoading ? <CircularProgress size={20} sx={{ color: 'white' }} /> : <span>Sign in</span>}
                                </button>
                            </form>

                            <div className="text-center dark:text-white mt-2 capitalize ">
                                Don't have an account ?&nbsp;
                                <Link to="/register" className="uppercase text-primary underline transition hover:text-black dark:hover:text-white">
                                    SIGN UP
                                </Link>
                            </div>
                        </div>
                        <p className="absolute bottom-6 w-full text-center   dark:text-white">© {new Date().getFullYear()}Bulk SMS All Rights Reserved.</p>
                    </div>
                </div>
            </div>
      
    );
};

export default Login;
