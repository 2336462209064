import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { setPageTitle } from '../../store/themeConfigSlice';
import { AppDispatch, IRootState, RootState } from '../../store';
import IconMail from '../../components/Icon/IconMail';
import IconLockDots from '../../components/Icon/IconLockDots';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { unwrapResult } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
import { CircularProgress } from '@mui/material';
import RegisterThunk from '../../Redux/actions/register';
import IconUser from '../../components/Icon/IconUser';
import signupSchema from '../../Validations/register';
import IconArrowBackward from '../../components/Icon/IconArrowBackward';
import Img1 from '@/assets/001@1-3000x3000 1 (1).png';
import Img2 from '@/assets/Frame.svg';
import Img3 from '@/assets/bulksms 1.svg';

const Register = () => {
    useEffect(() => {
        dispatch(setPageTitle('Login'));
    });
    const navigate = useNavigate();
    const themeConfig = useSelector((state: IRootState) => state.themeConfig);

   

    const { error, errorMessage, isLoading, access_token }:any = useSelector((state: RootState) => state.register);

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        resolver: yupResolver(signupSchema),
    });
    const dispatch = useDispatch<AppDispatch>();

    const submit = async (data: any) => {
        try {
            const email = data.email;
            const firstName = data.firstName;
            const lastName = data.lastName;
            const password = data.password;
            const datas = { email, lastName, firstName, password };

            const response = await dispatch(RegisterThunk(datas)).then((action: any) => unwrapResult(action));
            reset();
            localStorage.setItem('Ishema-token',access_token)
            toast.success(' Register successfull   ');
            setTimeout(() => navigate('/dashboard'), 1000);
        } catch (e: any) {
            toast.error(e);
        }
    };

    return (
        <div>
            <div className="relative flex flex-row min-h-screen justify-center py-6 dark:bg[#060818] ">
                <div className="relative flex w-full max-w-[1502px] flex-col justify-between overflow-hidden rounded-md bg-white/60 backdrop-blur-lg dark:bg-black/50 lg:min-h-[758px] lg:flex-row lg:gap-10 xl:gap-0">
                <div className="hidden lg:block bg-[#008080] overflow-hidden shrink-0  text-white   items-start justify-end pt-[536px] px-[85px] pb-[129px] box-border relative gap-[16px]  max-w-full  ">
                        <div className="w-full h-full absolute !m-[0] top-[0px] right-[-2px] bottom-[0px]">
                            <img className="absolute top-[-1px] left-[0px] h-[858px] object-cover mix-blend-normal" alt="" src={Img1} />
                            <img className="absolute top-[127px] left-[208px] rounded-[40px] w-[294px] h-[386px] overflow-hidden object-contain z-[1]" loading="lazy" alt="" src={Img2} />
                        </div>
                        <div className="w-[540px] flex flex-row items-start justify-start py-0 pr-16 pl-[64.5px] box-border max-w-full mq750:pl-8 mq750:pr-8 mq750:box-border">
                            <h1 className="text-7xl font-extrabold ">
                                Quick, Easy, and Effortless!
                            </h1>
                        </div>
                        <div className="w-[540px] relative text-lg capitalize mt-4 font-nunito     ">
                            Our bulk SMS solutions simplify communication strategies, offering user-friendly platforms for easy crafting and messaging, catering to startups and enterprises of all
                            sizes.
                        </div>
                    </div>
                    <div className="relative flex w-full flex-col items-center justify-center gap-6 px-4 pb-16 pt-6 sm:px-6 lg:max-w-[667px]">
                    <div className='flex flex-row w-full  justify-start items-start  '>
                        
                    <div className='flex flex-row justify-between px-16   w-full '>
                        <Link to='/Home'>
                        <img src={Img3}  alt=""  />
                        </Link>
                             
                            </div>
                 
                  </div>
                        <div className=" font-outfit  w-full max-w-[540px] lg:mt-16">
                            <div className="mb-10">
                                <h1 className="text-3xl font-bold uppercase !leading-snug text-primary md:text-4xl">Sign up</h1>
                                <p className="text-base  leading-normal text-white-dark">Fill the information below to create an account</p>
                            </div>
                            <form className="space-y-5  dark:text-white" onSubmit={handleSubmit(submit)}>
                                
                                <div className='grid grid-cols-2  gap-3 '>
                                <div>
                                    <label htmlFor="Email " className="font-medium">
                                        Email
                                    </label>
                                    <div className="relative text-white-dark">
                                        <input id="email" type="email" placeholder="Enter Email" className="form-input  font-normal ps-10 placeholder:text-white-dark" {...register('email')} />
                                        <span className="absolute start-4 top-1/2 -translate-y-1/2">
                                            <IconMail fill={true} />
                                        </span>
                                    </div>
                                    {errors.email && <p className="text-red-500 text-xs">Email is required</p>}
                                </div>
                                <div>
                                    <label htmlFor="Email " className="font-medium">
                                        First Name
                                    </label>
                                    <div className="relative text-white-dark">
                                        <input id="text" type="name" placeholder="Enter first name" className="form-input font-normal ps-10 placeholder:text-white-dark" {...register('firstName')} />
                                        <span className="absolute start-4 top-1/2 -translate-y-1/2">
                                            <IconUser fill={true} />
                                        </span>
                                    </div>
                                    {errors.firstName && <p className="text-red-500 text-xs">First name is required</p>}
                                </div>
                                <div>
                                    <label htmlFor="Email " className="font-medium">
                                        Last Name
                                    </label>
                                    <div className="relative text-white-dark">
                                        <input id="text" type="text" placeholder="Enter last name" className="form-input font-normal ps-10 placeholder:text-white-dark" {...register('lastName')} />
                                        <span className="absolute start-4 top-1/2 -translate-y-1/2">
                                            <IconUser fill={true} />
                                        </span>
                                    </div>
                                    {errors.lastName && <p className="text-red-500 text-xs">Last Name is required</p>}
                                </div>
                                <div>
                                    <label htmlFor="Password" className="font-medium">
                                        Password
                                    </label>
                                    <div className="relative text-white-dark">
                                        <input id="Password" type="password" placeholder="Enter Password" className="form-input font-normal ps-10 placeholder:text-white-dark" {...register('password')} />
                                        <span className="absolute start-4 top-1/2 -translate-y-1/2">
                                            <IconLockDots fill={true} />
                                        </span>
                                    </div>
                                    {errors.password && <p className="text-red-500 text-xs">Password is required</p>}
                                </div>
                                <div>
                                    <label htmlFor="Password" className="font-medium">
                                        confirm password
                                    </label>
                                    <div className="relative text-white-dark">
                                        <input id="Password" type="password" placeholder="Enter Password" className="form-input font-normal ps-10 placeholder:text-white-dark" {...register('confirmPassword')} />
                                        <span className="absolute start-4 top-1/2 -translate-y-1/2">
                                            <IconLockDots fill={true} />
                                        </span>
                                    </div>
                                    {errors.confirmPassword && <p className="text-red-500 text-xs"> Password does not match</p>}
                                </div>
                                </div>
                              

                                <button type="submit" className="btn  btn-lg font-normal  capitalize w-full btn-primary ">
                                    {isLoading ? <CircularProgress size={20} sx={{ color: 'white' }} /> : <span>sign Up</span>}
                                </button>
                            </form>

                            <div className="text-center dark:text-white mt-2 capitalize ">
                                Already have an account ?&nbsp;
                                <Link to="/login" className="uppercase text-primary underline transition hover:text-black dark:hover:text-white">
                                    LOGIN
                                </Link>
                            </div>
                        </div>
                        <p className="absolute bottom-6 w-full text-center text-md dark:text-white">© {new Date().getFullYear()}   Bulk SMS  All Rights Reserved.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Register;
