import React, { useEffect, useRef, useState } from 'react';
import ExportDataTable, { TableColumn } from '../../components/datatable';
import formatDateToLongForm from '../../utils/Date';
import { useGetContacts } from '../../api/groups';
import { Link, useLocation } from 'react-router-dom';
import AdminCreateUser from '../users/AdminCreateUser';

function Contacts() {
   
  const {loading,contacts, getContacts}:any= useGetContacts()

  const location = useLocation();
  const query = location.search;
   
  useEffect(() => {
  getContacts(query)
  }, [query
    
  ])
  

    const columns: TableColumn<any>[] = [
        {
            title: 'Group Name',
            accessor: 'name',
            render: (row) => <p>{row.group?.name}</p>,
        },
        {
            title: 'Phone Number',
            accessor: 'phoneNumber',
            render: (row) => <p>{row?.phoneNumber}</p>,
        },   
        {
            title: 'Created At',
            accessor: 'created_at',
            render: (row) => <p>{formatDateToLongForm(row.created_at)}</p>,
        },
       
    ];
    return (
        <div className=" flex  flex-col        ">
            <div>
                <div className="w-full flex flex-row justify-between items-center">
                    <div>
                        <ul className="flex space-x-2 rtl:space-x-reverse">
                            <li>
                                <Link to="/dashboard" className="text-primary hover:underline">
                                    Dashboard
                                </Link>
                            </li>

                            <li className="before:content-['/'] ltr:before:mr-2 rtl:before:ml-2">
                                <span>Contacts</span>
                            </li>
                        </ul>
                    </div>

                  
                </div>
            </div>
            <ExportDataTable
                columns={columns}
                data={contacts?.list ?? []}
                total={contacts?.total ?? 0}
                currentPage={contacts?.currentPage?? 1}
                nextPage={contacts?.nextPage ?? 0}
                previousPage={contacts?.previousPage ?? 0}
                lastPage={contacts?.lastPage ?? 0}
                isLoading={loading}
                filterComponent={undefined}
            />
        </div>
    );
}

export default Contacts;
