import IconArrowLeft from '../../components/Icon/IconArrowLeft';
import { CSSProperties, FunctionComponent, useMemo } from 'react';
import img1 from '@/assets/bulksms 1.svg';
import img2 from '@/assets/Group 20.svg';
import appBadge from '@/assets/app-badge 1.png';
import Phone from '@/assets/Group 1 (1).png';
import frame11 from '@/assets/Frame 11.png';
import frame12 from '@/assets/Frame 12.png';
import box from '@/assets/box 1.png';
import frame35 from '@/assets/Frame 35.png';
import frame36 from '@/assets/Frame 35 (1).png';
import frame37 from '@/assets/Frame 35 (2).png';
import rect from '@/assets/Rectangle 5.png';
import message from '@/assets/message-square 1.png';
import inst from '@/assets/instagram.png';
import fa from '@/assets/facebook.png';
import link from '@/assets/linkedin.png';
import bgFitter from '@/assets/001@1-3000x3000 1 (1).png';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll';
import Header from './Header';
import Header2 from './Header2';

const Home: FunctionComponent = () => {
    return (
        <>
        <div className=' '>
        <Header2 />
        </div>
       
        <div className="w-full relative bg-white overflow-hidden flex flex-col items-end justify-start pt-0 px-0 pb-[19px] box-border tracking-[normal]">
            <HelloSection />
            <section className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-[75px] box-border max-w-full text-left text-lg text-teal-100 font-gilroy-semibold mq450:pb-8 mq450:box-border mq1050:pb-[49px] mq1050:box-border">
                <div className="flex-1 bg-teal-100 overflow-hidden flex flex-col items-center justify-start pt-[77px] px-5 pb-14 box-border relative gap-[142px] max-w-full mq750:pt-[50px] mq750:pb-9 mq750:box-border mq450:gap-[35px_142px] mq1050:gap-[71px_142px]">
                    <BranchDistribution />
                    <FrameComponent />
                    <div className="w-full h-[1252px] absolute !m-[0] top-[-468px] right-[0px] left-[0px]">
                        <img className="absolute top-[404px] left-[0px] w-full h-[848px] object-cover mix-blend-normal" alt="" src={bgFitter} />
                        <div className="absolute top-[0px] left-[-470px] rounded-[50%] [background:radial-gradient(50%_50%_at_50%_50%,_#21b4b4,_rgba(0,_128,_128,_0))] w-[935px] h-[935px] z-[1]" />
                    </div>
                    <div className="flex flex-row items-start justify-end py-0 px-[61px] box-border max-w-full mq750:pl-[30px] mq750:pr-[30px] mq750:box-border">
                        <div className="flex flex-row items-start justify-start gap-[33px] max-w-full mq750:flex-wrap mq750:gap-[33px_16px]">
                            <div className="w-[291px] rounded-lg bg-white box-border overflow-hidden shrink-0 flex flex-col items-start justify-start py-6 px-[21px] gap-[16px] min-w-[291px] z-[3] border-[3px] border-solid border-teal-300 mq750:flex-1">
                                <div className="h-12 rounded-xl bg-coral-200 overflow-hidden shrink-0 flex flex-row items-start justify-start p-3 box-border">
                                    <img className="h-6 w-6 relative overflow-hidden shrink-0" loading="lazy" alt="" src={frame11} />
                                </div>
                                <div className="w-[243px] font-bold relative leading-[28px] capitalize inline-block">Easily send messages to any phone number.</div>
                            </div>
                            <div className="rounded-lg bg-white box-border overflow-hidden flex flex-col items-start justify-start py-6 px-[21px] gap-[16px] min-w-[316px] z-[3] border-[3px] border-solid border-teal-400 mq750:flex-1">
                                <div className="h-12 rounded-xl bg-coral-200 overflow-hidden shrink-0 flex flex-row items-start justify-start p-3 box-border">
                                    <img className="h-6 w-6 relative overflow-hidden shrink-0" loading="lazy" alt="" src={frame12} />
                                </div>
                                <div className="w-[268px]  font-bold relative leading-[28px] capitalize inline-block">Effortlessly import contacts from Excel.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <LayerMerger />
            <DropdownManager />
            <DataAggregator />
            <ValueTransformer />
        </div></>
    );
};

export default Home;
const HelloSection: FunctionComponent = () => {
    const scrollToSection = (sectionId: any) => {
        scroll.scrollTo(sectionId, {
            duration: 100,
            smooth: 'easeInOutQuart',
        });
    };

    return (
        <section className="self-stretch overflow-hidden flex flex-row items-center mt-10 justify-start pt-8 pb-[35px] pr-[93px] pl-[98px] box-border gap-[70px] max-w-full text-left text-21xl text-teal-100 font-outfit mq750:gap-[70px_17px] mq750:pt-[21px] mq750:pb-[23px] mq750:pr-[23px] mq750:pl-6 mq750:box-border  mq1225:gap-[70px_35px] mq1225:pl-[49px] mq1225:pr-[46px] mq1225:box-border">
            <div className="flex-1 flex flex-col items-start justify-start min-w-[385px] min-h-[429px] max-w-full mq750:min-w-full mq1225:min-h-[auto]">
                <div className="self-stretch flex flex-col items-start justify-start gap-[24px] max-w-full">
                    <div className="self-stretch flex flex-col items-start justify-start gap-[13px] max-w-full">
                        <div className="w-[578px] flex flex-row items-start justify-start relative max-w-full">
                            <h1 className="m-0 flex-1 relative text-inherit leading-[56px] capitalize inline-block max-w-full z-[1] font-inherit mq750:text-13xl mq750:leading-[45px] mq450:text-5xl mq450:leading-[34px]">
                                <span className="font-bold">{`Transforming the world through `}</span>
                                <span className="font-outfit text-coral-100">instant connections.</span>
                            </h1>
                           
                        </div>
                        <div className="self-stretch relative text-xl leading-[32px] capitalize font-outfit text-dimgray-200 z-[1] mq450:text-base mq450:leading-[26px]">
                            Elevate your communication with seamless SMS delivery. Experience the power of quick, reliable, and user-friendly messaging. Connect with ease, anytime, anywhere.
                        </div>
                    </div>
                    <Link to="/register">
                        <button className="cursor-pointer   items-center [border:none]   bg-teal-100 w-[200px] py-2 rounded-29xl overflow-hidden shrink-0 flex flex-row px-4  justify-between box-border gap-[23px]">
                            <div className=" flex flex-row justify-center items-center self-stretch relative text-lg capitalize font-outfit text-white text-left whitespace-nowrap">Get started</div>

                            <div className="w-10 h-10 rounded-full bg-white flex flex-row justify-center items-center">
                                <IconArrowLeft className="w-7" />
                            </div>
                        </button>
                    </Link>
                </div>
            </div>
            <div className="  flex-1 flex flex-col items-end justify-start gap-[23px] min-w-[382px] max-w-full mq750:min-w-full" id="home">
          
                <img className="self-stretch h-[582px] relative max-w-full overflow-hidden shrink-0 object-cover" loading="lazy" alt="" src={img2} />
            </div>
        </section>
    );
};

const BranchDistribution: FunctionComponent = () => {
    return (
        <div className=" w-[385.3px] h-[802.7px] absolute !m-[0] right-[518.7px] bottom-[-477.7px] text-center text-mid text-black font-cairo">
            
            <div className=''>
                <img src={Phone} className='hidden sm:block ml-[300px] mt-[50px]  lg:mt-0 lg:ml-0' alt="" />
            </div>

            <div className="absolute top-[771.8px] left-[129.9px] rounded-81xl bg-black w-[124.6px] h-[4.5px] z-[3]" />
        </div>
    );
};

const FrameComponent: FunctionComponent = () => {
    return (
        <div className="w-full flex flex-col items-start justify-start gap-[19px] max-w-full text-center text-19xl text-teal-100 font-gilroy-heavy" id="features">
            <div className="self-stretch flex flex-row items-start justify-center py-0 px-5">
                <button className="cursor-pointer [border:none] py-3 px-6 bg-white rounded-lg flex flex-row items-start justify-start gap-[8px] z-[4]">
                    <div className="h-[21px] flex flex-col items-start justify-start pt-px px-0 pb-0 box-border">
                        <img className="w-5 h-5 relative overflow-hidden shrink-0" alt="" src={appBadge} />
                    </div>
                    <div className="relative text-lg capitalize font-outfit text-coral-100 text-left inline-block min-w-[72px]">features</div>
                </button>
            </div>
            <div className=" text-white  font-outfit  self-stretch flex flex-row items-start justify-center py-0 px-5 box-border max-w-full">
                <h1 className="m-0 h-[109px] w-[564px] relative text-inherit leading-[54px] capitalize inline-block shrink-0 max-w-full z-[4] font-inherit mq750:text-11xl mq750:leading-[43px] mq450:text-4xl mq450:leading-[32px]">
                    <span className="font-bold">Explore</span>
                    <span className="">{` `}</span>
                    <span className="font-medium">{`seamless `}</span>
                    <span className="font-extrabold">communication.</span>
                </h1>
            </div>
            <div className="self-stretch relative text-xl leading-[28px] capitalize font-outfit text-whitesmoke z-[4] mq450:text-base mq450:leading-[22px]">
                Enhance your messaging experience with BULK SMS. Our platform caters to your unique needs, providing a comprehensive solution for individuals and businesses alike. Elevate your
                messaging game and experience the difference today.
            </div>
        </div>
    );
};

const DropdownManager: FunctionComponent = () => {
    return (
        <section
            className=" font-outfit self-stretch h-fit flex flex-row items-start justify-center pt-0 px-5 pb-[140px] box-border max-w-full text-left text-21xl text-teal-100 font-gilroy-bold mq750:pb-[91px] mq750:box-border"
            id="pricing"
        >
            <div className=" w-[1162px] overflow-x-auto shrink-0 flex flex-row  flex-wrap items-center justify-center gap-[77px] max-w-full mq750:gap-[77px_19px] mq1225:gap-[77px_38px]">
                <SliderController
                    silver="Silver"
                    silverImage={frame35}
                    everySmsCosts15RWFInThisS="Every sms Costs 15 RWF in this Silver Package"
                    informationPresenter="15"
                    startsFrom0="Starts from 0"
                    endsAt1000Sms="To  1000 sms"
                />
                <SliderController
                    gold="Gold"
                    silver="Gold"
                    goldImage={frame36}
                    everySmsCosts15RWFInThisS="Every sms Costs 12 RWF in this Gold Package"
                    informationPresenter="12"
                    startsFrom0="Starts from 1001"
                    endsAt1000Sms="To 10000 sms"
                    propMinWidth="91px"
                    propMinWidth1="unset"
                    propWidth="189px"
                    propFlex="1"
                />
                <div className="self-stretch w-[336px] rounded-3xl bg-white overflow-hidden shrink-0 flex flex-col items-start justify-start pt-0 pb-[38px] pr-[26px] pl-[46px] box-border gap-[32px]">
                    <img className="w-[98px] h-[97px] relative rounded-lg overflow-hidden shrink-0" alt="" src={frame37} />
                    <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
                        <div className="w-[155px] flex flex-col items-start justify-start">
                            <h1 className="m-0 self-stretch h-[50px] relative text-inherit capitalize font-normal font-inherit inline-block mq750:text-13xl mq450:text-5xl">Platnum</h1>
                        </div>
                        <div className="self-stretch flex flex-col items-start justify-start gap-[8px] text-lg text-gray font-gilroy-regular">
                            <div className="self-stretch relative leading-[28px] capitalize">Every sms Costs 10 RWF in this Platnum Package</div>
                            <div className="flex flex-row items-center justify-start gap-[7px] text-5xl text-coral-100 font-gilroy-extrabold">
                                <div className="relative capitalize inline-block min-w-[25px] mq450:text-lgi">10</div>
                                <div className="relative capitalize inline-block min-w-[42px] mq450:text-lgi">rwf</div>
                            </div>
                        </div>
                    </div>
                    <div className="w-[252px] flex flex-col items-start justify-start gap-[16px] text-lg text-dimgray-100 font-gilroy-regular">
                        <div className="flex flex-row items-start justify-start gap-[8px]">
                            <div className="h-[21px] flex flex-col items-start justify-start pt-px px-0 pb-0 box-border">
                                <img className="w-5 h-5 relative overflow-hidden shrink-0" alt="" src={box} />
                            </div>
                            <div className="relative capitalize">Starts from 10001</div>
                        </div>
                        <div className="self-stretch flex flex-row items-start justify-start gap-[8px]">
                            <div className="h-[21px] flex flex-col items-start justify-start pt-px px-0 pb-0 box-border">
                                <img className="w-5 h-5 relative overflow-hidden shrink-0" alt="" src={box} />
                            </div>
                            <div className="flex-1 relative capitalize">To any number of Sms</div>
                        </div>
                        <div className="self-stretch flex flex-row items-start justify-start gap-[8px]">
                            <div className="h-[21px] flex flex-col items-start justify-start pt-px px-0 pb-0 box-border">
                                <img className="w-5 h-5 relative overflow-hidden shrink-0" alt="" src={box} />
                            </div>
                            <div className="flex-1 relative capitalize">Unlimited hours of support</div>
                        </div>
                    </div>
                    <button className="cursor-pointer [border:none] bg-teal-100 h-14 rounded-xl overflow-hidden shrink-0 flex flex-row items-center justify-center w-[80%] box-border gap-5 ">
                        <div className=" flex flex-col items-center justify-center text-lg capitalize font-outfit text-white text-left  min-w-[81px]">try it now</div>

                        <div className="flex flex-col items-center justify-center">
                            <IconArrowLeft className="text-white w-30 h-16  " />
                        </div>
                    </button>
                </div>
            </div>
        </section>
    );
};

export type SliderControllerType = {
    silver?: string;
    silverImage?: string;
    gold?: string;
    goldImage?: string;
    everySmsCosts15RWFInThisS?: string;
    informationPresenter?: string;
    startsFrom0?: string;
    endsAt1000Sms?: string;
    propMinWidth?: CSSProperties['minWidth'];
    propMinWidth1?: CSSProperties['minWidth'];
    propWidth?: CSSProperties['width'];
    propFlex?: CSSProperties['flex'];
};

const SliderController: FunctionComponent<SliderControllerType> = ({
    silver,
    silverImage,
    gold,
    goldImage,
    everySmsCosts15RWFInThisS,
    informationPresenter,
    startsFrom0,
    endsAt1000Sms,
    propMinWidth,
    propMinWidth1,
    propWidth,
    propFlex,
}) => {
    return (
        <div className=" self-stretch w-[336px] rounded-3xl bg-white overflow-hidden shrink-0 flex flex-col items-start justify-start pt-0 px-[35px] pb-[42px] box-border gap-[28px] text-left text-21xl text-teal-100 font-gilroy-bold mq750:pb-[27px] mq750:box-border">
            {silverImage && <img className="w-[98px] h-[97px] relative rounded-lg overflow-hidden shrink-0" loading="lazy" alt="" src={silverImage} />}
            {goldImage && <img className="w-[98px] h-[97px] relative rounded-lg overflow-hidden shrink-0" loading="lazy" alt="" src={goldImage} />}
            <div className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-1">
                <div className="flex-1 flex flex-col items-start justify-start gap-[8px]">
                    <div className="flex flex-col items-start justify-start">
                        <h1
                            className="m-0 h-[50px] relative text-inherit capitalize font-normal font-inherit inline-block min-w-[102px] mq750:text-13xl mq450:text-5xl"
                            style={{ minWidth: propMinWidth }}
                        >
                            {silver}
                        </h1>
                    </div>
                    <div className="self-stretch flex flex-col items-start justify-start gap-[8px] text-lg text-gray font-gilroy-regular">
                        <div className="self-stretch relative leading-[28px] capitalize">{everySmsCosts15RWFInThisS}</div>
                        <div className="flex flex-row items-center justify-start gap-[7px] text-5xl text-coral-100 font-gilroy-extrabold">
                            <div className="relative capitalize inline-block min-w-[24px] mq450:text-lgi">{informationPresenter}</div>
                            <div className="relative capitalize inline-block min-w-[42px] mq450:text-lgi">rwf</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-[252px] flex flex-col items-start justify-start pt-0 px-0 pb-1 box-border gap-[16px] text-lg text-dimgray-100 font-gilroy-regular">
                <div className="flex flex-row items-start justify-start gap-[8px]">
                    <div className="h-[21px] flex flex-col items-start justify-start pt-px px-0 pb-0 box-border">
                        <img className="w-5 h-5 relative overflow-hidden shrink-0" loading="lazy" alt="" src={box} />
                    </div>
                    <div className="relative capitalize inline-block min-w-[109px]" style={{ minWidth: propMinWidth1 }}>
                        {startsFrom0}
                    </div>
                </div>
                <div className="flex flex-row items-start justify-start gap-[8px]" style={{ width: propWidth }}>
                    <div className="h-[21px] flex flex-col items-start justify-start pt-px px-0 pb-0 box-border">
                        <img className="w-5 h-5 relative overflow-hidden shrink-0" alt="" src={box} />
                    </div>
                    <div className="relative capitalize" style={{ flex: propFlex }}>
                        {endsAt1000Sms}
                    </div>
                </div>
                <div className="self-stretch flex flex-row items-start justify-start gap-[8px]">
                    <div className="h-[21px] flex flex-col items-start justify-start pt-px px-0 pb-0 box-border">
                        <img className="w-5 h-5 relative overflow-hidden shrink-0" alt="" src={box} />
                    </div>
                    <div className="flex-1 relative capitalize">Unlimited hours of support</div>
                </div>
            </div>
            <button className="cursor-pointer [border:none] bg-teal-100 h-14 rounded-xl overflow-hidden shrink-0 flex flex-row items-center justify-center w-[80%] box-border gap-5 ">
                <div className=" flex flex-col items-center justify-center text-lg capitalize font-outfit text-white text-left  min-w-[81px]">try it now</div>

                <div className="flex flex-col items-center justify-center">
                    <IconArrowLeft className="text-white w-30 h-16  " />
                </div>
            </button>
        </div>
    );
};
const LayerMerger: FunctionComponent = () => {
    return (
        <section className=" font-outfit w-full flex flex-row items-start justify-center pt-0 px-5 pb-[70px] box-border max-w-full text-left text-13xl text-teal-100 font-gilroy-semibold">
            <div className="w-[1192px] flex flex-row items-center justify-between max-w-full gap-[12px] mq1225:flex-wrap">
                <div className="w-[615px]  flex flex-col items-start justify-start py-0 pr-[375px] pl-0 box-border gap-[15px] min-w-[615px] max-w-full mq750:min-w-full mq1225:flex-1 mq1225:pr-0 mq1225:box-border">
                    <button className="cursor-pointer [border:none] py-3 px-6 bg-coral-200 h-[46px] rounded-lg flex flex-row items-center justify-start box-border gap-[10px] hover:bg-chocolate">
                        <img className="h-5 w-5 relative overflow-hidden shrink-0" alt="" src={appBadge} />
                        <div className="relative text-lg capitalize font-bold text-coral-100 text-left inline-block min-w-[57px]">pricing</div>
                    </button>
                    <div className=" text-7xl capitalize font-bold 0 text-left ">Choose your plan</div>
                </div>
                <div className="w-[565px] relative text-xl  font-bold leading-[28px] capitalize text-darkslategray-100 inline-block shrink-0 max-w-full mq450:text-base mq450:leading-[22px]">
                    Unlock Cost-Effective Communication with Our Flexible SMS Pricing
                </div>
            </div>
        </section>
    );
};

const DataAggregator: FunctionComponent = () => {
    return (
        <section className="w-full font-outfit  flex flex-col items-start justify-center pt-0 px-5 pb-16 box-border max-w-full text-left text-5xl text-teal-100  font-bold  mq750:pb-[42px] mq750:box-border">
            <button className=" mx-16 my-8 cursor-pointer [border:none] py-3 px-6 bg-coral-200 rounded-lg flex flex-row items-start justify-start whitespace-nowrap hover:bg-chocolate">
                        <div className="relative text-lg capitalize font-gilroy-bold text-coral-100 text-left inline-block min-w-[76px]">about us</div>
                    </button>
            <div className="w-full flex flex-row flex-wrap  justify-between  items-center gap-3 ">
            <div className="hidden lg:block   mt-32 self-stretch  flex-row items-start justify-between max-w-full gap-[20px] text-lg text-black font-gilroy-semibold ">
                    <div className="w-full  flex flex-col items-start justify-start pt-[117px] px-0 pb-0 box-border min-w-[416px] max-w-full mq750:min-w-full mq1225:flex-1">
                        <div className="self-stretch flex flex-row items-start justify-start relative max-w-full">
                            <img className=" h-[332px] w-[529px]  absolute !m-[0] top-[-249px] right-[-57px] rounded-3xl object-cover" alt="" src={rect} />
                            <div className="flex-1 rounded-3xl bg-white shadow-[0px_0px_19px_rgba(215,_215,_215,_0.25)] overflow-hidden flex flex-row items-start justify-start p-8 box-border gap-[16px] max-w-full z-[1] mq450:flex-wrap">
                                <div className="h-[58px] flex flex-col items-start justify-start pt-[26px] px-0 pb-0 box-border">
                                    <img className="w-8 h-8 relative overflow-hidden shrink-0" loading="lazy" alt="" src={message} />
                                </div>
                                <div className="flex-1 relative leading-[28px] capitalize inline-block min-w-[198px]">
                                    Successfully Providing software solutions and messaging services for over 7 years
                                </div>
                            </div>
                        </div>
                    </div>
                   
                </div>
                <div>
                <div className=" w-full self-stretch flex flex-row items-start justify-center py-0 pr-5 pl-[106px] mq450:pl-5 mq450:box-border">
                    
                </div>
                <div className= "self-stretch flex flex-row items-start justify-center  ">
                    <div className="w-[612px] relative leading-[32px] capitalize inline-block shrink-0 max-w-full mq450:text-lgi mq450:leading-[26px]">
                        <p className="m-0">{`As a leading software development `}</p>
                        <p className="m-0 whitespace-pre-wrap">and messaging company,</p>
                    </div>
                </div>
                <div className="h-56 w-[612px] relative leading-[32px] capitalize bg-white  font-outfit  font-normal text-black  inline-block shrink-0 min-w-[612px] max-w-full mq750:min-w-full mq1225:flex-1">
                        <p className="my-4">{`we pride ourselves on delivering innovative solutions tailored `}</p>
                        <p className="m-0 text-xl md:text-lg">
                            to meet the unique needs of our clients. With a wealth of experience spanning over two decades, we have consistently provided cutting-edge software solutions and messaging
                            services. Our commitment to excellence has made us a trusted partner in the industry. Explore our website to learn more about our services and how we can help your business
                            thrive in the digital landscape.
                        </p>
                    </div>
                </div>
               
               
            </div>
        </section>
    );
};
const ValueTransformer: FunctionComponent = () => {
    return (
        <footer className="self-stretch flex flex-col items-start justify-start gap-[26px] max-w-full text-left text-base text-white font-gilroy-semibold">
            <div className="self-stretch bg-teal-100 overflow-hidden flex flex-col items-start justify-start pt-0 pb-[55px] pr-0 pl-[93px] box-border relative gap-[28px] max-w-full mq750:pl-[23px] mq750:box-border mq1225:pl-[46px] mq1225:box-border">
                <img className="w-full h-[243px] absolute !m-[0] right-[0px] bottom-[-4px] left-[0px] max-w-full overflow-hidden shrink-0 object-cover mix-blend-normal" alt="" src={bgFitter} />
                <div className="ml-[-104px] w-[1451px] bg-teal-200 box-border overflow-hidden flex flex-row items-start justify-between pt-4 pb-[15px] pr-[68px] pl-[104px] max-w-[108%] shrink-0 gap-[20px] z-[1] border-b-[3px] border-solid border-darkcyan mq750:pl-[52px] mq750:pr-[34px] mq750:box-border mq450:pl-5 mq450:box-border mq1050:flex-wrap">
                    <div className="w-[326px] flex flex-col items-start justify-start pt-0.5 px-0 pb-0 box-border max-w-full">
                        <div className="self-stretch relative leading-[28px] capitalize">Get connected with us on social networks:</div>
                    </div>
                    <div className="h-[33px] w-[235px] flex flex-row items-start justify-start gap-[21px]">
                        <div className="h-[33px] flex-1 flex flex-row items-start justify-start gap-[12px]">
                            <div className="self-stretch h-8 flex flex-row items-start  gap-5 justify-center">
                                <img className="h-8 w-8 relative z-[1]" loading="lazy" alt="" src={inst} />
                                <img className="h-8 w-8 relative" loading="lazy" alt="" src={link} />
                                <img className="h-8 w-8 relative" loading="lazy" alt="" src={fa} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-row items-start justify-start py-0 pr-5 pl-0 box-border gap-[46.5px] max-w-full text-mid mq750:gap-[46.5px_23px] mq1225:flex-wrap">
                    <div className="w-[443px] flex flex-col items-start justify-start pt-1 px-0 pb-0 box-border max-w-full">
                        <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
                            <div className="relative leading-[28px] capitalize inline-block min-w-[97px] z-[1]">ISHEMA HUB</div>
                            <div className="self-stretch relative leading-[28px] capitalize font-outfit z-[1]">
                                we are a software devlopment campany and we provide any digital solution suitable for your campany
                            </div>
                        </div>
                    </div>
                    <div className="w-[210px] flex flex-col items-start justify-start gap-[8px] z-[1] text-lg font-gilroy-bold">
                        <div className="relative leading-[28px] capitalize inline-block min-w-[94px]">quick links</div>
                        <div className="self-stretch flex flex-row flex-wrap items-start justify-start py-0 pr-0.5 pl-0 gap-[24px_22px] font-outfit">
                            <div className="flex-1 flex flex-col items-start justify-start gap-[12px]">
                                <div className="self-stretch relative leading-[28px] capitalize">{`Home `}</div>
                                <div className="self-stretch relative leading-[28px] capitalize">features</div>
                            </div>
                            <div className="flex flex-col items-start justify-start gap-[12px]">
                                <div className="relative leading-[28px] capitalize inline-block min-w-[56px]">pricing</div>
                                <div className="relative leading-[28px] capitalize inline-block min-w-[76px]">about us</div>
                            </div>
                        </div>
                    </div>
                    <div className="w-[472px] flex flex-row items-start justify-start gap-[34px] max-w-full font-outfit mq750:gap-[34px_17px] mq450:flex-wrap">
                        <div className="w-[169px] flex flex-col items-start justify-start gap-[10px] min-w-[169px] mq450:flex-1">
                            <div className="w-[77px] relative text-lg leading-[28px] capitalize font-gilroy-bold inline-block z-[1]">contacts</div>
                            <div className="self-stretch relative leading-[28px] capitalize whitespace-nowrap z-[1]">info@ishemahub.com</div>
                            <div className="relative leading-[28px] capitalize whitespace-nowrap z-[1]">+250 792 4004 74</div>
                        </div>
                        <div className="flex-1 flex flex-col items-start justify-start pt-1.5 px-0 pb-0 box-border min-w-[175px] text-lg font-gilroy-bold">
                            <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
                                <div className="relative leading-[28px] capitalize inline-block min-w-[67px] z-[1]">address</div>
                                <div className="self-stretch relative leading-[28px] capitalize font-outfit z-[1]">Downtown, MAKUZA Parking Towers 5th Floor. Kigali, Rwanda</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-center py-0 pr-5 pl-[] box-border max-w-full text-mid text-dimgray-300 font-outfit ">
                <div className="w-[331px] relative leading-[22px] capitalize inline-block max-w-full">© 2023 Copyright:Powered by ISHEMA Hub</div>
            </div>
        </footer>
    );
};
