import { Dialog, Transition } from '@headlessui/react';
import { useState, Fragment } from 'react';
import { z } from 'zod';
import { useCreateGroup, useDeleteCron } from '../../../api/groups';
import IconPlus from '../../../components/Icon/IconPlus';
import { Form } from '../../../components/form';
import InputField from '../../../components/inputField';
import IconTrash from '../../../components/Icon/IconTrash';

export default function DeleteCron({ handeRefetch, data }: any) {
    const { deleteCron, isLoading } = useDeleteCron();
    const [modal, setModal] = useState(false);

    const submit = async () => {
        await deleteCron(data?.id);
        handeRefetch();
        setModal(false);
    };

    return (
        <div className="">
            <div className="flex items-center justify-center">
                <button type="button" onClick={() => setModal(true)} className="btn btn-danger btn-sm">
                    <IconTrash className="w-4" />
                </button>
            </div>
            <Transition appear show={modal} as={Fragment}>
                <Dialog as="div" open={modal} onClose={() => setModal(false)}>
                    <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                        <div className="fixed inset-0" />
                    </Transition.Child>
                    <div className="fixed inset-0 bg-[black]/60 z-[999] overflow-y-auto">
                        <div className="flex items-start justify-center min-h-screen px-4">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel as="div" className="panel border-0 p-0 rounded-lg overflow-hidden my-8 w-full max-w-lg text-black dark:text-white-dark">
                                    <div className="flex bg-[#fbfbfb] dark:bg-[#121c2c] items-center justify-between px-5 py-3">
                                        <div className="text-lg flex flex-row justify-center w-full font-bold ">Delete schedule </div>
                                    </div>
                                    <div className="p-5">
                                       
                                            <div className="w-full">
                                                <div className="px-4 flex flex-col gap-4 text-sm mb-3">
                                                    <p>
                                                        Are you sure you want to delete this Schedule. Sending <strong>{data?.message}</strong> to <strong>{data?.group.name}</strong>?
                                                    </p>

                                                    <div className="my-3 flex justify-end">
                                                        <button type="button"
                                                         onClick={submit} disabled={isLoading} className="btn btn-primary">
                                                            Submit
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                     
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </div>
    );
}

