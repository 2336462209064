import { Dialog, Transition } from '@headlessui/react';
import { useState, Fragment } from 'react';
import FileSaver from 'file-saver';
import { toast } from 'react-toastify';
import IconDownload from '../../../../components/Icon/IconDownload';
import { File_BASE } from '../../../../utils/constant';
import { useUploadFile } from '../../../../api/groups';

export default function UploadContact({ data, handleRefetch }: any) {
    const [modal, setModal] = useState(false);
    const { uploadFile, uploading, error } = useUploadFile();

    const handleDownload = () => {
        let sliceSize = 1024;
        let byteCharacters = atob(File_BASE);
        let bytesLength = byteCharacters.length;
        let slicesCount = Math.ceil(bytesLength / sliceSize);
        let byteArrays = new Array(slicesCount);
        for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
            let begin = sliceIndex * sliceSize;
            let end = Math.min(begin + sliceSize, bytesLength);
            let bytes = new Array(end - begin);
            for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
                bytes[i] = byteCharacters[offset].charCodeAt(0);
            }
            byteArrays[sliceIndex] = new Uint8Array(bytes);
        }
        FileSaver.saveAs(new Blob(byteArrays, { type: 'application/vnd.ms-excel' }), 'my-excel.xlsx');
    };

    const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            try {
                await uploadFile(file, data?.id);
              
                toast.success('File uploaded successfully');
                handleRefetch(); 
            } catch (error) {
             
                console.error('File upload failed:', error);
                toast.error('File upload failed');
            }
        }
    };

    return (
        <div className="">
            <div className="flex items-center justify-center">
                <button type="button" onClick={() => setModal(true)} className="btn btn-primary">
                    Upload contacts
                </button>
            </div>
            <Transition appear show={modal} as={Fragment}>
                <Dialog as="div" open={modal} onClose={() => setModal(false)}>
                    <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                        <div className="fixed inset-0" />
                    </Transition.Child>
                    <div className="fixed inset-0 bg-[black]/60 z-[999] overflow-y-auto">
                        <div className="flex items-start justify-center min-h-screen px-4">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel as="div" className="panel border-0 p-0 rounded-lg overflow-hidden my-8 w-full max-w-lg text-black dark:text-white-dark">
                                    <div className="flex bg-[#fbfbfb] dark:bg-[#121c2c] items-center justify-between px-5 py-3">
                                        <div className="text-lg flex flex-row justify-center w-full font-bold ">Upload Contact to {data?.name}</div>
                                    </div>
                                    <div className="p-5">
                                        <div className="  w-full">
                                            <label htmlFor="" className="text-gray-600 dark:text-gray-400 font-bold">
                                                {' '}
                                                Download template
                                            </label>

                                            <button type="button" className="btn btn-primary w-full " onClick={handleDownload}>
                                                <IconDownload />
                                            </button>
                                        </div>
                                    </div>
                                    <div className="p-5">
                                        <div className="  w-full">
                                            <label htmlFor="" className="text-gray-600 dark:text-gray-400 font-bold">
                                                {' '}
                                                Upload Excel file
                                            </label>
                                            <input type="file" accept=".xls, .xlsx" onChange={handleFileUpload} className="btn btn-primary w-full" />
                                        </div>
                                        {/* Display error if any */}
                                        {error && <div className="text-red-500">{error}</div>}
                                        {/* Display uploading message if uploading */}
                                        {uploading && <div className="text-blue-500">Uploading...</div>}
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </div>
    );
}
