import { UseFormRegisterReturn } from 'react-hook-form';

import { ChangeEvent, useState } from 'react';
import clsx from 'clsx';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';

import { DefaultValue } from '@mantine/core/lib/MultiSelect/DefaultValue/DefaultValue';

type InputFieldProps = FieldWrapperPassThroughProps & {
	type?: 'text' | 'number' | 'email' | 'password' | 'date' | 'file' | 'tel';
	placeholder?: string;
	className?: string;
    defaultValue?:string;
	registration?: Partial<UseFormRegisterReturn>;
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
};

export default function   InputField(props: InputFieldProps) {
    const {
        type = 'text',
        label,
    
        defaultValue='',
        icon,
        placeholder = '',
        error,
        registration,
        isLoading,
        className = '',
        onChange
    } = props;

    const [passwordVisible, setPasswordVisible] = useState<boolean>(false);

    return (
        <FieldWrapper label={label} icon={icon} error={error} isLoading={isLoading}>
            <div className="flex w-full flex-row items-center justify-center">
                <input
                    type={
                        type === 'password' ? (passwordVisible ? 'text' : 'password') : type
                    }
                    autoComplete="off"
                    placeholder={placeholder}
                    disabled={isLoading}
                    defaultValue={defaultValue}
                    onChange={onChange}
                    className={clsx(
                        'form-input',
                        icon ? 'px-2' : 'pl-3',
                        type === 'date' && 'uppercase',
                        className
                    )}
                    {...registration}
                />
                {type === 'password' &&
                    (passwordVisible ? (
                        <EyeIcon
                            className="icon-md mx-2 cursor-pointer w-5 text-primary"
                            onClick={() => setPasswordVisible(false)}
                        />
                    ) : (
                        <EyeSlashIcon
                            className="icon-md mx-2 cursor-pointer w-5 text-primary"
                            onClick={() => setPasswordVisible(true)}
                        />
                    ))}

            </div>
        </FieldWrapper>
    );
}


import { ReactNode } from 'react';
import { FieldError } from 'react-hook-form';

type FieldWrapperProps = {
    children: ReactNode;
    label?: string;
    icon?: ReactNode;
    className?: string;
    error?: FieldError | any;
    isLoading?: boolean;
};

export type FieldWrapperPassThroughProps = Omit<
    FieldWrapperProps,
    'className' | 'children' 
>;

export const FieldWrapper = (props: FieldWrapperProps) => {
    const {
        children,
        label,
        icon,
        error,
        className = '',
        isLoading = false,
    } = props;

    return (
        <div className="block w-full">
            <label>
                {label && (
                    <div className="mb-1 dark:text-white-light">
                        {label}
                    </div>
                )}

                <div
                    className={clsx(
                        'flex items-center rounded overflow-hidden bg-transparent text-[15px] font-medium',
                        isLoading ? 'cursor-wait opacity-80' : '',
                        error?.message ? 'border-red-600' : '',
                        className
                    )}
                >
                    {icon && <div className="px-3 text-primary">{icon}</div>}

                    <div className="grow">{children}</div>
                </div>
            </label>

            {error?.message && (
                <div
                    role="alert"
                    aria-label={error.message}
                    className={`${icon ? 'ml-12' : ''} mt-2 text-danger`}
                >
                    {error.message}
                </div>
            )}
        </div>
    );
};
