import { Tab } from '@headlessui/react';
import { UserIcon } from '@heroicons/react/24/outline';
import { Fragment } from 'react';
import { FaIdBadge, FaKey } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import SenderId from '../senderId/senderId';
import Profile from '../users/Profile';
import { ApiKeys } from '../apiKey';

export default function AdminReportsSummary() {
    const tabs = [
        {
            icon: FaKey,
            title: 'API key',
            selected: true,
        },
        {
            icon: FaIdBadge,
            title: 'Sender Id',
            selected: false,
        },
        {
            icon: UserIcon,
            title: 'Profile',
            selected: false,
        },
    ];

    const tabItems = [<ApiKeys />, <SenderId />, <Profile />];
    return (
        <div className="text-gray-700 ">
            <ul className="flex space-x-2 rtl:space-x-reverse">
                <li>
                    <Link to="/dashboard" className="text-primary hover:underline">
                        Dashboard
                    </Link>
                </li>

                <li className="before:content-['/'] ltr:before:mr-2 rtl:before:ml-2 text-gray-500">
                    <span>settings</span>
                </li>
            </ul>
            <div className="panel mt-5" id="icon">
                <div className="mb-5">
                    <Tab.Group>
                        <Tab.List className="mt-3 mr-3 flex flex-wrap border-b border-white-light dark:border-[#39437b]">
                            {tabs.map((item, index) => (
                                <Tab key={index} as={Fragment}>
                                    {({ selected }) => (
                                        <button
                                            className={`${
                                                selected ? '!border-white-light !border-b-white text-primary !outline-none dark:!border-[#39437b] dark:!border-b-black' : 'dark:text-gray-400'
                                            }
                                    dark:hover:border-b-black' -mb-[1px] flex items-center border border-transparent p-3.5 py-2 hover:text-primary`}
                                        >
                                            <item.icon className="ltr:mr-2 w-5 rtl:ml-2" />
                                            {item.title}
                                        </button>
                                    )}
                                </Tab>
                            ))}
                        </Tab.List>
                        <Tab.Panels>
                            {tabItems.map((item, index) => (
                                <Tab.Panel key={index}>
                                    <div className="">{item}</div>
                                </Tab.Panel>
                            ))}
                        </Tab.Panels>
                    </Tab.Group>
                </div>
            </div>
        </div>
    );
}
