import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { showErrorMessage, showSuccessMessage } from "../../utils/toast";
import "react-toastify/dist/ReactToastify.css";
import DeleteSenderIdThunk from "../../Redux/actions/DeleteSenderId";
import DeleteUserThunk from "../../Redux/actions/deleteUser";
import IconTrash from "../../components/Icon/IconTrash";

export default function DeleteUser({ user, handleFilter }: any) {
  const dialog = useRef<HTMLDialogElement>(null);

  const closeModel = () => {
    if (dialog.current?.close) dialog.current.close();
  };

  const openModel = () => {
    if (dialog.current?.showModal) dialog.current.showModal();
  };

  const close = (e: React.MouseEvent<HTMLElement>) => {
    const dialogDimensions = dialog.current?.getBoundingClientRect();
    if (
      e.clientX < dialogDimensions!.left ||
      e.clientX > dialogDimensions!.right ||
      e.clientY < dialogDimensions!.top ||
      e.clientY > dialogDimensions!.bottom
    ) {
      closeModel();
    }
  };
  const dispatch = useDispatch<AppDispatch>();
  const { isLoading } = useSelector((state: RootState) => state.deleteSenderId);
  const Id = user.id;

  const handleDelete = async (rID: any) => {
    await dispatch(DeleteUserThunk(rID));
    handleFilter(rID);
    closeModel();

    showSuccessMessage("user Deleted");
  };

  return (
    <>
      <dialog
        ref={dialog}
        className=" dark:bg-[#121c2c] rounded-lg shadow-lg   "
        data-testid="dialog"
        onClick={(e) => close(e)}
      >
        <div className="p-3 rounded">
          <div className="flex flex-row justify-center items-center">
            <h3 className="p-2 text-gray-600 dark:text-gray-400 text-lg">
              <i className="fas fa-exclamation-triangle text-red-500 text-xl mx-2"></i>{" "}
              Delete User
            </h3>
          </div>
          <div>
            <div className="form-group">
              <div className="flex flex-row justify-center">
                <p className="text-md text-gray-600 dark:text-gray-400">
                  are you sure you want to Delete this user
                  <span className="font-semibold">    {user.firstName} </span>
              
                </p>
              </div>
            </div>

            <div className="mt-2 flex flex-row justify-center">
              <button
                type="submit"
                onClick={() => handleDelete(Id)}
                className="btn btn-primary"
              >
                {isLoading ? (
                  <i className="fa-solid fa-spinner text-red-400 text-lg "></i>
                ) : (
                  "Delete"
                )}
              </button>
              <button type="button" className="btn btn-outline-danger mx-2 " onClick={closeModel}>
                                                                    Discard
                                                                </button>
            </div>
          </div>
        </div>
      </dialog>
      <button onClick={() => openModel()} title="Delete user" className="btn
      
      btn-danger btn-sm">
                <IconTrash className=" w-4 " />
            </button>
    </>
  );
}
