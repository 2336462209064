import React, { useEffect, useRef, useState } from 'react';
import ExportDataTable, { TableColumn } from '../../components/datatable';
import formatDateToLongForm from '../../utils/Date';
import { useGetContacts, useGetCrons } from '../../api/groups';
import { Link } from 'react-router-dom';
import AdminCreateUser from '../users/AdminCreateUser';
import { capitalize } from '@mui/material';
import UpdateCron from './update';
import DeleteCron from './delete';
import MessageModal from './MessageModal';

function Schedules() {
    const { getCrons, crons, loading }: any = useGetCrons();

    useEffect(() => {
        getCrons();
    }, []);

    const [expandedMessage, setExpandedMessage] = useState<number | null>(null);
    const handleExpandMessage = (index: number) => {
        if (expandedMessage === index) {
            setExpandedMessage(null);
        } else {
            setExpandedMessage(index);
        }
    };

    
    const handeRefetch = () => {
        getCrons();
    };
    const columns: TableColumn<any>[] = [
        {
            title: 'Sender ID',
            accessor: 'senderId',
            render: (row) => <p>{row.senderId}</p>,
        },
        {
            title: 'Message',
            accessor: 'message',
            render: (row, index) => (
                <p onClick={() => handleExpandMessage(index)} className=" hover:underline">
                    {row.message && row.message.length > 30 ? row.message.substring(0, 30) + '...' : row.message || 'Not Provided'}
                    <MessageModal isOpen={expandedMessage === index} onClose={() => handleExpandMessage(index)} message={row} />,
                </p>
            ),
        },

        {
            title: 'Scheduled Time',
            accessor: 'schedule_time',
            render: (row) => <p>{formatDateToLongForm(row.created_at)}</p>,
        },
        {
            title: 'Is Initiated',
            accessor: 'isTriggered',
            render: (row) => <p>{row.isTriggered ? 'Yes' : 'No'}</p>,
        },

        {
            title: 'Created by',
            accessor: 'user.firstName', // Access nested property
            render: (row) => (
                <p>
                    {capitalize(row.user?.firstName)} {capitalize(row.user?.lastName)}
                </p>
            ),
        },
        {
            title: 'Group',
            accessor: 'group.name', // Access nested property
            render: (row) => <p>{row.group?.name}</p>,
        },
        {
            title: 'Created At',
            accessor: 'created_at',
            render: (row) => <p>{formatDateToLongForm(row.created_at)}</p>,
        },

        {
            title: 'Actions',
            accessor: 'created_at',
            render: (row) => (
                <p className="flex flex-row gap-2 ">
                   
                    <DeleteCron handeRefetch={handeRefetch} data={row} />
                </p>
            ),
        },
    ];

    return (
        <div className=" flex  flex-col        ">
            <div>
                <div className="w-full flex flex-row justify-between items-center">
                    <div>
                        <ul className="flex space-x-2 rtl:space-x-reverse">
                            <li>
                                <Link to="/dashboard" className="text-primary hover:underline">
                                    Dashboard
                                </Link>
                            </li>

                            <li className="before:content-['/'] ltr:before:mr-2 rtl:before:ml-2">
                                <span>Scheduled messages</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <ExportDataTable
                columns={columns}
                data={crons?.list ?? []}
                total={crons?.total ?? 0}
                currentPage={crons?.currentPage ?? 1}
                nextPage={crons?.nextPage ?? 0}
                previousPage={crons?.previousPage ?? 0}
                lastPage={crons?.lastPage ?? 0}
                isLoading={loading}
                filterComponent={undefined}
            />
        </div>
    );
}

export default Schedules;
