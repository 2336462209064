import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../configs/config';
import toast from 'react-hot-toast';

const  SendSmsThunk = createAsyncThunk(
  'sms/add',
  async (all: any, { rejectWithValue }) => {
    console.log(all);
    try {
      const data = all.formData;
      const response = await axios.post(
        '/api/v1/notifications/send-sms',
        data,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('Ishema-token')}`,
            ApiKey: `${all.Key}`,
          },
        }
      );

      return response.data;
    } catch (error:any) {
      if (error?.response && error?.response.data) {
        const errorMessage = error?.response.data.message;
        if (!Array.isArray(errorMessage)) {
          toast.error(errorMessage);
      }
      
        const errorMessages = Array.isArray(errorMessage)
          ? errorMessage
          : [errorMessage];

        return rejectWithValue(errorMessages[0]||errorMessage);
      } else {
        
        return rejectWithValue('An error occurred');
      }
    }
  }
);

export default SendSmsThunk;
