import * as yup from "yup";

const SendGroupSchema:any = yup.object().shape({
  message: yup
    .string()
    .required("Message is required"),
  senderId: yup
    .string()
    .required("Sender ID is required"),
    groupId: yup
    .string()
    .required("Group  is required")
});

export default SendGroupSchema;
