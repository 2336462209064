import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../configs/config";

const ReportThunk = createAsyncThunk("report", async (querry: any) => {
  try {
    const res = await axios.get(`/api/v1/wallet/transactions?${querry}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Ishema-token")}`,
      },
    });

    return res.data;
  } catch (error) {
    return { error };
  }
});

export default ReportThunk;
