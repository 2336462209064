import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { useForm } from 'react-hook-form';
import 'react-toastify/dist/ReactToastify.css';
import profile from '@/assets/images/avatar-icon-images-4 (1).jpg';
import toast from 'react-hot-toast';
import { yupResolver } from '@hookform/resolvers/yup';
import UpdateProfile from '../../Redux/actions/editUser';
import { AppDispatch, RootState } from '../../store';
import userSchema from '../../Validations/user';


function Profile() {
    const dispatch = useDispatch<AppDispatch>();
    const { data: api_data } = useSelector((state: RootState) => state.api);

    const { isLoading } = useSelector((state: RootState) => state.edit);
    const { user: data, loading } = useSelector((state: RootState) => state.user);
    console.log(data);
    const id = data.id;
    const [firstName, setfname] = useState(data.firstName || '');
    const [lastName, setLname] = useState(data.lastName || '');

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(userSchema),
    });

    const submit = async () => {
        try {
            const datas = { firstName, lastName };
            console.log(datas);
            const response = await dispatch(UpdateProfile({ datas, id })).then((action: any) => unwrapResult(action));
            toast.success('User updated successfully');
        } catch (e: any) {
            toast.error(e);
        }
    };

    return (
        <div className="">
            <form className="border border-[#ebedf2] text-white-dark  dark:border-[#191e3a] rounded-md p-4 mb-5 bg-white dark:bg-black" onSubmit={handleSubmit(submit)}>
                <h6 className="text-lg font-bold mb-5">Account information</h6>
                <div className="flex flex-col sm:flex-row">
                    <div className="ltr:sm:mr-4 rtl:sm:ml-4 w-full sm:w-2/12 mb-5">
                        <img src={profile} alt="img" className="w-20 h-20 md:w-32 md:h-32 rounded-full object-cover mx-auto" />
                    </div>
                    <div className="flex-1 grid grid-cols-1 sm:grid-cols-2 gap-5">
                        <div>
                            <label htmlFor="first-name">First Name</label>
                            <input
                                id="first-name"
                                type="text"
                                placeholder="First Name"
                                value={firstName}
                                {...register('firstName')}
                                className="form-input"
                                onChange={(e) => setfname(e.target.value)}
                            />
                            {errors.firstName && <p className="text-red-500 text-xs">First name is required</p>}
                        </div>
                        <div>
                            <label htmlFor="last-name">Last Name</label>
                            <input
                                id="last-name"
                                type="text"
                                placeholder="Last Name"
                                value={lastName}
                                className="form-input"
                                {...register('lastName')}
                                onChange={(e) => setLname(e.target.value)}
                            />
                            {errors.lastName && <p className="text-red-500 text-xs">Last name is required</p>}
                        </div>
                        <div>
                            <label htmlFor="name">Role</label>
                            <input id="name" type="text" placeholder="ex. developer" defaultValue={data?.role} className="form-input" disabled />
                        </div>
                        <div>
                            <label htmlFor="email">Email</label>
                            <input id="email" type="email" placeholder="ex. Jimmy@gmail.com" defaultValue={data?.email} className="form-input" disabled />
                        </div>
                        <div className="sm:col-span-2 mt-3">
                            <button type="submit" className="btn btn-primary">
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default Profile;
