import { useState, useCallback, useEffect } from 'react';
import PRIVATE_API from './ax';
import config from '../config';
import axios from 'axios';
import toast from 'react-hot-toast';
import { storage } from '../utils/storage';

export const queryString = (query?: string): string => {
  return query ? `${query}` : '';
};
export const useGetGroupList = () => {
    const [loading, setLoading] = useState(false);
    const [groups, setGroups] = useState<any[]>([]);
    const [error, setError] = useState<string | null>(null);

    const fetchGroups = useCallback(async () => {
        setLoading(true);
        try {
            const response: any = await PRIVATE_API.get('/group',

            );
            setGroups(response?.data);
        } catch (error: any) {
            setError(error.response?.data?.message || 'An unexpected error occurred');
        } finally {
            setLoading(false);
        }
    }, []);

    return { loading, groups, error, fetchGroups };
};

export const useGetWalletStatistics = () => {
    const [loading, setLoading] = useState(false);
    const [walletStatistics, setWalletStatistics] = useState<any>(null);
    const [error, setError] = useState<string | null>(null);

    const fetchWalletStatistics = async () => {
        setLoading(true);
        try {
            const response: any = await PRIVATE_API.get('/wallet/statistics');
            console.log(response);
            setWalletStatistics(response?.data);
        } catch (error: any) {
            setError(error.response?.data?.message || 'An unexpected error occurred');
        } finally {
            setLoading(false);
        }
    };

    return { loading, walletStatistics, error, fetchWalletStatistics };
};

export const useCreateGroup = () => {
    const [creating, setCreating] = useState(false);
    const [newGroup, setNewGroup] = useState<any>(null);
    const [error, setError] = useState<string | null>(null);

    const createGroup = useCallback(async (groupData: any) => {
        setCreating(true);
        try {
            const response: any = await PRIVATE_API.post('/group', groupData);
            setNewGroup(response?.data);
            toast.success('Group created');
        } catch (error: any) {
            setError(error.response?.data?.message || 'An unexpected error occurred');
        } finally {
            setCreating(false);
        }
    }, []);

    return { creating, newGroup, error, createGroup };
};

export const useGetContacts = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [contacts, setContacts] = useState<any[]>([]);
    const baseURL: any = config.API_URL;
    const getContacts = useCallback(async (query?:string) => {
        setLoading(true);
        try {
            
                const response: any = await axios.get(`${baseURL}contacts${queryString(query)}`, {
                    headers: {
                        Authorization: `Bearer ${storage.getToken()}`,
                    },
                });
                setContacts(response?.data || []);
        
        } catch (error: any) {
            setError(error.response?.data?.message || 'An unexpected error occurred');
        } finally {
            setLoading(false);
        }
    }, []);

    return { loading, error, contacts, getContacts };
};
export const useGetCrons = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [crons, setCrons] = useState<any[]>([]);
  const baseURL: any = config.API_URL;

  const getCrons = useCallback(async () => {
      setLoading(true);
      try {
          const response: any = await axios.get(`${baseURL}api/v1/cron`, {
              headers: {
                  Authorization: `Bearer ${storage.getToken()}`,
              },
          });
          setCrons(response?.data || []);
      } catch (error: any) {
          setError(error.response?.data?.message || 'An unexpected error occurred');
      } finally {
          setLoading(false);
      }
  }, []);

  return { loading, error, crons, getCrons };
};

export const useDeleteCron = () => {
  const baseURL: any = config.API_URL;
  const [isLoading, setLoading] = useState(false);

  const deleteCron = useCallback(async (cronId: string) => {
      setLoading(true); 
      try {
          await axios.delete(`${baseURL}api/v1/cron/${cronId}`, {
              headers: {
                  Authorization: `Bearer ${storage.getToken()}`,
              },
          });
      } catch (error: any) {
          throw new Error(error.response?.data?.message || 'An unexpected error occurred');
      } finally {
          setLoading(false);
      }
  }, []);

  return { deleteCron, isLoading };
};


export const usePatchCron = () => {
  const baseURL: any = config.API_URL;

  const patchCron = useCallback(async (cronId: string, newData: any) => {
      try {
          await axios.patch(`${baseURL}api/v1/cron/${cronId}`, newData, {
              headers: {
                  Authorization: `Bearer ${storage.getToken()}`,
              },
          });
      } catch (error: any) {
          throw new Error(error.response?.data?.message || 'An unexpected error occurred');
      }
  }, []);

  return { patchCron };
};

export const useCreateContact = () => {
    const [creating, setCreating] = useState(false);
    const [newContact, setNewContact] = useState<any>(null);
    const [error, setError] = useState<string | null>(null);
    const baseURL: any = config.API_URL;
    const createContact = useCallback(async (contactData: any) => {
        setCreating(true);
        try {
            const response: any = await axios.post(`${baseURL}contacts`, contactData, {
                headers: {
                    Authorization: `Bearer ${storage.getToken()}`,
                },
            });
            setNewContact(response?.data);
            toast.success('Contact created');
        } catch (error: any) {
            setError(error.response?.data?.message || 'An unexpected error occurred');
        } finally {
            setCreating(false);
        }
    }, []);

    return { creating, newContact, error, createContact };
};

export const useUploadFile = () => {
    const [uploading, setUploading] = useState(false);
    const [uploadedFile, setUploadedFile] = useState<any>(null);
    const [error, setError] = useState<string | null>(null);
    const baseURL: any = config.API_URL;

    const uploadFile = useCallback(
        async (file: File, groupId: string) => {
            setUploading(true);
            const formData = new FormData();
            formData.append('file', file);

            const requestOptions: RequestInit = {
                method: 'POST',
                body: formData,
                headers: {
                    Authorization: `Bearer ${storage.getToken()}`,
                },
            };

            try {
                const response = await fetch(`${baseURL}contacts/upload/${groupId}`, requestOptions);

                if (!response.ok) {
                    throw new Error('File upload failed');
                }

                const data = await response.json();
                setUploadedFile(data);
                toast.success('File uploaded successfully');
            } catch (error: any) {
                setError(error.message || 'An unexpected error occurred');
            } finally {
                setUploading(false);
            }
        },
        [baseURL]
    );

    return { uploading, uploadedFile, error, uploadFile };
};

export const useSendMessage = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const sendMessage = useCallback(async (messageData: any, apiKey: string) => {
        setLoading(true);
        try {
            const response = await axios.post(`${config.API_URL}api/v1/notifications/send-sms/group`, messageData, {
                headers: {
                    apiKey: `${apiKey}`,

                    Authorization: `Bearer ${storage.getToken()}`,
                },
            });

            toast.success('Message sent');
        } catch (error: any) {
           toast.error(error.response?.data?.message || 'An unexpected error occurred');
            setError(error.response?.data?.message || 'An unexpected error occurred');
        } finally {
            setLoading(false);
        }
    }, []);

    return { loading, error, sendMessage };
};


export const useGetSmsRequest = () => {
    const [loading, setLoading] = useState(false);
    const [requests, setRequests] = useState<any[]>([]);
    const [error, setError] = useState<string | null>(null);

    const fetchRequests = useCallback(async (query: string) => {
        setLoading(true);
        try {
            const response: any = await PRIVATE_API.get(`notifications/sms-request${queryString(query)}`,

            );
            setRequests(response?.data);
        } catch (error: any) {
            setError(error.response?.data?.message || 'An unexpected error occurred');
        } finally {
            setLoading(false);
        }
    }, []);

    return { loading, requests, error, fetchRequests };
};