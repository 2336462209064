import React, { useEffect } from 'react';

import ReactApexChart from 'react-apexcharts';
import { CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';
import { useGetWalletStatistics } from '../../api/groups';
import { IRootState } from '../../store';

const WalletStatisticsChart = () => {
    const { walletStatistics, loading, error, fetchWalletStatistics } = useGetWalletStatistics();

    useEffect(() => {
        fetchWalletStatistics();
    }, []);

    console.log(walletStatistics);

    const isDark = useSelector((state: IRootState) => state.themeConfig.theme === 'dark' || state.themeConfig.isDarkMode);
    const isRtl = useSelector((state: IRootState) => state.themeConfig.rtlClass === 'rtl');

    const barChart: any = {
        series: [
            {
                name: 'Amount',
                data: walletStatistics?.map((stat: any) => stat.amount),
            },
        ],
        options: {
            chart: {
                height: 300,
                type: 'bar',
                zoom: {
                    enabled: false,
                },
                toolbar: {
                    show: false,
                },
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                show: true,
                width: 1,
            },
            colors: ['#008080'],
            xaxis: {
                categories: walletStatistics?.map((stat: any) => stat.date),
                axisBorder: {
                    color: isDark ? '#191e3a' : '#e0e6ed',
                },
            },
            yaxis: {
                opposite: isRtl ? true : false,
                reversed: isRtl ? true : false,
            },
            grid: {
                borderColor: isDark ? '#191e3a' : '#e0e6ed',
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                },
            },
            fill: {
                opacity: 0.8,
            },
            tooltip: {
                y: {
                    formatter: function (value: any) {
                        return new Intl.NumberFormat('en-US').format(value);
                    },
                },
            },
        },
    };

    return (
        <div className="panel">
            <p className="text-lg font-bold">Wallet Statistics</p>
            {walletStatistics ? (
                <ReactApexChart series={barChart.series} options={barChart.options} type="bar" height={300} />
            ) : (
                <div className="flex flex-col items-center justify-center">
                    <CircularProgress />
                </div>
            )}
        </div>
    );
};

export default WalletStatisticsChart;
